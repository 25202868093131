import React, { useEffect, useState } from 'react'; 
import Typography from '@material-ui/core/Typography';  
import CreateShipment from './CreateShipment';
import CreateJob from './CreateJob';
import BaseDocumentView from '../components/BaseDocumentView'; 
import { AppConfig } from '../utils/config.utils';
import useLocalStorage from '../utils/auth/useLocalStorage.hook';
import {CustomAppBar} from '../components/UserProfilePopper'; 
import {
    Breadcrumb,CommandBarButton,Label, Stack
} from '@fluentui/react';
import moment from 'moment';
import { Divider } from '@material-ui/core';
 
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';  
import CheckBoxIcon from '@material-ui/icons/CheckBox'; 
import SubdirectoryArrowLeftIcon from '@material-ui/icons/SubdirectoryArrowLeft';
import SubdirectoryArrowRightIcon from '@material-ui/icons/SubdirectoryArrowRight';

const API_CODE = AppConfig.PUBLIC_API_CODE;
const API_URL = AppConfig.PUBLIC_API_URL;
const axios = require('axios');
const axiosInstance = axios.create({
  baseURL: `${API_URL}/api/latest`, 
  headers: {'Content-Type': 'application/json'}
});

const useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: 'Segoe UI', 
    width: '100%',
    // maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
}));

function ListItemLink(props) {
  return <ListItem button component="a" {...props} />;
}

// async function createDocument(appId, userInfo) { 
//   return new Promise((resolve, reject) => {
//     axiosInstance.post(`/${appId.toLowerCase()}/document?code=${API_CODE}`, {
//       user: userInfo,
//       appId: appId,
//     })
//     .then(function (response) {
//       console.log(response); 
//       resolve(response.data.key); 
//     })
//     .catch(function (error) {
//       console.log(error);
//       reject(error);
//     });
//   }); 
// }

async function updateDocument(doc) {
  return new Promise((resolve, reject) => { 
    axiosInstance.put(`/document?code=${AppConfig.PUBLIC_API_CODE}`, doc)
    .then(function (response) {
      // console.log(response); 
      resolve();
    })
    .catch(function (error) {
      console.log(error);
      reject(error);
    });
  });
} 

async function getDocument(appId, data) {
  return new Promise((resolve, reject) => { 
    axiosInstance.get(`/document?key=${data.key}&code=${API_CODE}`, {})
    .then(function (response) {
      console.log(response);  
      resolve(response.data);
    })
    .catch(function (error) {
      console.log(error);
      reject(error);
    });
  });
}

async function deleteDocument(data) {
  return new Promise((resolve, reject) => { 
    axiosInstance.delete(`/document?key=${data.key}&code=${API_CODE}`, {})
    .then(function (response) {
      console.log(response);  
      resolve(response.data);
    })
    .catch(function (error) {
      console.log(error);
      reject(error);
    });
  });
}

const DocumentBrowser = (props) => {
  const classes = useStyles();
    const { key } = props.match.params; 
    const [validKeys, setValidKeys] = useState(['AMO', 'ANA', 'DWR', 'ENG', 'FTR', 'HSES', 'EIP', 'TLA', 'RLBT', 'RMA', 'SHIP', 'TSDI'])
    const [document, setDocument] = useState({}); 
    const [loading, setLoading] = useState(true);
    const [valid, setValid] = useState('UNKNOWN'); 
    // const [local, setLocal] = useLocalStorage('user', {});  

    useEffect(() => { 
       
        if (key && key.includes('-')) {
            const documentKey = key.split('-'); 
            if (documentKey && documentKey.length === 2) { // format should be '{type}-{index}' 
                documentKey[0] = documentKey[0].toUpperCase();
                if (validKeys.includes(documentKey[0])) {
                    // The doc key looks good, try to get the document!
                    setValid('DOC_ACCEPTED_APP_ID'); 
                    fetch(`${AppConfig.PUBLIC_API_URL}/api/latest/issue?key=${documentKey[0]}-${documentKey[1]}&code=${AppConfig.PUBLIC_API_CODE}`)
                    .then(response => {
                        if (response.status < 300) {
                            return response.json();
                        } else {
                            return null;
                        }
                    })
                    .then(json => { 
                        if (json) { 
                            if (json && json.id === `${documentKey[0]}-${documentKey[1]}`) { 
                                json.appId = documentKey[0];
                                setDocument(json);
                            } else {
                                setValid('DOC_REJECTED');
                                setDocument(null);
                            } 
                        } else {
                            setValid('DOC_REJECTED');
                        }
                        setLoading(false);
                    }); 
                } else {
                    // we don't recognize the key, so we shouldn't bother trying to get a document
                    setValid('DOC_REJECTED');
                    setLoading(false);
                }
            } else {
                // invalid key or key format
                    setValid('DOC_REJECTED');
                    setLoading(false);
            }
        } else {
            console.log(key)
            // invalid key or key format
            if (!key) {
                // route the user to a general browser
                    setValid('BROWSE_ACCEPTED');
                    setLoading(false);
            } else {
                // check to see if the key is one of:
                if (validKeys.includes(key.toUpperCase())) {
                    setValid('BROWSE_ACCEPTED_APP_ID'); 
                } else {
                    setValid('BROWSE_REJECTED_APP_ID');
                }
                setLoading(false);
            }
        }
        
    }, [key]);

    return ( 
        <CustomAppBar>
        {/* <AppHeader appSelected={(document) ? getAppSelectedName(document.appId) : null}> */}
            <> 
                {/* <BreadcrumbBasicExample document={document}/> */}
                <Label style={{fontSize: '28px', textAlign: 'left'}}>{document.id}</Label>
                {loading && <div>Loading...</div>}
                {!loading && (
                    <>
                    {(valid === 'BROWSE_ACCEPTED') && <Typography variant="h1">Display the general browser!</Typography>}
                    {(valid === 'BROWSE_ACCEPTED_APP_ID') && <Typography variant="h1">Display the app browser!</Typography>}
                    {(valid === 'BROWSE_REJECTED_APP_ID') && <PermissionErrorPage title={"You don't have access to this app"} description={"This app requires elevated permissions or the app doesn't exist. If you need access, contact the Portal Administrator for support."}/>}
                    {(valid === 'DOC_ACCEPTED_APP_ID') && (
                        <BaseDocumentView 
                          hideTasks
                          hideAttachments
                          onRenderHeader={() => {
                         //   console.log(document);
                            return (
                              <div style={{textAlign: 'left', padding: '0px 8px'}}> 
                                <div style={{display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center'}}>
                                  <Label style={{fontSize: '20px'}}>{document.Summary}</Label>
                                  <Stack horizontal styles={{ root: { height: 44, paddingTop: '8px' } }}>
                                  <CommandBarButton iconProps={{ iconName: 'PageArrowRight' }} onClick={() => {window.location.href='https://evolutioneng.atlassian.net/browse/' + document.id}}>View in Jira</CommandBarButton>
                                  </Stack> 
                                </div>
                                <Label style={{fontWeight:400}}>Created by <strong>{document['Creator'].displayName}</strong> on {moment(document['Created']).format('MMMM d, YYYY')}</Label>  
                                <Divider/>
                                <Label>Issue Type: {document['Issue Type'].name}</Label> 
                                
                                {(document['Issue Type'].subtask) && "(Subtask)"}
                                {
                                  (document.Labels && document.Labels.length > 0) && 
                                  <div>
                                    {document.Labels.join(', ')}
                                  </div>
                                }
                                {(document['Client'] && document['Client'].length > 0) &&
                                  <Label>Client: {document['Client'][0]}</Label>
                                } 
                                {(document['Description']) &&
                                <>
                                  <Label>Description:</Label>
                                  <Label style={{fontWeight:400}}>{document['Description']}</Label>
                                </>
                                } 
                                {(document['Service Location']) &&
                                  <Label>Service Location: {document['Service Location']}</Label>
                                } 
                                  {(document['Service Notes']) &&
                                  <>
                                  <Label>Service Notes:</Label>
                                  <Label style={{fontWeight:400}}>{document['Service Notes']}</Label>
                                  </>
                                }  
                              </div>
                            );
                          }}
                          tabs={[
                            {  
                              title: 'Linked Issues',
                              icon: 'Link',
                              itemCount: (document['Linked Issues'] && document['Linked Issues'].length > 0) ? document['Linked Issues'].length : undefined, 
                              content: ( 
                                <div className={classes.root}>
                                {(document['Linked Issues'] && document['Linked Issues'].length > 0) && 
                                  <List dense component="nav" aria-label="linked-issues-list"> 
                                      {document['Linked Issues'].map(st => {
                                        if (st.inwardIssue) {
                                          return (
                                            <ListItem button onClick={() => {window.location.href = 'https://evolutioneng.atlassian.net/browse/' + st.inwardIssue.key}}>
                                              <ListItemIcon>
                                                <SubdirectoryArrowLeftIcon />
                                              </ListItemIcon>
                                              <ListItemText primary={st.inwardIssue.fields.summary} secondary={st.inwardIssue.key}/> 
                                            </ListItem> 
                                          )
                                        } else if (st.outwardIssue) {
                                          return (
                                            <ListItem button onClick={() => {window.location.href = 'https://evolutioneng.atlassian.net/browse/' + st.outwardIssue.key}}>
                                              <ListItemIcon>
                                                <SubdirectoryArrowRightIcon />
                                              </ListItemIcon>
                                              <ListItemText primary={st.outwardIssue.fields.summary} secondary={st.outwardIssue.key}/> 
                                          </ListItem> 
                                          )
                                        } 
                                      })} 
                                  </List>
                                  } 
                                </div>
                              )
                            }, 
                            {
                              title: 'Subtasks',
                              icon: 'TaskLogo',
                              itemCount: (document['Sub-tasks'] && document['Sub-tasks'].length > 0) ? document['Sub-tasks'].length : undefined, 
                              content: (
                                <div className={classes.root}>
                                {(document['Sub-tasks'] && document['Sub-tasks'].length > 0) && 
                                  <List dense component="nav" aria-label="subtasks-list"> 
                                      {document['Sub-tasks'].map(st => {
                                        return (
                                          <ListItem button onClick={() => {window.location.href = 'https://evolutioneng.atlassian.net/browse/' + st.key}}>
                                              <ListItemIcon>
                                                <CheckBoxIcon />
                                              </ListItemIcon>
                                              <ListItemText primary={st.fields.summary} secondary={st.key}/> 
                                          </ListItem>
                                        )
                                      })} 
                                  </List>
                                  } 
                                </div>
                              )
                            },
                          ]}
                          />
                    )}
                    {(valid === 'DOC_REJECTED') && 
                    <>
                      {/* <PermissionErrorPage title={"You don't have access to this document"} description={'Make sure the document exists. If it does, ask the Portal Administrator to see the document.'}/> */}
                      <BaseDocumentView
                        onRenderHeader={() => (<PermissionErrorPage title={"You don't have access to this document"} description={'Make sure the document exists. If it does, ask the Portal Administrator to see the document.'}/>)}
                        hideTabs
                        tabs={[
                          {
                            title: 'Notes',
                            icon: 'Notes',
                            itemCount: 4,
                            content: (
                              <div>
                                This is a test
                              </div>
                            )
                          }
                        ]}
                        />
                    </>
                    }
                    </>
                )}
            </>
        {/* </AppHeader> */} 
      </CustomAppBar>
    ); 
};


function _onBreadcrumbItemClicked(ev, item) {
    switch (item.key) {
      case 'document':   
        window.location.href = item.to;
        break;
      case 'browse': 
        window.location.href = item.to;
        break;
      case 'app':
        window.location.href = item.to;
        break;
      case 'home':
        window.location.href = item.to;
        break;
      default: 
        break;
    }
    console.log(item); 
} 
  
function convertAppIdToAppName(appId) {
    switch (appId) {
        case 'SHIP': return 'Logistics';
        case 'JOB': return 'Operations';
        default: return '...';
    }
}

// function getAppDocumentType(appId) {
//     switch (appId) {
//         case 'SHIP': return 'Shipments';
//         case 'JOB': return 'Jobs';
//         default: return '...';
//     }
// }

const BreadcrumbBasicExample = ({document}) => { 
    const [bcItems, setBCItems] = useState([]);  

    useEffect(() => { 
        if (document) {
            // console.log(document);
            setBCItems(b => { 
                return [ 
                    { to: '/', text: 'Home', key: 'home', onClick: _onBreadcrumbItemClicked }, 
                    { to: `/browse/${document.appId}`, text: convertAppIdToAppName(document.appId), key: 'browse', onClick: _onBreadcrumbItemClicked },
                    { to: '/browse/'+document.id, documentId: document.id, text: document.id, key: 'document', onClick: _onBreadcrumbItemClicked, isCurrentItem: true },
                ];
            });
        } else { 
            setBCItems(b => {  
                return [
                    { 
                        text: 'Home', 
                        key: 'home', 
                        onClick: _onBreadcrumbItemClicked,
                        isCurrentItem: true
                    }
                ];
            });
        } 
    }, [document])
    return (
      <div style={{marginBottom: '16px'}}> 
        <Breadcrumb
          style={{backgroundColor: '#F4F5F7'}}
          items={bcItems}
          maxDisplayedItems={4}
          ariaLabel="Breadcrumb"
          overflowAriaLabel="More links"
        /> 
      </div>
    );
};

function getAppSelectedName(appId) {
    switch (appId) {
        case 'JOB': return 'Operations';
        case 'SHIP': return 'Logistics';
        default: return;
    }
}

function RenderDocument({ document, setDocument }) {
    return (
        <>
            {
              document.appId === 'SHIP' && 
                <CreateShipment   
                  document={document} 
                  setDocument={setDocument} 
                  saveDocument={async () => {
                    // console.log('Saving...', document);
                   await updateDocument(document);
                  }}
                  />
            }
            {
              document.appId === 'JOB' && 
                <CreateJob  
                  document={document} 
                  setDocument={setDocument}
                  saveDocument={async () => {
                    // console.log('Saving...', document);
                    await updateDocument(document);
                  }}
                  />
            }
        </>
    )
}

function PermissionErrorPage({title, description}) {
    return (
      <div style={{marginTop:'50px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
        <img height='128px' src='/permission-error.svg'/>
        <Typography style={{fontWeight:600, fontSize: '24px', marginTop: '20px', fontFamily: 'Segoe UI'}} variant='h6'>{title}</Typography>
        <Typography style={{marginTop: '10px', fontSize: '16px', fontFamily: 'Segoe UI', maxWidth: '700px'}} variant='body2'>{description}</Typography>
      </div>
    )
  } 

export default DocumentBrowser;