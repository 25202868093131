import React from 'react';
import { 
    makeStyles,
} from '@material-ui/core/styles' 
import { 
    Divider, 
} from '@material-ui/core'; 
import {
    ChecklistCollection
} from './Checklist';
import {
    Pivot,  
    PivotItem, 
} from '@fluentui/react';
import CustomFileDropper from './CustomFileDropper';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: '#FFFFFF',
        border: 'solid 1px #E0E0E0',
        height: '100%',
        // minHeight: '500px',
    },
    headerContent: {
        // minHeight: '275px'
    }
})); 

export default function BaseDocumentView({tabs, hideTabs, hideTasks, hideAttachments, onRenderHeader, document, setDocument}) {
    const classes = useStyles();
    const tasks = [];
    return (
        <div className={classes.root}>
            <div className={classes.headerContent}>
                {onRenderHeader ? onRenderHeader() : undefined}
            </div>
            {!hideTabs && 
            <>
            <Divider/> 
            <Pivot style={{textAlign: 'left'}} aria-label="pivottable"> 
                {tabs && tabs.length > 0 && tabs.map((tab, idx) => {
                    return (
                        <PivotItem key={idx} id={idx} headerText={tab.title} itemCount={tab.itemCount || undefined} itemIcon={tab.icon}>
                            {tab.content}
                        </PivotItem>
                    )
                })} 
                {!hideTasks && <PivotItem key="tasks" id="tasks" headerText="Tasks" itemCount={(tasks && tasks.length > 0) ? tasks.length : undefined} itemIcon="TaskLogo">
                    <ChecklistCollection 
                        tasks={tasks} 
                        setTask={(task) => {
                            console.log('EDIT_TASK', task);
                        }}
                        onDeleteTask={(task) => {
                            console.log('DELETE_TASK', task);
                        }}
                        onAddTask={(task) => {
                            task.eventData.link = document.id; 
                            console.log('CREATE_TASK', task);
                        }}/>
                </PivotItem>}
                {!hideAttachments && <PivotItem key="attachments" id="attachments" headerText="Attachments" itemCount={undefined} itemIcon="Attach">
                    <CustomFileDropper document={document} setDocument={setDocument}/>
                </PivotItem>}
            </Pivot>
            </>}
        </div>
    );
}