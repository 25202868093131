import React, { useEffect } from 'react'; 
import { AppConfig } from '../utils/config.utils';
import ReactJson from 'react-json-view';

// Styles
import { makeStyles } from '@material-ui/core/styles'; 

// Components
import { CustomAppBar } from '../components/UserProfilePopper'; 
import { Label, Stack } from '@fluentui/react';
import {
    DetailsList,
    DetailsListLayoutMode, 
    Selection,
    TextField,
    SelectionMode, 
    TooltipHost,  SelectableOptionMenuItemType,
    FontWeights,
    getTheme,
    mergeStyleSets, 
    ComboBox, 
    Text,
    Modal,
    DefaultButton,
    IconButton,
  } from '@fluentui/react'; 
  import { mergeStyles } from '@fluentui/react/lib/Styling';
import { Chip } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { useBoolean } from '@fluentui/react-hooks';

// Contexts
import { AppContext, AppContextState } from '../contexts/AppContext';

// Utilities
const queryString = require('query-string');
const axios = require('axios');
const moment = require('moment');

const axiosInstance = axios.create({
    baseURL: `${AppConfig.PUBLIC_API_URL}/api/latest`, 
    headers: {'Content-Type': 'application/json'}
});

const classNames = mergeStyleSets({
    compactCard: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
    },
    expandedCard: {
      padding: '16px 24px',
    },
    item: {
      selectors: {
        '&:hover': {
          textDecoration: 'underline',
          cursor: 'pointer',
        },
      },
    },
  });

const theme = getTheme();

const contentStyles = mergeStyleSets({
    container: {
      display: 'flex',
      flexFlow: 'column nowrap',
      alignItems: 'stretch',
    },
    header: [ 
        theme.fonts.xLargePlus,
        {
          flex: '1 1 auto',
          borderTop: `4px solid ${theme.palette.themePrimary}`,
          color: theme.palette.neutralPrimary,
          display: 'flex',
          alignItems: 'center',
          fontWeight: FontWeights.semibold,
          padding: '12px 12px 14px 24px',
        },
      ],
      body: {
        flex: '4 4 auto',
        padding: '0 24px 24px 24px',
        overflowY: 'hidden',
        minHeight: '325px',
        selectors: {
          p: { margin: '14px 0' },
          'p:first-child': { marginTop: 0 },
          'p:last-child': { marginBottom: 0 },
        },
      },
});

const cancelIcon = { iconName: 'Cancel' };

const iconButtonStyles = {
    root: {
      color: theme.palette.neutralPrimary,
      marginLeft: 'auto',
      marginTop: '4px',
      marginRight: '2px',
    },
    rootHovered: {
      color: theme.palette.neutralDark,
    },
  };

const CustomIconButton = () => {
    const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] = useBoolean(false);

    return (
        <>
        <IconButton onClick={showModal} iconProps={{ iconName: 'Filter' }} title="Filter" ariaLabel="Filter" />;
        <Modal
            titleAriaId={'product modal'}
            isOpen={isModalOpen}
            onDismiss={hideModal}
            isBlocking={false}
            containerClassName={contentStyles.container}>
            <div className={contentStyles.header}>
                <span id={'product modal'}>Filter</span>
                <IconButton
                    styles={iconButtonStyles}
                    iconProps={cancelIcon}
                    ariaLabel="Close popup modal"
                    onClick={hideModal}
                />
            </div>
            <div className={contentStyles.body}>
            </div>
        </Modal>
        </>
    );
}

function CreateProductModal({isModalOpen, hideModal, data, setData}) {
    const productOptions = [
        {key: 'asset', text: 'Asset'},
        {key: 'assembly', text: 'Assembly'},
        {key: 'part', text: 'Part'},
    ];
    const assetTypeOptions = [
        { key: 'elpTitle', text: 'Electronics Probe', itemType: SelectableOptionMenuItemType.Header },
        {key: 'pulser', text: 'Pulser', prefix: 'ELP-'},
        {key: 'pressureFeedThrough', text: 'Pressure Feedthrough'},
        {key: 'gearbox', text: 'Gearbox'},
        {key: 'motor', text: 'Motor'},
        {key: 'ecam', text: 'ECAM', prefix: 'ECAM111-'},
        {key: 'directional', text: 'Directional'},
        {key: 'gamma', text: 'Gamma'},
        {key: 'femaleRotatable', text: 'Female Rotatable', prefix: 'RFC-'},
        {key: 'electronicsHousing', text: 'Electronics Housing', prefix: 'EPH-'},

        { key: 'elpTitle', text: 'Battery Probe', itemType: SelectableOptionMenuItemType.Header },
        {key: 'batteryBulkHead', text: 'Battery Bulkhead', prefix: 'BAP-'},
        {key: 'gapJoint', text: 'Gap Joint', prefix: 'GJT-'},
        {key: 'txRod', text: 'TX Rod', prefix: 'TXR-'},
        {key: 'battery', text: 'Battery'},
        {key: 'maleRotatable', text: 'Male Rotatable', prefix: 'RCM-'},
        {key: 'batteryHousing', text: 'Battery Housing', prefix: 'BPH-'},
        
        { key: 'elpTitle', text: 'Carrier', itemType: SelectableOptionMenuItemType.Header },
        {key: 'gapSub', text: 'Gap Sub'},
        {key: 'centralizer', text: 'Centralizer'},
        {key: 'landingSub', text: 'Landing Sub'},
        {key: 'crossoverSub', text: 'Crossover Sub'},

        { key: 'elpTitle', text: 'Equipment', itemType: SelectableOptionMenuItemType.Header }, 
        {key: 'surfaceReceiver', text: 'Surface Receiver'},
        {key: 'orientationTool', text: 'Orientation Tool', prefix: 'ORT-'}, 
        {key: 'router', text: 'Router/Repeater'},
        {key: 'qcJig', text: 'QC Jig'},
        {key: 'phone', text: 'Phone'},
        {key: 'tablet', text: 'Tablet'},
        {key: 'computer', text: 'Computer'}, 
        {key: 'other', text: 'Other'},
    ];
    const assemblyTypeOptions = [
        {key: 'batteryProbe', text: 'Battery Probe Assembly', prefix: 'BAP-'},
        {key: 'carrier', text: 'Carrier Assembly'},
        {key: 'electronicsProbe', text: 'Electronics Probe Assembly', prefix: 'ELP-'},
        {key: 'kitBox', text: 'Kit Box Assembly', prefix: 'EVO-ONE-'},
    ];
    const productOwnerOptions = [
        {key: 'amocan', text: 'AMO-CAN'},
        {key: 'amousa', text: 'AMO-USA'},
        {key: 'eetcan', text: 'EET-CAN'},
        {key: 'eetusa', text: 'EET-USA'},
        {key: 'engineering', text: 'Engineering'},
        {key: 'eleclabcan', text: 'LAB-CAN'},
        {key: 'eleclabusa', text: 'LAB-USA'},
        {key: 'manufacturing', text: 'Manufacturing'},
    ];

    return (
        <Modal
            titleAriaId={'product modal'}
            isOpen={isModalOpen}
            onDismiss={() => hideModal(false)}
            isBlocking={false}
            containerClassName={contentStyles.container}>
            <div className={contentStyles.header}>
                <span id={'product modal'}>Add a new product</span>
                <IconButton
                    styles={iconButtonStyles}
                    iconProps={cancelIcon}
                    ariaLabel="Close popup modal"
                    onClick={() => hideModal(false)}
                />
            </div>
            <div className={contentStyles.body}> 
                <ComboBox
                    label="Product Type" 
                    autoComplete="on" 
                    defaultSelectedKey={data.type}
                    onChange={(event, value) => 
                        setData(s => {
                            s.prefix = undefined; 
                            s.assetType=undefined;
                            s.assemblyType=undefined; 
                            s.type = value.key; 
                            s.serial = undefined;
                            return {...s};})
                    }
                    styles={{root: { maxWidth: 300 }}}
                    options={productOptions}/>
                {data.type === 'asset' && <ComboBox
                    label="Asset Type" 
                    autoComplete="on"
                    defaultSelectedKey={data.assetType}
                    onChange={(event, value) => 
                        setData(s => {
                            s.assetType = value.key; 
                            s.assetTypeDescription = value.text;
                            s.prefix = value.prefix;
                            return {...s};
                        })
                    }
                    styles={{root: { maxWidth: 300 }}}
                    options={assetTypeOptions}/>}
                {data.type === 'assembly' && <ComboBox
                    label="Assembly Type" 
                    autoComplete="on"
                    onChange={(event, value) => 
                        setData(s => {
                            s.assemblyType = value.key; 
                            s.assemblyTypeDescription = value.text;
                            s.prefix = value.prefix;
                            return {...s};
                        })
                    }
                    defaultSelectedKey={data.assemblyType}
                    styles={{root: { maxWidth: 300 }}}
                    options={assemblyTypeOptions}/>} 
                <TextField
                    label="Serial #"
                    prefix={data.prefix}
                    onChange={(event, value) => { 
                        setData(s => {
                            s.serial = value;  
                            return {...s};
                        })
                    }}
                    ariaLabel="serial"
                    /> 
                <ComboBox
                    label="Owner" 
                    autoComplete="on"
                    onChange={(event, value) => {
                        console.log(value);
                        setData(s => {
                            s.owner = value.text;  
                            return {...s};
                        })
                    }
                    }
                    defaultSelectedKey={'manufacturing'}
                    styles={{root: { maxWidth: 300 }}}
                    options={productOwnerOptions}/>
            </div>
            <div style={{backgroundColor: '#F9F9F9', height: '56px', paddingTop: '12px', paddingRight: '12px', width: '100%', position: 'absolute', bottom: 0, right: 0}}>
                <Stack horizontal tokens={{ childrenGap: 5 }} styles={{ root: { height: 44, display: 'flex', justifyContent: 'flex-end' } }}>
                <DefaultButton onClick={() => hideModal(false)} text="Cancel" /> 
                <DefaultButton primary onClick={() => hideModal(true)} text="Add" /> 
                </Stack>
          </div>
        </Modal>
    )
}

async function createDocuments(products) {
    return new Promise((resolve, reject) => { 
        axiosInstance.post(`/product?code=${AppConfig.PUBLIC_API_CODE}`, products)
        .then(function (response) { 
            resolve(response.data);
        })
        .catch(function (error) { 
            reject(error);
        });
    });
}

async function updateDocuments(ids, change) {
    return new Promise((resolve, reject) => { 
        axiosInstance.put(`/product?bulk=true&code=${AppConfig.PUBLIC_API_CODE}`, {ids, change})
        .then(function (response) { 
            resolve(response.data);
        })
        .catch(function (error) { 
            reject(error);
        });
    });
}

async function queryDocuments(query) {
    return new Promise((resolve, reject) => { 
        axiosInstance.get(`/product?query=${query}&code=${AppConfig.PUBLIC_API_CODE}`, {})
        .then(function (response) { 
            resolve(response.data);
        })
        .catch(function (error) { 
            reject(error);
        });
    });
}

const useStyles = makeStyles((theme) => ({
    root: { 

    }, 
    jsonView: {
        backgroundColor: '#002B36',
        padding: '12px', 
        textAlign: 'left', 
        marginTop: '16px', 
        borderRadius: '6px'
    }
})); 

export default function ProductsPage(props) {
    const classes = useStyles();
    const [data, setData] = React.useState({});

    const { x_jjv } = queryString.parse(props.location.search);
    const { children }  = props; 

    return (
        <CustomAppBar>
            <div className={classes.root}>  
                <Label style={{fontSize: '28px', textAlign: 'left'}}>Products</Label>
                <ProductTable/>
            </div>
            {x_jjv==='true' && 
                <div className={classes.jsonView}>
                    <ReactJson 
                        src={data}
                        theme="solarized"/>
                </div>
            }
        </CustomAppBar>
    )
}

const exampleChildClass = mergeStyles({
    display: 'block',
    marginBottom: '10px',
    textAlign: 'left',
});

const textFieldStyles = { root: { maxWidth: '300px' } };

function buildTable() {
    const _allItems = [];
    // for (let i = 0; i < 200; i++) {
    //   _allItems.push({
    //     key: i,
    //     id: i,
    //     serial: 'Item ' + i,
    //     productTypeName: i,
    //     location: i, 
    //     owner: i, 
    //     checkedOut: i, 
    //     tags: i,
    //   });
    // }
    return _allItems;
}

function ProductTable() {
    
    const _getSelectionDetails = (_selection) => {
        const selectionCount = _selection.getSelectedCount();
    
        switch (selectionCount) {
          case 0:
            return 'No items selected';
          case 1:
            return '1 item selected: ' + (_selection.getSelection()[0]).name;
          default:
            return `${selectionCount} items selected`;
        }
    }

    const [selection, setSelection] = React.useState(new Selection({
        onSelectionChanged: () => setSelectionDetails(_getSelectionDetails(selection)),
      }));
    const [allItems, setAllItems] = React.useState(buildTable());
    const [columns, setColumns] = React.useState([
        { key: 'column1', name: 'Serial', fieldName: 'serial', minWidth: 60, maxWidth: 100, isResizable: true },
        { key: 'column2', name: 'Product Type', fieldName: 'productTypeName', minWidth: 85, maxWidth: 150, isResizable: true },
        { key: 'column3', name: 'Location', fieldName: 'location', minWidth: 100, maxWidth: 200, isResizable: true },
        { key: 'column4', name: 'Owner', fieldName: 'owner', minWidth: 65, maxWidth: 100, isResizable: true },
        { key: 'column5', name: 'Checked Out', fieldName: 'checkedOut', minWidth: 65, maxWidth: 120, isResizable: true,
            onRender: (item) => {
                if (item.checkedOut === true) {
                    return ( 
                        <Chip clickable size="small" label="CHECKED OUT" style={{borderRadius: '4px', backgroundColor: '#FFF4E5', color: '#AD926C', fontSize: '11px', fontWeight: 600, alignItems: 'center', width: '111px', padding: "0px 6px", margin: 0}}/>
                    );
                } else if (item.checkedOut === false) {
                    return ( 
                        <Chip clickable size="small" label="CHECKED IN" style={{borderRadius: '4px', backgroundColor: '#EDF7ED', color: '#1E4620', fontSize: '11px', fontWeight: 600, alignItems: 'center', width: '111px', padding: "0px 6px", margin: 0}}/>
                    );
                } else {
                    return ( 
                        <Chip clickable size="small" label="UNKNOWN" style={{borderRadius: '4px', backgroundColor: '#FDECEA', color: '#611A15', fontSize: '11px', fontWeight: 600, alignItems: 'center', width: '111px', padding: "0px 6px", margin: 0}}/>
                    );
                }
            }
        },
        { key: 'column6', name: 'Tags', fieldName: 'groupTag', minWidth: 100, maxWidth: 200, isResizable: true, 
            onRender: (item) => { 
                return (
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        {item.groupTag && 
                        item.groupTag.length > 0 && 
                        item.groupTag.map((tag, idx) => {
                            return (
                                <Chip clickable size="small" style={{borderRadius: '4px', marginRight: '4px'}} label={tag}/>
                            );
                        })
                        }
                    </div>
                );
            } 
        },
    ]);
    const [items, setItems] = React.useState(allItems);
    const [selectionDetails, setSelectionDetails] = React.useState(_getSelectionDetails(selection));

    const _onItemInvoked = (item) => {
        // alert(`Item invoked: ${item.id}`);
        window.location.href = AppConfig.OAUTH_REDIRECT_URI + '#/browse/products/' + item.id;
    };
    
    const onFilter = (ev, text) => {
        setItems(text ? allItems.filter(i => i.serial.toLowerCase().indexOf(text) > -1) : [...allItems]);
    };

    const addIcon = { iconName: 'Add' };

    const createMenuProps = {
        items: [
          {
            key: 'asset',
            text: 'Asset',
            iconProps: { iconName: 'WebAppBuilderModule' },
            onClick: () => setIsCreateModalOpen('asset'),
          },
          {
            key: 'assembly',
            text: 'Assembly',
            iconProps: { iconName: 'WebAppBuilderFragment' },
            onClick: () => handleCreateModalOpen('assembly'),
          },
          {
            key: 'part',
            text: 'Part',
            iconProps: { iconName: 'WebComponents' },
            onClick: () => handleCreateModalOpen('part'),
          },
        ],
      };

      const [loadCount, setLoadCount] = React.useState(0);
    useEffect(() => {
        queryDocuments('') 
        .then((result) => { 
            setAllItems(result);
            setItems(result);
        })
        .catch(console.log);
    }, [loadCount]);

    const applicationState = React.useContext(AppContextState);
    const [isCreateModalOpen, setIsCreateModalOpen] = React.useState(false);
    const [createObject, setCreateObject] = React.useState({});
    function handleCreateModalOpen(type) {
        if (!type) type = 'asset';
        setCreateObject({
            type: type,
        });
        setIsCreateModalOpen(true);
    }

    function handleCloseModal(isCreate) {
        if (isCreate) {
            var prd = {
                "productTypeName": createObject.assetTypeDescription,
                "assignee": undefined,
                "owner": createObject.owner || "Manufacturing",
                "serial": (createObject.prefix || '') + createObject.serial,
                "type": createObject.type,
                "location": 'N/A',
                "created": moment().format(),
                "updated": moment().format(),
                "createdBy": {
                    "accountId": applicationState.user.id,
                    "displayName": applicationState.user.displayName,
                },
                "parent": undefined,
                "checkedOut": false,
                "groupTag": [],
                "assignment": undefined, 
                "retired": false,
            }
            console.log(prd);
            createDocuments(prd)
            .then((result) => {
                console.log(result)
                // if (result.resut === 'SUCCESS') {
                setLoadCount(loadCount + 1);
                // }
            })
            .catch(console.log);
        }
        setIsCreateModalOpen(false);
    }

    return (
        <>
        <CreateProductModal data={createObject} setData={setCreateObject} isModalOpen={isCreateModalOpen} hideModal={handleCloseModal}/>
        <div style={{
            backgroundColor: '#FFFFFF',
            border: 'solid 1px #E0E0E0',
            height: '100%',
            textAlign: 'left',
            borderRadius: '6px',
            padding: '16px 0px',  
            }}> 
            
            <Alert style={{margin: '0px 16px'}} severity="warning">
                This feature is in preview and is actively being rolled out. Not all features are available yet (like checking out an asset), so hang tight while we work to open this up to everyone. Thanks for your patience!
            </Alert>
                <div style={{display: 'flex', marginTop: '16px', justifyContent: 'space-between'}}>
                    <div style={{display: 'flex'}}>
                        <div style={{marginLeft: '16px', marginRight: '8px'}}>
                            <DefaultButton
                                primary
                                text="Add"
                                split
                                iconProps={addIcon}
                                splitButtonAriaLabel="Add options"
                                aria-roledescription="split button"
                                onClick={() => handleCreateModalOpen('asset')}
                                menuProps={createMenuProps}
                                /> 
                        </div> 
                        <TextField
                            placeholder='Filter'
                            className={exampleChildClass} 
                            onChange={onFilter}
                            styles={textFieldStyles}/> 
                    </div>
                    <div style={{margin: '0px 16px'}}>
                        <CustomIconButton/> 
                    </div>
                </div> 
                <DetailsList
                    items={items}
                    columns={columns}
                    setKey="set"
                    selectionMode={SelectionMode.multiple}
                    layoutMode={DetailsListLayoutMode.justified}
                    selection={selection}
                    selectionPreservedOnEmptyClick={true}
                    ariaLabelForSelectionColumn="Toggle selection"
                    ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                    checkButtonAriaLabel="select row"
                    onItemInvoked={_onItemInvoked}/>
        </div>
        </>
    )
}