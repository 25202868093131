import React, { useState, useEffect } from 'react';
import { v4 as uuidv4 } from "uuid";
import {
  TagPicker,
  Toggle,
  Label,
  Stack,
  mergeStyles
} from '@fluentui/react'; 

const rootClass = mergeStyles({
    width: '380px',
    // maxWidth: 500,
});

const toggleStyles = { root: { margin: '10px 0' } };

const inputProps = {
  onBlur: (ev) => console.log('onBlur called'),
  onFocus: (ev) => console.log('onFocus called'),
  'aria-label': 'Tag picker',
};

const pickerSuggestionsProps = {
  suggestionsHeaderText: 'Suggested',
  noResultsFoundText: 'No suggestions found',
};

const testTags = [
  '475 Carrier', 
  '550 Unified Carrier',
  '650 Carrier', 
  '660 Unified Carrier',
  '800 Carrier', 
  'ELP', 
  'BAP', 
  'Kit Box', 
  'Surface Equipment'
].map(item => ({ key: item, name: item }));

const listContainsTagList = (tag, tagList) => {
  if (!tagList || !tagList.length || tagList.length === 0) {
    return false;
  }
  return tagList.some(compareTag => compareTag.key === tag.key);
};

const filterSuggestedTags = (filterText, tagList) => {
  return filterText
    ? testTags.filter(
        tag => tag.name.toLowerCase().indexOf(filterText.toLowerCase()) === 0 && !listContainsTagList(tag, tagList),
      )
    : [];
};

const filterSelectedTags = (filterText, tagList) => {
  return filterText ? testTags.filter(tag => tag.name.toLowerCase().indexOf(filterText.toLowerCase()) === 0) : [];
};

const getTextFromItem = (item) => item.name;

export default function ProductTypePicker({ setValue, value, label }) {
  // All pickers extend from BasePicker specifying the item type.
  const picker = React.useRef(null);
  const [tagPicker, setTagPicker] = useState(false); 
  const [loaded, setLoaded] = useState(false);

  const onItemSelected = React.useCallback((item) => {
    // if (picker.current && listContainsTagList(item, picker.current.items)) {
    //   return null;
    // }
    const _item = {...item};
    _item.key += '-' + uuidv4();
    return {..._item};
  }, []);

  useEffect(() => {
    if (picker && picker.current && !loaded) { 
        if (value && value.length > 0) value.forEach(v => picker.current.addItem(v))
        setLoaded(true);
    }
  }, [picker, loaded])

  return (
    <div className={rootClass}>
        <Stack>
            <div style={{marginRight: '8px', width: '72px'}}><Label>{label}</Label></div>
            <TagPicker  
                onChange={setValue}
                removeButtonAriaLabel="Remove"
                componentRef={picker}
                onResolveSuggestions={filterSelectedTags}
                onItemSelected={onItemSelected}
                getTextFromItem={getTextFromItem}
                pickerSuggestionsProps={pickerSuggestionsProps}
                // itemLimit={25}
                disabled={tagPicker}
                inputProps={inputProps}
            />
        </Stack>
      {/* <Toggle
        styles={toggleStyles}
        label="Disable tag picker"
        checked={tagPicker}
        onChange={() => setTagPicker(!tagPicker)}
      /> */}
      {/* Filter items in suggestions: This picker will filter added items from the search suggestions. */}
      {/* <TagPicker
        removeButtonAriaLabel="Remove"
        onResolveSuggestions={filterSuggestedTags}
        getTextFromItem={getTextFromItem}
        pickerSuggestionsProps={pickerSuggestionsProps}
        itemLimit={25}
        disabled={tagPicker}
        inputProps={inputProps}
      /> */}
      {/* <br /> */}
      {/* Filter items on selected: This picker will show already-added suggestions but will not add duplicate tags. */}
    
    </div>
  );
};
