import React, { useState, useEffect } from 'react';
import { v4 as uuidv4 } from "uuid";
import Chip from '@material-ui/core/Chip';
import ExploreIcon from '@material-ui/icons/Explore';
import {
  TagPicker, 
  Label,
  Stack,
  mergeStyles, 
} from '@fluentui/react'; 

export default function CustomItemPicker({ disabled, setValue, value, label, getOptions, limitResults, filterDuplicates }) {
  // All pickers extend from BasePicker specifying the item type.
  const picker = React.useRef(null);
  const [tagPicker, setTagPicker] = useState(false); 
  const [loaded, setLoaded] = useState(false);
  // const [valueList, setValueList] = useState([]);
  const rootClass = mergeStyles({ 
    marginBottom: '5px', 
  });
 
  const inputProps = { 
    onBlur: (ev) => {},
    onFocus: (ev) => {},
    'aria-label': 'Tag picker',
  };

  const pickerSuggestionsProps = {
  suggestionsHeaderText: 'Suggested',
  noResultsFoundText: 'No suggestions found',
  };

  if (!getOptions) {
    getOptions = async () => {
      return [
        'ELP-0223', 
        ].map(item => ({ key: item, name: item }));
    }
  } 

  const listContainsTagList = (tag, tagList) => {
  if (!tagList || !tagList.length || tagList.length === 0) {
    return false;
  }
  return tagList.some(compareTag => compareTag.key === tag.key);
  };

  const filterSuggestedTags = async (filterText, tagList) => {
  return filterText
    ? (await getOptions()).filter(
        tag => tag.name.toLowerCase().indexOf(filterText.toLowerCase()) === 0 && !listContainsTagList(tag, tagList),
      )
    : [];
  };

  const filterSelectedTags = async (filterText, tagList) => {
  return filterText ? (await getOptions()).filter(tag => tag.name.toLowerCase().indexOf(filterText.toLowerCase()) === 0) : [];
  };

  const getTextFromItem = (item) => item.name;

  const onItemSelected = React.useCallback((item) => {
    if (filterDuplicates && picker.current && listContainsTagList(item, picker.current.items)) {
      return null;
    }
    const _item = {...item};
    _item.key += '-' + uuidv4();
    return {..._item};
  }, []);

  useEffect(() => {
    if (picker && picker.current && !loaded) { 
        if (value && value.length > 0) {
          value.forEach(v => {
            picker.current.addItem(v);      
            // console.log(v);    
            // setValueList(o => o.push(v));
          });
        }
        setLoaded(true);
    } 
  }, [picker, loaded])
  
  return (
    <div className={rootClass}>
        <Stack horizontal>
            {!disabled && <div style={{marginRight: '8px', textAlign: 'left'}}><Label>{label}</Label></div>}
            <TagPicker  
                autoFocus 
                styles={(disabled) ? {text: {border: 0, width:260 }} : {text:{width:260}}}
                onChange={setValue}
                removeButtonAriaLabel="Remove"
                componentRef={picker}
                onResolveSuggestions={(filterDuplicates) ? filterSuggestedTags : filterSelectedTags}
                onItemSelected={onItemSelected}
                getTextFromItem={getTextFromItem}
                pickerSuggestionsProps={pickerSuggestionsProps}
                itemLimit={limitResults}
                disabled={tagPicker}
                inputProps={inputProps}
                onRenderItem={(disabled) ? (
                  (item) => { 
                    return (<Chip style={{borderRadius: 2, fontWeight:600, padding:'14px 6px', color: 'white', backgroundColor: '#0078D4', fontFamily: 'Segoe UI', fontSize: '14px'}} icon={<ExploreIcon style={{color: 'white'}}/>} size="small" label={item.item.name}/>);
                  }
                ) : undefined}
            />
        </Stack>
    </div>
  );
}; 
