import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, HashRouter } from 'react-router-dom';
import './index.css';
import App from './App';
import AppProvider from './contexts/AppContext';
import * as serviceWorker from './serviceWorker';
   
// MSAL imports
import { PublicClientApplication } from "@azure/msal-browser"; 
 
const msalInstance = new PublicClientApplication({
  auth: {
      clientId: "c511daac-75a7-4b0e-a563-169d6288d76e",
      authority: 'https://login.microsoftonline.com/29b01927-ad34-4afa-bef4-d86120399c2b',
      redirectUri: (process.env.NODE_ENV === 'development') ? "http://localhost:3000" : 'https://portal-dev.evolutioneng.com',  
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: true, 
  },
});

ReactDOM.render(
    <AppProvider>
      <HashRouter>
        <App pca={msalInstance}/>
      </HashRouter>
    </AppProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
