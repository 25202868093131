import React, { useState, useEffect, useRef } from 'react';
import { AppConfig } from '../utils/config.utils';
import moment from 'moment';
// import { v4 as uuidv4 } from "uuid"; 
// import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper'; 
import Divider from '@material-ui/core/Divider'; 
import LinearProgress from '@material-ui/core/LinearProgress';
// import GetAppIcon from '@material-ui/icons/GetApp';  
// import IconButton from '@material-ui/core/IconButton';  
// import List from '@material-ui/core/List';
// import ListItem from '@material-ui/core/ListItem';
// import ListItemIcon from '@material-ui/core/ListItemIcon';
// import ListItemText from '@material-ui/core/ListItemText';  
// import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'; 
import { FileDrop } from 'react-file-drop'; 
// import DeleteIcon from '@material-ui/icons/Delete'; 
// import Document24Icon from '@atlaskit/icon-file-type/glyph/document/24';
import { getFileTypeIconProps, FileIconType } from '@uifabric/file-type-icons';
 
import {  
    MarqueeSelection,  
    mergeStyleSets,
    TooltipHost,
    ThemeProvider,
    DetailsList,
    DetailsListLayoutMode,
    Selection,
    SelectionMode, 
    CommandBarButton,
    SearchBox,
    Icon,
    Stack,
} from '@fluentui/react'; 

const path = require('path');

const axios = require('axios');
const ax = axios.create({
    baseURL: `${AppConfig.PUBLIC_API_URL}/api/latest/`, 
    headers: { 
      'Content-Type': 'application/json', 
    } 
}); 
 
export default function CustomFileDropper({document, setDocument}) {
    const [loading, setLoading] = useState(false);
    const [frameEnter, setFrameEnter] = useState(false);
    const [dragEnter, setDragEnter] = useState(false); 
    const [progressPercent, setProgressPercent] = useState(null);
    const [progress, setProgress] = useState(0);

    const fileInputRef = useRef(null);
    
    function doUploadProgress(event) {
        if (!event || !event.loaded || !event.total || event.total === 0 ) {
          console.log('Bad event!', event);
          return;
        }
        setProgressPercent(Math.round((100 * event.loaded) / event.total));
    }
    
    const uploadChunk = async (file) => { 
        setLoading(true);
        if (document.attachments && document.attachments.filter(x => x.fileName === file.name).length > 0) {
            console.log('Attachment with file name already exists!');
            setLoading(false);
            return;
        }
        var response = {}; 
        var formData = new FormData(); 
        formData.append("data", file, file.name); 
        const xconfig = {
            headers: { 'Content-Type': 'multipart/form-data' },
            onUploadProgress: doUploadProgress
        }; 
        response = await ax.post(`/attachments?key=${document.id}&code=${AppConfig.PUBLIC_API_CODE}`, formData, xconfig)
        .catch((error) => {console.log(error); return {};}); 
      
        if (response.status===200) {
            setDocument(d => {
                d.attachments = [response.data, ...d.attachments];
                return {...d};
            });
        }
        setLoading(false);
        setProgressPercent(0);
    }; 

    const onFileInputChange = (event) => {
        const { files } = event.target;  
        Object.keys(files).forEach(key => {
          uploadChunk(files[key]); 
        }); 
    }
    
    const onTargetClick = () => {
        if (fileInputRef.current) fileInputRef.current.click();
    }

    function handleFrameEnter(event) {  
        setFrameEnter(true);
    }

    function handleFrameLeave(event) {  
        setFrameEnter(false);
    }

    function handleFrameDrop(event) {  
        setFrameEnter(false);
    }

    function handleDragOver(event) {  
        setDragEnter(true);
    }
    
    function handleDragLeave(event) {  
        setDragEnter(false);
    }
    
    function handleDrop(files, event) { 
        setDragEnter(false);
        Object.keys(files).forEach(key => { 
          uploadChunk(files[key]);
        }); 
    }

    async function handleDeleteAttachment(fileId) {
        if (!fileId) return;
        const response = await ax.delete(`/attachments?key=${document.id}&fileId=${fileId}&code=${AppConfig.PUBLIC_API_CODE}`, {});
        console.log(response);
        if (response && response.status === 200) {
            return true;
        }
        return false;
    }
      
    const stackStyles = { root: { height: 44 } };
    const stackTokens = { childrenGap: 20 };
    const [fileSearchValue, setFileSearchValue] = useState('');
    const [dl, setDL] = useState(null);
    const [startDownloads, setStartDownloads] = useState(null);
    const [selectedAttachments, setSelectedAttachments] = useState(null);
    const [enableDownloads, setEnableDownloads] = useState(false);

    function handleSetSelectedAttachments(v) {
        setSelectedAttachments(v); 
        setEnableDownloads(v && v.length > 0);
    }

    useEffect(() => {
        if (dl !== null) {
            return;
        }
        if (startDownloads !== null) {
            if (startDownloads.length > 0) {
                const att = startDownloads.splice(0, 1);
                setDL(`${att[0].self}&code=${AppConfig.PUBLIC_API_CODE}`);
            } else {
                setStartDownloads(null);
            }
        } 
    }, [startDownloads, dl]);

    useEffect(() => {
        if (dl !== null) {
            window.location.href = dl;
            setDL(null);
        }
    }, [dl]);
 
    function handleDownloadCommand() {
        setStartDownloads([...selectedAttachments]); 
    }

    return (
        <div>
            <Stack horizontal styles={stackStyles}>
                <CommandBarButton
                    disabled={!enableDownloads}
                    iconProps={{ iconName: 'Download' }}
                    text="Download"  
                    onClick={handleDownloadCommand}/>
                <CommandBarButton
                    iconProps={{ iconName: 'Upload' }}
                    text="Upload"  
                    onClick={onTargetClick}/>
                <CommandBarButton
                    disabled={!enableDownloads}
                    iconProps={{ iconName: 'Delete' }}
                    text="Delete"  
                    onClick={async () => {
                        var items = [...(selectedAttachments || [])];
                        if (items && items.length > 0) {
                            for (let i = 0; i < items.length; i++) {  
                                var res = await handleDeleteAttachment(items[i].key);
                                items[i].deleted = res;
                            } 
                            console.log(items);
                            items = items.filter(x => x.deleted);
                            if (items.length > 0) {
                                setDocument(d => {    
                                    for (let i = 0; i < d.attachments.length; i++) {
                                        if (items.filter(x => x.key === d.attachments[i].fileId).length > 0) {
                                            console.log(i, d.attachments[i].fileId);
                                            d.attachments[i].deleted = true;
                                        }
                                    } 
                                    d.attachments = [...d.attachments.filter(x => !x.deleted)]; 
                                    return {...d};
                                });
                            } 
                        }
                    }}/>
                <Stack tokens={stackTokens} style={{minWidth:'100px', marginLeft: '8px', marginTop: '6px'}}>
                    <SearchBox 
                        styles={{ root: { width: 200 } }}
                        placeholder="Filter" 
                        iconProps={{ iconName: 'Filter' }}
                        onChange={(ev, newValue) => {setFileSearchValue(newValue.toLowerCase());}} 
                        onSearch={newValue => {setFileSearchValue(newValue.toLowerCase());}} />
                </Stack> 
            </Stack>
            {loading && !progressPercent && <LinearProgress style={{width: '100%'}}/>}
            {loading && progressPercent && <LinearProgress variant="determinate" value={progressPercent} style={{width: '100%'}}/>}
            <Divider/> 
            <Paper elevation={(dragEnter) ? 12 : ((frameEnter) ? 3 : 0)} style={{borderRadius: '0px', height: '100%'}}>
                <input onChange={onFileInputChange} ref={fileInputRef} type="file" style={{visibility:'hidden'}}/>
                <FileDrop  
                    onFrameDragEnter={handleFrameEnter}
                    onFrameDragLeave={handleFrameLeave}
                    onFrameDrop={handleFrameDrop}
                    onDragOver={handleDragOver}
                    onDragLeave={handleDragLeave}
                    onDrop={handleDrop}>
                    <div> 
                        <FileViewer 
                            setSelectedAttachments={handleSetSelectedAttachments} 
                            document={document} 
                            searchValue={fileSearchValue}/>
                    </div>
                </FileDrop> 
            </Paper>
        </div> 
    );
}

const classNames = mergeStyleSets({
  fileIconHeaderIcon: {
    padding: 0,
    fontSize: '16px',
  },
  fileIconCell: {
    textAlign: 'center',
    selectors: {
      '&:before': {
        content: '.',
        display: 'inline-block',
        verticalAlign: 'middle',
        height: '100%',
        width: '0px',
        visibility: 'hidden',
      },
    },
  },
  fileIconImg: {
    verticalAlign: 'middle',
    maxHeight: '16px',
    maxWidth: '16px',
  },
  controlWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  exampleToggle: {
    display: 'inline-block',
    marginBottom: '10px',
    marginRight: '30px',
  },
  selectionDetails: {
    marginBottom: '20px',
  },
}); 
   
function FileViewer({ document, searchValue, setSelectedAttachments }) { 

    function _copyAndSort(items, columnKey, isSortedDescending) {
        const key = columnKey;
        return items.slice(0).sort((a, b) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
    } 
   
    function mapAttachmentData(doc, text) {
        if (!doc || !doc.attachments || doc.attachments.length === 0) {
            return [];
        }
        const _allItems = doc.attachments.map(a => {
            const ext = path.extname(a.fileName);  
            return {
                key: a.fileId,
                name: a.fileName,
                value: a.fileName,
                icon: (<Icon {...getFileTypeIconProps({ extension: ext, size: 16, imageFileType: 'png' }) } />),
                fileType: ext,
                addedBy: a.author.displayName,
                dateModifiedValue: (new Date(a.created)).valueOf(),
                dateModified: (moment(a.created)).format('MMM DD, YYYY'),
                fileSize: bytesToSize(a.size),
                fileSizeRaw: a.size,
                self: a.self,
            }
        });
        if (text) {
            return _allItems.filter(i => i.name.toLowerCase().indexOf(text) > -1);
        } else {
            return _allItems;
        } 
    }
 
    function bytesToSize(bytes) {
        var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        if (bytes == 0) return '0 Byte';
        var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
        return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
     }

    const _columns = [
        {
            key: 'column1',
            name: 'File Type',
            className: classNames.fileIconCell,
            iconClassName: classNames.fileIconHeaderIcon,
            ariaLabel: 'Column operations for File type, Press to sort on File type',
            iconName: 'Page',
            isIconOnly: true,
            fieldName: 'name',
            minWidth: 16,
            maxWidth: 16,
            onColumnClick: _onColumnClick,
            onRender: (item) => (
              <TooltipHost content={`${item.fileType.toUpperCase()}`}>
                  {item.icon}
              </TooltipHost>
            ),
          },
          {
            key: 'column2',
            name: 'Name',
            fieldName: 'name',
            minWidth: 210,
            maxWidth: 350,
            isRowHeader: true,
            isResizable: true,
            isSorted: true,
            isSortedDescending: false,
            sortAscendingAriaLabel: 'Sorted A to Z',
            sortDescendingAriaLabel: 'Sorted Z to A',
            onColumnClick: _onColumnClick,
            data: 'string',
            isPadded: true,
          },
          {
            key: 'column3',
            name: 'Date Added',
            fieldName: 'dateModifiedValue',
            minWidth: 70,
            maxWidth: 90,
            isResizable: true,
            onColumnClick: _onColumnClick,
            data: 'number',
            onRender: (item) => {
              return <span>{item.dateModified}</span>;
            },
            isPadded: true,
          },
        //   {
        //     key: 'column4',
        //     name: 'Added By',
        //     fieldName: 'addedBy',
        //     minWidth: 70,
        //     maxWidth: 90,
        //     isResizable: true,
        //     isCollapsible: true,
        //     data: 'string',
        //     onColumnClick: _onColumnClick,
        //     onRender: (item) => {
        //       return <span>{item.addedBy}</span>;
        //     },
        //     isPadded: true,
        //   },
          {
            key: 'column5',
            name: 'File Size',
            fieldName: 'fileSizeRaw',
            minWidth: 70,
            maxWidth: 90,
            isResizable: true,
            isCollapsible: true,
            data: 'number',
            onColumnClick: _onColumnClick,
            onRender: (item) => {
              return <span>{item.fileSize}</span>;
            },
          },
    ];

    function _getSelectionDetails() {
        return selection.getSelection(); 
    }

    const [allItems, setAllItems] = useState(mapAttachmentData(document)) 
    const [items, setItems] = useState(allItems);
    const [selection, setSelection] = useState(new Selection({
        onSelectionChanged: () => {
            setSelectionDetails(_getSelectionDetails());
        },
    }));
    const [columns, setColumns] = useState(_columns);
    const [selectionDetails, setSelectionDetails] = useState(_getSelectionDetails());
    const [isCompactMode, setIsCompactMode] = useState(false);
    const [isModalSelection, setIsModalSelection] = useState(true); 
   
    useEffect(() => { 
        _onChangeText(null, searchValue);
    }, [searchValue]);

    useEffect(() => {
        if (setSelectedAttachments) setSelectedAttachments(selectionDetails);
    }, [selectionDetails])

    function _onColumnClick (ev, column) {
        const newColumns = columns.slice();
        const currColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
        newColumns.forEach((newCol) => {
        if (newCol === currColumn) {
            currColumn.isSortedDescending = !currColumn.isSortedDescending;
            currColumn.isSorted = true; 
        } else {
            newCol.isSorted = false;
            newCol.isSortedDescending = true;
        }
        });
        const newItems = _copyAndSort(items, currColumn.fieldName, currColumn.isSortedDescending);
        setColumns(newColumns);
        setItems(newItems); 
    };
        
    function _getKey(item, index) {
        return item.key;
    }

    function _onChangeCompactMode (ev, checked) {
        setIsCompactMode(checked); 
    };

    function _onChangeModalSelection (ev, checked) {
        setIsModalSelection(checked); 
    };

    function _onChangeText (ev, text) {
        setItems(text ? allItems.filter(i => i.name.toLowerCase().indexOf(text) > -1) : allItems);
    };

    function _onItemInvoked(item) {
        alert(`Item invoked: ${item.name}`);
    }
 
    return (
        <ThemeProvider> 
            {isModalSelection ? (
            <MarqueeSelection selection={selection}>
                <DetailsList
                items={mapAttachmentData(document, searchValue)}
                compact={isCompactMode}
                columns={columns}
                selectionMode={SelectionMode.multiple}
                getKey={_getKey}
                setKey="multiple"
                layoutMode={DetailsListLayoutMode.justified}
                isHeaderVisible={true}
                selection={selection}
                selectionPreservedOnEmptyClick={true}
                onItemInvoked={_onItemInvoked}
                enterModalSelectionOnTouch={true}
                ariaLabelForSelectionColumn="Toggle selection"
                ariaLabelForSelectAllCheckbox="Toggle selection for all items"
                checkButtonAriaLabel="select row"
                />
            </MarqueeSelection>
            ) : (
            <DetailsList
                items={mapAttachmentData(document, searchValue)}
                compact={isCompactMode}
                columns={columns}
                selectionMode={SelectionMode.none}
                getKey={_getKey}
                setKey="none"
                layoutMode={DetailsListLayoutMode.justified}
                isHeaderVisible={true}
                onItemInvoked={_onItemInvoked}
            />
            )}
        </ThemeProvider>
    );
}