import React, { useState, useContext } from 'react'; 
import { motion } from 'framer-motion';
import CustomItemPicker from './CustomItemPicker';
import ExploreIcon from '@material-ui/icons/Explore';

import { 
  TextField, 
  Label, 
} from '@fluentui/react';

import { 
  ClickAwayListener, 
  Chip, 
  Typography 
} from '@material-ui/core';

import { AppContextState } from '../contexts/AppContext';
 

export function CustomTextField({label, textSize, textWeight, multiline, vertical, rows, onGetErrorMessage, placeholder, description, value, setValue, labelSpacing }) {
    const [editing, setEditing] = useState(false); 
    const [editValue, setEditValue] = useState(value || ''); 
    const applicationState = useContext(AppContextState); 

    function handleClickAway() {
      if (editing) {
        setEditing(false);
        if (setValue) setValue(editValue); 
      }
    }
  
    function handleOnClick() {
      setEditValue(value);
      setEditing(true); 
    }
  
    function handleKeyPress(event) {
      if (event.key === 'Enter') {
        handleClickAway();
      }
    }
   
    return (
      <ClickAwayListener onClickAway={handleClickAway}>
        <div 
          style={{
            display: 'flex', 
            width: '100%',
            flexDirection: (vertical) ? 'column' : 'row', 
            alignItems: (vertical) ? 'left' : 'center',
            textAlign: 'left', 
            flexBasis: '100%',
            textOverflow: 'ellipsis'
          }}> 
            {label && label.length > 0 && 
              <div style={{width: labelSpacing, textAlign: 'left', textOverflow: 'ellipsis'}}>
                <Label>{label}</Label>
              </div>
            }
 
            {(!editing) && 
              <motion.div  
                animate={{ rotate: (applicationState.user && applicationState.user.displayName && applicationState.user.displayName.toLowerCase().includes('gardner')) ? 360 : 0 }} 
                transition={{ duration: 0.25 }}
                style={{
                  borderRadius:'4px',
                  padding: '4px 8px',
                  alignItems: 'center',
                  marginLeft: (labelSpacing) ? '16px' : 0, 
                  marginBottom: (labelSpacing) ? '2px' : 0,
                }}
                whileHover={{
                  backgroundColor: '#F4F5F7'
                }} 
                >
                {(value && value.length > 0) && 
                  <Typography 
                    style={{cursor: 'pointer', textOverflow: 'ellipsis', fontFamily: 'Segoe UI', fontWeight: textWeight || undefined, fontSize: textSize || '14px', textAlign: 'left'}} 
                    onClick={handleOnClick}>
                      {value}
                  </Typography>}
                {(!value || value.length === 0) && 
                  <Typography 
                    style={{cursor: 'pointer', textOverflow: 'ellipsis', fontFamily: 'Segoe UI', fontWeight: textWeight || undefined, fontSize: textSize || '14px', color: '#5E6C84', textAlign: 'left'}} 
                    onClick={handleOnClick}>
                    {placeholder}
                  </Typography>}
              </motion.div> 
            }


            {/* {(editing && onRenderEditCustomField) && {onRenderEditCustomField}} */}
            {(editing) && 
             
              <TextField  
                styles={{root: {width: '100%'}}}
                autoFocus
                multiline={multiline} 
                rows={rows}
                onGetErrorMessage={onGetErrorMessage}
                description={description} 
                placeholder={placeholder}  
                value={editValue}
                onChange={(ev, value) => setEditValue(value)}
                onKeyPress={handleKeyPress}
                />
            } 
        </div>
      </ClickAwayListener >
    );
  }

  export function CustomPickerField({value, setValue, label, placeholder, labelSpacing, getOptions}) {
    const [editing, setEditing] = useState(false); 
    const [editValue, setEditValue] = useState(value || {}); 
  
    function handleClickAway() {
      if (editing) {
        setEditing(false);
        if (setValue) setValue(editValue); 
      }
    }
  
    function handleOnClick() {
      setEditValue(value);
      setEditing(true); 
    }
  
    function handleKeyPress(event) {
      if (event.key === 'Enter') {
        handleClickAway();
      }
    }
  
    // React.useEffect(() => {
    //   console.log(value)
    // }, [value])
   
    return (
      <ClickAwayListener onClickAway={handleClickAway}>
        <div 
          style={{
            display: 'flex', 
            flexDirection: 'row', 
            alignItems: 'center',
            width: '350px',
            textAlign: 'left',
          }}> 
            {label && label.length > 0 && 
              <div style={{width: labelSpacing}}>
                <Label>{label}</Label>
              </div>
            }
            {(!editing) && 
              <motion.div  
                // animate={{ rotate: 360 }}
                transition={{ duration: 0.25 }}
                style={{
                  borderRadius:'4px',
                  padding: '4px 8px',
                  marginLeft: '16px', 
                  marginBottom: '2px',
                }}
                whileHover={{
                  backgroundColor: '#F4F5F7'
                }} 
                >
                {(value) && 
                  <Typography 
                    style={{cursor: 'pointer', fontFamily: 'Segoe UI', fontSize: '14px'}} 
                    onClick={handleOnClick}> 
                    <>
                    { (value && value.length > 0) && 
                      <Chip 
                        style={{borderRadius: 2, fontWeight:600, padding:'14px 6px', color: 'white', backgroundColor: '#0078D4', fontFamily: 'Segoe UI', fontSize: '14px'}} 
                        icon={<ExploreIcon style={{color: 'white'}}/>} 
                        size="small" 
                        label={value[0].label}/>
                    }
                    { (!value || value.length === 0) && "Unknown"}
                    </>
                  </Typography>}
                {(!value) && 
                  <Typography 
                    style={{cursor: 'pointer', fontFamily: 'Segoe UI', fontSize: '14px', color: '#5E6C84'}} 
                    onClick={handleOnClick}>
                    {placeholder}
                  </Typography>}
              </motion.div> 
            } 
            {(editing) && 
              <CustomItemPicker  
                autoFocus
                value={editValue} 
                setValue={setEditValue} 
                getOptions={getOptions} 
                limitResults 
                filterDuplicates 
                onKeyPress={handleKeyPress}
                /> 
            } 
        </div>
      </ClickAwayListener >
    );
  }
  
  