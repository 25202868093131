import React, { useState, useEffect } from 'react';
// import AppHeader from '../components/AppHeader';
import {
  Dropdown, 
  DropdownMenuItemType,  
  Stack,
  Label,  
  TextField,
  mergeStyleSets,
  DefaultButton,
  DetailsList, 
  buildColumns,
  HoverCard, 
  Selection,
  CommandBarButton,
  ThemeProvider,
  Pivot,  
  TooltipHost,
  PanelType,
  Panel, 
  Icon,
  Toggle,
  PivotItem,
  DatePicker, DayOfWeek, 
} from '@fluentui/react'; 
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';

import { makeStyles } from '@material-ui/core/styles'  
// import Paper from '@material-ui/core/Paper'; 
// import LinearProgress from '@material-ui/core/LinearProgress';
// import GetAppIcon from '@material-ui/icons/GetApp';  
// import AddIcon from '@material-ui/icons/Add';
// import IconButton from '@material-ui/core/IconButton'; 
// import { colors } from '@atlaskit/theme'; 
// import List from '@material-ui/core/List';
import { Accordion, AccordionSummary, AccordionDetails, AccordionActions } from '@material-ui/core';
// import ListItem from '@material-ui/core/ListItem';
// import ListItemIcon from '@material-ui/core/ListItemIcon';
// import ListItemText from '@material-ui/core/ListItemText';  
// import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
// import { FileDrop } from 'react-file-drop'; 
// import DeleteIcon from '@material-ui/icons/Delete';
// import Document24Icon from '@atlaskit/icon-file-type/glyph/document/24';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { AppConfig } from '../utils/config.utils';
import moment from 'moment';
import ProductTypePicker from '../components/ProductTypePicker';
// import ProductPicker from '../components/ProductPicker';
import { v4 as uuidv4 } from "uuid";
import CustomFileDropper from '../components/CustomFileDropper';
import {
  CustomTextField,
  CustomPickerField,
} from '../components/CustomFields';

// const axios = require('axios');
// const ax = axios.create({
//     baseURL: `${AppConfig.PUBLIC_API_URL}/api/latest/`, 
//     headers: { 
//       'Content-Type': 'application/json', 
//     } 
// }); 

// // async function getDocument(data) {
// //   return new Promise((resolve, reject) => { 
// //     ax.get(`/ship/document?key=${data.key}&code=${AppConfig.PUBLIC_API_CODE}`, {})
// //     .then(function (response) {
// //       // console.log(response);  
// //       resolve(response.data);
// //     })
// //     .catch(function (error) {
// //       console.log(error);
// //       reject(error);
// //     });
// //   });
// // }

const DayPickerStrings = {
  months: [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ],
  shortMonths: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],

  days: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],

  shortDays: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],

  goToToday: 'Go to today',
  prevMonthAriaLabel: 'Go to previous month',
  nextMonthAriaLabel: 'Go to next month',
  prevYearAriaLabel: 'Go to previous year',
  nextYearAriaLabel: 'Go to next year',
  closeButtonAriaLabel: 'Close date picker',
  monthPickerHeaderAriaLabel: '{0}, select to change the year',
  yearPickerHeaderAriaLabel: '{0}, select to change the month',
};

const controlClass = mergeStyleSets({
  control: {
    margin: '0 0 15px 0',
    maxWidth: '300px',
  },
});

const DatePickerBasicExample = ({label, placeholder, value, onChange}) => {
  const [firstDayOfWeek, setFirstDayOfWeek] = React.useState(DayOfWeek.Sunday);
  return (
    <div>
      {label && <Label>{label}</Label>}
      <DatePicker
        className={controlClass.control}
        firstDayOfWeek={firstDayOfWeek}
        strings={DayPickerStrings}
        placeholder={placeholder}
        ariaLabel={placeholder}
        value={value}
        onSelectDate={onChange}
      /> 
    </div>
  );
};
 
const stackTokens = { childrenGap: 10 };

export const ToggleCustomLabelExample = ({checkedText, uncheckedText, label, toolTipText}) => {
  return (
    <Stack tokens={stackTokens}> 
      <Toggle
        label={
          <div>
            {label + ' '}
            <TooltipHost content={toolTipText}>
              <Icon iconName="Info" aria-label={toolTipText} />
            </TooltipHost>
          </div>
        }
        inlineLabel
        onText={checkedText}
        offText={uncheckedText}
        onChange={_onChange}
      />
    </Stack>
  );
};

function _onChange(ev, checked) {
  console.log('toggle is ' + (checked ? 'checked' : 'not checked'));
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

function AccordianColumn(props) {
  const { children } = props;
  return (
    <div style={{ flexBasis: '100%',}}>
      {children}
    </div>
  )
}

function SimpleAccordion() {
  const classes = useStyles();
  const [allocations, setAllocations] = useState([
    // {
    //   id: uuidv4(),
    //   expanded: false, 
    //   item: [
    //     {name: 'Kit Box', key: 'Kit Box'}
    //   ],  
    //   startDate: null,
    //   endDate: null,
    //   allocated: [], 
    // }
  ]);
  
  const stackStyles = { root: { height: 44, marginBottom: '8px' } };

  return (
    <div className={classes.root}>
    <Stack horizontal styles={stackStyles}>
        <CommandBarButton
          iconProps={addIcon}
          text="Add"  
          onClick={()=> setAllocations(s => {
            return [{
              id: uuidv4(),
              expanded: true, 
              item: [],  
              startDate: null,
              endDate: null,
              allocated: [], 
            }, ...s]
          })}
        />
      </Stack>
      {allocations.map((x, idx) => {
        return ( 
          <Accordion key={idx} expanded={x.expanded} onChange={(o, exp) => setAllocations(s => {s[idx].expanded = exp; return [...s]})} square>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`panel${idx}a-content}`}
              id={`panel${idx}a-header}`}>
                {x.expanded && <Typography className={classes.heading}>Allocation Unit</Typography>} 
                {!x.expanded && 
                  <> 
                    <AccordianColumn>
                      <Typography className={classes.heading}>{x.item.map(x => x.name).join(', ')}</Typography>
                    </AccordianColumn> 
                    <AccordianColumn>
                      <Typography className={classes.heading}>Allocated {moment(x.startDate).format('MMM DD, YYYY')} to {moment(x.endDate).format('MMM DD, YYYY')}</Typography>
                    </AccordianColumn> 
                    <AccordianColumn>
                      <Typography className={classes.heading}>{x.allocated}</Typography>
                    </AccordianColumn>
                  </>
                }
            </AccordionSummary>
          <AccordionDetails>  
            <Stack>
              <Stack>
                <Stack horizontal>
                  <DatePickerBasicExample value={x.startDate} onChange={(v) => setAllocations(s => {s[idx].startDate = v; return [...s];})} label="Start Date"/>
                  <Spacer width="16px"/>
                  <DatePickerBasicExample value={x.endDate} onChange={(v) => setAllocations(s => {s[idx].endDate = v; return [...s];})} label="End Date"/> 
                </Stack> 
                
                {/* <CustomTextField value={x.item} onChange={(v) => setAllocations(s => {s[idx].item = v; return [...s];})} labelSpacing={'64px'} label="Item"/> */}
                {/* <Spacer height="16px"/>
                <CustomTextField value={x.quantity} onChange={(v) => setAllocations(s => {s[idx].quantity = v; return [...s];})} labelSpacing={'64px'} label="Quantity"/>  */}
              </Stack> 
              <Stack horizontal>
                <ProductTypePicker value={x.item} setValue={(v) => setAllocations(s => {s[idx].item = v; return [...s]})} label={'Allocated'}/>
                {/* <Spacer width='16px'/>
                <ProductPicker label={'Products'}/> */}
                {/* <CustomTextField value={x.allocated} onChange={(v) => setAllocations(s => {s[idx].allocated = v; return [...s];})} label="Allocated"/>  */}
              </Stack>
            </Stack>
          </AccordionDetails>
            <Divider/>
            <AccordionActions> 
              <DefaultButton primary onClick={() => setAllocations(s => s.filter(b => x.id !== b.id))}>
                Delete
              </DefaultButton>
            </AccordionActions>
          </Accordion>
        )
      })} 
    </div>
  );
}

const Spacer = ({ height, width }) => {
  return (
    <div style={{height: height, width: width}}/>
  )
}

const PanelBasic = ({open, setOpen, data}) => { 
  const labelSpacing = '72px';

  return (
    <div>
      <Panel
        headerText="Run 1"
        isOpen={open}
        type={PanelType.largeFixed}
        onDismiss={() => setOpen(false)}
        // You MUST provide this prop! Otherwise screen readers will just say "button" with no label.
        closeButtonAriaLabel="Close"
        >
          <Spacer height="16px"/>
          <div style={{display: 'flex', justifyContent: 'space-between'}}>
            <CustomTextField 
                labelSpacing={labelSpacing} 
                placeholder='Enter the run number'
                label="Run #" />  
            <ButtonSplitExample/>
          </div>
          <Divider style={{margin: '16px 0px'}}/> 
          <ToggleCustomLabelExample toolTipText={'Indicates whether this is a field trial or a regular run.'} label={'Field trial'} checkedText={'Yes'} uncheckedText={'No'}/>
          <ToggleCustomLabelExample toolTipText={'Indicates that an incident occurred on this run.'} label={'Incident occured?'} checkedText={'Yes'} uncheckedText={'No'}/>
          <Divider style={{margin: '12px 0px'}}/>
          <Stack horizontal>
            <DatePickerBasicExample label={'Start Date'} placeholder={"Select a date..."}/>
            <Spacer width="16px"/>
            <DatePickerBasicExample label={'End Date'} placeholder={"Select a date..."}/>
          </Stack>
          <Divider style={{margin: '16px 0px'}}/>
        <SimpleAccordion/>
      </Panel>
    </div>
  )
};
 

const PivotIconCountExample = (props) => {
  const { items, tasks, notes, attachments, onRenderAttachments, onRenderRuns, onRenderTasks, onRenderNotes } = props;
  return ( 
    <Pivot style={{textAlign: 'left'}} aria-label="Job board table">
      {onRenderRuns && <PivotItem headerText="Runs" itemCount={(items && items.length > 0) ? items.length : undefined} itemIcon="ScheduleEventAction">
        {onRenderRuns(items)}
      </PivotItem>}
      {onRenderNotes && <PivotItem headerText="Job Notes" itemCount={(notes && notes.length > 0) ? notes.length : undefined} itemIcon="AddNotes">
        {onRenderNotes(notes)}
      </PivotItem>}
      {onRenderTasks && <PivotItem headerText="Tasks" itemCount={(tasks && tasks.length > 0) ? tasks.length : undefined} itemIcon="TaskLogo">
        {onRenderTasks(tasks)}
      </PivotItem>}
      {onRenderAttachments && <PivotItem headerText="Attachments" itemCount={(attachments && attachments.length > 0) ? attachments.length : undefined} itemIcon="Attach">
        {onRenderAttachments(attachments)}
      </PivotItem>}  
    </Pivot> 
  );
}; 
 


const ButtonSplitExample = (props) => {
  const { disabled, checked, document, onChange } = props;
  const statusMenuProps = {
    items: [
      {
        key: 'planning',
        text: 'Planning',
        category: 'unresolved',
        iconProps: { iconName: 'PlanView' },
        onClick: handleStatusChange
      },
      {
        key: 'active',
        text: 'Active',
        category: 'unresolved',
        iconProps: { iconName: 'LightningBolt' },
        onClick: handleStatusChange
      },
      {
        key: 'inactive',
        text: 'Inactive',
        category: 'resolved',
        iconProps: { iconName: 'CompletedSolid' },
        onClick: handleStatusChange
      },
      {
        key: 'canceled',
        text: 'Canceled',
        category: 'resolved',
        iconProps: { iconName: 'Cancel' },
        onClick: handleStatusChange
      },
    ]
  };  
  
  const [status, setStatus] = useState(statusMenuProps.items[0]);
  
  useEffect(() => {
    // console.log('UPDATE STATUS', document);
    if (document && document.eventData && document.eventData.status) {
      var i = statusMenuProps.items.findIndex(x => x.key === document.eventData.status.key);
      if (i >= 0) {
        setStatus(statusMenuProps.items[i]);
      }
    }
  }, [document]);

  function handleStatusChange(ev, o) {
    setStatus(o);
    if (onChange) onChange({key: o.key, text: o.text, category: o.category, date: (new Date())});
  }

  return (
    <Stack horizontal wrap> 
      <DefaultButton
        text={status.text}
        iconProps={status.iconProps}
        primary
        split
        splitButtonAriaLabel="See 2 options"
        aria-roledescription="split button"
        menuProps={statusMenuProps} 
        disabled={disabled}
        checked={checked}  
      /> 
    </Stack>
  );
};

const addIcon = { iconName: 'Add' };
const uploadIcon = { iconName: 'Upload' }; 
const downloadIcon = { iconName: 'Download' };
const editIcon = { iconName: 'Edit' };
const deleteIcon = { iconName: 'Delete' };
const stackStyles = { root: { height: 44 } };

const ButtonCommandBarExample = props => {
  const { disabled, checked, selectedItems, onAdd, onEdit, onDownload, onDelete } = props;
  return (
    <Stack horizontal styles={stackStyles}>
      {(!selectedItems || selectedItems.length === 0) && <CommandBarButton
        iconProps={addIcon}
        text="Add" 
        disabled={disabled}
        checked={checked}
        onClick={onAdd}
      />}
      {/* {(!selectedItems || selectedItems.length === 0) && <CommandBarButton
        iconProps={uploadIcon}
        text="Upload"
        disabled={disabled}
        checked={checked}
      />} */}
      {(selectedItems && selectedItems.length > 0) && <CommandBarButton 
        iconProps={downloadIcon} 
        text="Download" 
        disabled={disabled} 
        onClick={onDownload}
        checked={checked} />}
      {(selectedItems && selectedItems.length === 1) && <CommandBarButton 
        iconProps={editIcon} 
        text="Edit" 
        disabled={disabled} 
        onClick={onEdit}
        checked={checked} />}
      {(selectedItems && selectedItems.length > 0) && <CommandBarButton 
        iconProps={deleteIcon} 
        text="Delete" 
        disabled={disabled}
        onClick={onDelete} 
        checked={checked} />}
    </Stack>
  );
};

const classNames = mergeStyleSets({
  compactCard: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },
  expandedCard: {
    padding: '16px 24px',
  },
  item: {
    selectors: {
      '&:hover': {
        textDecoration: 'underline',
        cursor: 'pointer',
      },
    },
  },
});

function createSingleListItem(i, loc, desc) {
  return {
    Key: i,
    Run: i, 
    Location: loc,
    Description: desc
  };
}

// function createListItems(count) {
//   var items = [];
//   for (let i = 0; i < count; i++) {
//     items.push(createSingleListItem(i, 'test', 'hello'))
//   }
//   return items;
// } 

const buildColumn = (items) => {
  return buildColumns(items).filter(column => column.name === 'Location' || column.name === 'Run');
};

const onRenderCompactCard = (item) => {
  return (
    <div className={classNames.compactCard}>
      <a target="_blank" href={`http://wikipedia.org/wiki/${item.Location}`}>
        {item.Location}
      </a>
    </div>
  );
};
 
const onRenderExpandedCard = (item) => {
  return (
    <div className={classNames.expandedCard}>
      {item.Description}
      {/* <DetailsList setKey="expandedCardSet" items={items} columns={buildColumn()} /> */}
    </div>
  );
};

const onRenderItemColumn = (item, index, column) => {
  const expandingCardProps = {
    onRenderCompactCard: onRenderCompactCard,
    onRenderExpandedCard: onRenderExpandedCard,
    renderData: item,
  };
  if (column.key === 'Location') {
    return (
      <HoverCard expandingCardProps={expandingCardProps} instantOpenOnClick={true}>
        <div className={classNames.item}>{item.Location}</div>
      </HoverCard>
    );
  }
  return item[column.key];
};

const HoverCardBasicExample = (props) => {
  const { items, setItems } = props; 

  function handleSelectionChanged() {
    const _selectedItems = selection.getSelection(); 
    setItems(s => { 
      for (let i = 0; i < s.length; i++) {
        s[i].Selected = (_selectedItems.filter(x => x.Key === s[i].Key).length > 0)
      } 
      return [...s];
    }); 
  }
 
  const selection = new Selection({
    onSelectionChanged: handleSelectionChanged
  });

  return (
    <ThemeProvider>
      <DetailsList 
        setKey="hoverSet" 
        items={items} 
        compact
        columns={buildColumn(items)} 
        selection={selection}
        selectionPreservedOnEmptyClick={true}
        onRenderItemColumn={onRenderItemColumn} />
    </ThemeProvider>
  )
};

const customerOptions = [
  'Altitude', 
  'Baker Hughes Canada',
  'Baker Hughes USA',
  'Crescent', 
  'Drillers Directional', 
  'Ensign', 
  'Evolution Energy Canada',
  'Evolution Energy USA',
  'Halliburton', 
  'Phoenix Canada',
  'Phoenix USA',
  'Precision',
  'Premier',
  'Scout', 
  'Valiant',
  'Weatherford',
].map(x => ({key: x, name: x, label: x}));
 
function CreateJob({ document, setDocument, saveDocument, isModal }) {  
  const [listItems, setListItems] = useState([]); 
  const [panelOpen, setPanelOpen] = useState(false); 
  const labelSpacing = '60px'; 

  function handleUpdateJobStatus(status) { 
    document.eventData.status = status; 
    setDocument({...document});
    saveDocument();
  }

  function applyChange(field, value) {
    setDocument(d => {
      d.eventData[field] = value;
      return {...d};
    });
    saveDocument();
  } 

  return (
    <>
        <PanelBasic open={panelOpen} setOpen={setPanelOpen}/>
        <div style={{display: 'flex', justifyContent: 'space-between'}}> 
          <div style={{display: 'flex'}}>
            <div>
              <CustomTextField 
                value={document.eventData['jobNumber']}
                setValue={(value) => applyChange('jobNumber', value)}
                labelSpacing={labelSpacing} 
                placeholder='Enter a job number'
                label="Job #" />
                <Spacer height="8px"/>
              <CustomPickerField 
                placeholder="Select a client"
                labelSpacing={labelSpacing}
                value={document.eventData['client']} 
                setValue={(value) => applyChange('client', value)}
                label={'Client'} 
                getOptions={async () => { return customerOptions}}/>
                <Spacer height="8px"/>
              <CustomTextField 
                value={document.eventData['well']}
                setValue={(value) => applyChange('well', value)}
                labelSpacing={labelSpacing} 
                placeholder='Enter the name of the well'
                label="Well" />
            </div>
            <div style={{marginLeft: '32px'}}></div>
            <div>
              <Stack>
                <CustomTextField 
                  labelSpacing={labelSpacing} 
                  value={document.eventData['operator']}
                  setValue={(value) => applyChange('operator', value)}
                  placeholder='Enter a operator'
                  label="Operator" />
                <Spacer height="8px"/>
                <CustomTextField 
                  value={document.eventData['rig']}
                  setValue={(value) => applyChange('rig', value)}
                  labelSpacing={labelSpacing} 
                  placeholder='Enter a rig'
                  label="Rig" />
                <Spacer height="8px"/>
                <CustomTextField 
                  value={document.eventData['basin']}
                  setValue={(value) => applyChange('basin', value)}
                  labelSpacing={labelSpacing} 
                  placeholder='Enter a basin'
                  label="Basin" />  
              </Stack> 
            </div>
          </div> 
          <div style={{height: '48px'}}>
            <ButtonSplitExample document={document} onChange={handleUpdateJobStatus}/>
          </div>
        </div>
        <div style={{marginTop: '8px' }}>
          <PivotIconCountExample 
            items={document.eventData.runs || []}
            tasks={['a', 'b']}
            notes={['a']}
            attachments={(document) ? document.attachments : []}
            onRenderAttachments={() => {
              return (
                  <CustomFileDropper  
                    document={document}
                    setDocument={setDocument}/>
              )
            }}
            // onRenderTasks={() => {
            //   return (
            //     <>
            //     </>
            //   )
            // }}
            // onRenderNotes={() => {
            //   return (
            //     <>
            //     </>
            //   )
            // }}
            onRenderRuns={() => {
              function handleOnAdd() {
                setDocument(d => {
                  if (!d.eventData.runs) d.eventData.runs = [];
                  d.eventData.runs = [
                    createSingleListItem(d.eventData.runs.length + 1, 'new', 'data'), 
                    ...d.eventData.runs
                  ];
                  return {...d};
                });
                saveDocument(); 
              }
              function handleOnDelete() {
                setDocument(d => {
                  if (!d.eventData.runs) d.eventData.runs = [];
                  d.eventData.runs = d.eventData.runs.filter(x => !x.Selected);
                  return {...d};
                }); 
              }
              return (
                <>
                  <Divider/>
                  <ButtonCommandBarExample 
                    onAdd={handleOnAdd} 
                    onDelete={handleOnDelete} 
                    onEdit={() => setPanelOpen(true)}
                    selectedItems={(document.eventData.runs) ? document.eventData.runs.filter(x => x.Selected === true) : []}/>
                  <Divider/>
                  {document.eventData.runs && document.eventData.runs.length > 0 && 
                    <HoverCardBasicExample 
                      items={document.eventData.runs} 
                      setItems={(value) => {
                        setDocument(d => {
                          d.eventData.runs = value;
                          return {...d};
                        });
                        saveDocument();
                      }}/>}
                  {(!document.eventData.runs || document.eventData.runs.length === 0) && <EmptyListPage title={"You've started a new job!"} body={"This job doesn't have any runs yet. You can create one by clicking 'Add'."}/>}
                </>
              );
            }}/>
        </div> 
    </>
  );
}

function EmptyListPage({title, body}) {
  return (
    <div style={{marginTop:'50px', display: 'flex', flexDirection: 'column', justifyContent: 'center', textAlign: 'center'}}>
      <img height='128px' src='/queue-empty.svg'/>
      <Typography style={{fontWeight:600, marginTop: '16px', marginLeft: '16px', fontFamily: 'Segoe UI'}} variant='h6'>{title}</Typography>
      <Typography style={{fontFamily: 'Segoe UI', marginTop: '16px'}} variant='body2'>{body}</Typography>
    </div>
  )
}

export default CreateJob;