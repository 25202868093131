import React, { useEffect, useState, useContext } from 'react'; 
import { makeStyles } from '@material-ui/core/styles' 
import {Alert, AlertTitle} from '@material-ui/lab'; 
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography'; 
import Divider from '@material-ui/core/Divider'; 
import LinearProgress from '@material-ui/core/LinearProgress';
import GetAppIcon from '@material-ui/icons/GetApp'; 
import IconButton from '@material-ui/core/IconButton';  
import { colors } from '@atlaskit/theme';  
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText'; 
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import { FileDrop } from 'react-file-drop'; 
import DeleteIcon from '@material-ui/icons/Delete';  
import Document24Icon from '@atlaskit/icon-file-type/glyph/document/24';
import ProductTable from '../components/Tables/ProductTable';
import { v4 as uuidv4 } from "uuid";  
import { 
  CustomTextField,
  CustomPickerField
} from '../components/CustomFields';
import CustomFileDropper from '../components/CustomFileDropper';
import {
  ChecklistCollection
} from '../components/Checklist';
import {
  Pivot, 
  Stack,
  DefaultButton,
  CommandBarButton,
  PivotItem,
  TextField, 
  Label, 
  SearchBox,
  CommandButton
} from '@fluentui/react';
 
// Utilities
import moment from 'moment';
import { AppConfig } from '../utils/config.utils'; 
import { AppContext, AppContextState, useDocumentAPI } from '../contexts/AppContext';

import { MapContainer, TileLayer, Marker, Popup, useMapEvents, useMap } from 'react-leaflet';
import Geocode from 'react-geocode';
import L from 'leaflet';
import './MapPage.css';

Geocode.setApiKey("AIzaSyASTdruLkuSduVdi0O7GmgIubSNknQGNsQ");
Geocode.setLanguage("en");

const API_CODE = AppConfig.PUBLIC_API_CODE;
const API_URL = AppConfig.PUBLIC_API_URL;//'https://services.evolutioneng.com'; //
const axios = require('axios');
const axiosInstance = axios.create({
  baseURL: `${API_URL}/api/latest`,
  // timeout: 1000,
  headers: {'Content-Type': 'application/json'}
});

async function getAddressBook() { 
  return new Promise((resolve, reject) => { 
    axiosInstance.get(`/addressbook?code=${AppConfig.PUBLIC_API_CODE}`, {})
    .then(function (response) {
      // console.log(response);  
      resolve(response.data);
    })
    .catch(function (error) {
      console.log(error);
      reject(error);
    });
  });
}

const useStyles = makeStyles((theme) => ({
    root: {
      minWidth: '800px',
      backgroundColor: '#FFFFFF',
      border: 'solid 1px #E0E0E0'
    },
    paper: {
        width: "100%",
        minWidth: '450px',
        // marginTop: '10px', 
        // border: '1px solid #DFE1E6',
        // borderRadius: '5px',
    },
    item: {
        padding: "5px",
        color: colors.N800, 
        fontWeight: 550,
        fontFamily:'Segoe UI'
    },
    center: {
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        width: "50%"
    },
    break: {
        flexGrow: 1,
        fontWeight: "bold"
    },
    button: {
        display: "block"
    },
    error: {
        color: "red",
    },
    errorIcon: {
        position: "relative",
        color: "red",
        fontSize: "14px",
        top: "3px",
        paddingRight: "3px"
    },
    errorDiv: {
        display: "inline-block"
    },
    puzzle: {
        position: "relative",
        top: "3px",
        paddingRight: "3px",
        color: "#E5C92D",
        fontSize: "14px",
    },
    puzzleText: {
        color: "#E5C92D",
    }, 
    fields: {
        display: 'flex',
        flexDirection: 'column', 
        margin: '10px',
        alignItems: 'flex-start',
        width: '100%',
        flexBasis: '33%'
    }, 
    fieldCollection: {
        display: 'flex',
        margin: '10px',
        // maxWidth:'500px',
    },
    textBoxItem: {
        marginBottom: '5px',
        width: '260px',
    }
}));

function AddressTable({ manifestInfo, handlePropertyChange, handleCompanyFromChange, handleCompanyToChange, disabled }) {
  const classes = useStyles();
 
  const [optionList, setOptionList] = useState(companyList.map(x => ({...x, key: x.label, name: x.label})))

  function _getOption(o) {
    var a = optionList.filter(x => x.name===o.Location || x.name===o.Company);
    if (a && a.length > 0) { 
      return [a[0]];
    }
    return null;
  }
 
  const labelSpacing = '96px';
  const [mapPosition, setMapPosition] = useState([30.309665428, -95.455331512]); 
   
  function CustomMarker({location, name, locationType}) {
    const map = useMap();
    map.flyTo({lat: location[0], lng: location[1]}, map.getZoom());
    return (
      <Marker position={mapPosition}>
        <Popup>
          <Label>{name}</Label>
          {locationType && <Label style={{fontWeight: 400}}>{locationType}</Label>}
        </Popup>
      </Marker>
    )
  }

  useEffect(() => {
    // console.log(manifestInfo.To);
    var addr = '';
    addr += manifestInfo.To['Address'];
    addr += ' ';
    addr += manifestInfo.To['City, State, Zip']; 
    Geocode.fromAddress(addr).then(
      response => {
        // console.log(response.results);
        const { lat, lng } = response.results[0].geometry.location;
        const newPosition = [lat, lng];
        setMapPosition(newPosition); 
        // console.log(lat, lng);
      },
      error => {
        console.error(error);
      }
    );
  }, [manifestInfo])
 
  useEffect(() => {
    getAddressBook()
    .then((result) => {
      // console.log(result); 
      setOptionList(result.map(x => ({...x, key: x.label, name: x.label})));
    })
    .catch(console.log);
  }, []);

  if (!manifestInfo) {
    return (<p>Loading...</p>)
  }
  return (  
      <div className={classes.fieldCollection}> 
          <div className={classes.fields}>
              <Label style={{fontSize: '18px'}}>From:</Label>   
              <CustomPickerField
                placeholder="Pick a location"
                labelSpacing={labelSpacing}
                label="Location"
                value={_getOption(manifestInfo.From)} 
                setValue={(v) => {handleCompanyFromChange(null, v)}}
                getOptions={async () => {return optionList}}  
                />
              <CustomTextField labelSpacing={labelSpacing} label="Company" placeholder="Enter a value" value={manifestInfo.From['Company']} setValue={(value) => handlePropertyChange(value, 'From', 'Company')}/> 
              {(!manifestInfo.From.Location || manifestInfo.From.Location!=='Rig') && <CustomTextField labelSpacing={labelSpacing} label="Address" placeholder="Enter a value" value={manifestInfo.From['Address']} setValue={(value) => handlePropertyChange(value, 'From', 'Address')}/>}
              {(!manifestInfo.From.Location || manifestInfo.From.Location!=='Rig') && <CustomTextField labelSpacing={labelSpacing} label="City, State, Zip" placeholder="Enter a value" value={manifestInfo.From['City, State, Zip']} setValue={(value) => handlePropertyChange(value, 'From', 'City, State, Zip')}/>}
              {manifestInfo.From.Location==='Rig' && <CustomTextField labelSpacing={labelSpacing} label="Rig" placeholder="Enter a value" value={manifestInfo.From['Rig']} setValue={(value) => handlePropertyChange(value, 'From', 'Rig')} />  }
              {manifestInfo.From.Location==='Rig' && <CustomTextField labelSpacing={labelSpacing} label="Operator" placeholder="Enter a value" value={manifestInfo.From['Operator']} setValue={(value) => handlePropertyChange(value, 'From', 'Operator')} />  }
              <CustomTextField labelSpacing={labelSpacing} label="Country" placeholder="Enter a value" value={manifestInfo.From['Country']} setValue={(value) => handlePropertyChange(value, 'From', 'Country')} />  
              <CustomTextField labelSpacing={labelSpacing} label="Contact" placeholder="Enter a value" value={manifestInfo.From['Contact']} setValue={(value) => handlePropertyChange(value, 'From', 'Contact')}/> 
              <CustomTextField labelSpacing={labelSpacing} label="Phone" placeholder="Enter a value" value = {manifestInfo.From['Phone']} setValue={(value) => handlePropertyChange(value, 'From', 'Phone')}/> 
          </div> 
          <div className={classes.fields}>
              <Label style={{fontSize: '18px'}}>To:</Label>    
              <CustomPickerField
                placeholder="Pick a location"
                labelSpacing={labelSpacing}
                label="Location"
                value={_getOption(manifestInfo.To)} 
                setValue={(v) => {handleCompanyToChange(null, v)}} 
                getOptions={async () => {return optionList}}  
                /> 
              <CustomTextField labelSpacing={labelSpacing} label="Company" placeholder="Enter a value" value={manifestInfo.To['Company']} setValue={(value) => handlePropertyChange(value, 'To', 'Company')} /> 
              {(!manifestInfo.To.Location || manifestInfo.To.Location!=='Rig') && <CustomTextField labelSpacing={labelSpacing} label="Address" placeholder="Enter a value" value={manifestInfo.To['Address']} setValue={(value) => handlePropertyChange(value, 'To', 'Address')} /> }
              {(!manifestInfo.To.Location || manifestInfo.To.Location!=='Rig') && <CustomTextField labelSpacing={labelSpacing} label="City, State, Zip" placeholder="Enter a value" value={manifestInfo.To['City, State, Zip']} setValue={(value) => handlePropertyChange(value, 'To', 'City, State, Zip')} />  }
              {manifestInfo.To.Location==='Rig' && <CustomTextField labelSpacing={labelSpacing} label="Rig" placeholder="Enter a value" value={manifestInfo.To['Rig']} setValue={(value) => handlePropertyChange(value, 'To', 'Rig')} />  }
              {manifestInfo.To.Location==='Rig' && <CustomTextField labelSpacing={labelSpacing} label="Operator" placeholder="Enter a value" value={manifestInfo.To['Operator']} setValue={(value) => handlePropertyChange(value, 'To', 'Operator')} />  }
              <CustomTextField labelSpacing={labelSpacing} label="Country" placeholder="Enter a value" value={manifestInfo.To['Country']} setValue={(value) => handlePropertyChange(value, 'To', 'Country')} />
              <CustomTextField labelSpacing={labelSpacing} label="Contact" placeholder="Enter a value" value={manifestInfo.To['Contact']} setValue={(value) => handlePropertyChange(value, 'To', 'Contact')} /> 
              <CustomTextField labelSpacing={labelSpacing} label="Phone" placeholder="Enter a value" value={manifestInfo.To['Phone']} setValue={(value) => handlePropertyChange(value, 'To', 'Phone')} /> 
              
          </div> 
          <Paper elevation={3} style={{marginRight: '16px', flexBasis: '34%', borderRadius: '4px', marginTop: '16px', width: '350px', height: '300px'}}>
            <MapContainer center={mapPosition} zoom={10}>
              <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors' />
              <CustomMarker locationType={manifestInfo.To['Location Type']} name={manifestInfo.To['Company']} location={mapPosition}/> 
            </MapContainer>
          </Paper>
      </div>  
  );
} 

// function RigTable({ manifestInfo, handlePropertyChange, handleCompanyFromChange, handleCompanyToChange, disabled }) {
//   const classes = useStyles(); 
//   return (
//     <div style={{width: '100%'}}>
//     {/* <Paper elevation={0} className={classes.paper}>  */}
//       <div style={{display: 'flex', marginTop: '20px', marginLeft: '20px'}}>
//         <Typography style={{color: colors.N900, fontSize:'11pt', fontFamily: 'Segoe UI'}}>This shipment is </Typography> 
//         {disabled===false ?
//         (
//           <>
//             <Radio   
//               style={{marginLeft: '10px'}}
//               value="coming from a rig"
//               label="coming from a rig"
//               name="radio-default"
//               testId="radio-default"
//               isChecked={manifestInfo.direction===1}
//               onChange={({target: {checked}}) => {if (checked) handlePropertyChange(1, 'direction')}}
//               />
//             <Radio  
//               style={{marginLeft: '10px'}}
//               value="going to a rig"
//               label="going to a rig"
//               name="radio-default"
//               testId="radio-default"
//               isChecked={manifestInfo.direction===2}
//               onChange={({target: {checked}}) => {if (checked) handlePropertyChange(2, 'direction')}}
//               />
//               <Radio  
//               style={{marginLeft: '10px'}}
//               value="going between rigs"
//               label="going between rigs"
//               name="radio-default"
//               testId="radio-default"
//               isChecked={manifestInfo.direction===3}
//               onChange={({target: {checked}}) => {if (checked) handlePropertyChange(3, 'direction')}}
//               />
//           </>
//         )
//         :
//         (
//           <>
//           {manifestInfo.direction===1 && <Typography style={{color: colors.N900, fontSize:'11pt', fontWeight: 600, fontFamily: 'Segoe UI', marginLeft: '4px'}}>{' coming from a rig'}</Typography> }
//           {manifestInfo.direction===2 && <Typography style={{color: colors.N900, fontSize:'11pt', fontWeight: 600, fontFamily: 'Segoe UI', marginLeft: '4px'}}>{' going to a rig'}</Typography> }
//           {manifestInfo.direction===3 && <Typography style={{color: colors.N900, fontSize:'11pt', fontWeight: 600, fontFamily: 'Segoe UI', marginLeft: '4px'}}>{' going between rigs'}</Typography> } 
//           </>
//         )
//         } 
//         </div>
//         <Divider style={{width: '96%', margin: '2%'}}/> 
//         <div className={classes.fieldCollection}> 
//             <div className={classes.fields}>
//                 <Typography style={{fontWeight:550, color: colors.N800, }} variant="subtitle2">From:</Typography> 
//                 {(manifestInfo.direction===1 || manifestInfo.direction===3) && <FormattedTextField disabled={disabled} id="" label="Company" value={manifestInfo.From['Company']} onChange={(value) => handlePropertyChange(value, 'From', 'Company')} /> }
//                 {(manifestInfo.direction===1 || manifestInfo.direction===3) && <FormattedTextField disabled={disabled} id="" label="Rig" value={manifestInfo.From['Rig']} onChange={(value) => handlePropertyChange(value, 'From', 'Rig')} /> }
//                 {(manifestInfo.direction===1 || manifestInfo.direction===3) && <FormattedTextField disabled={disabled} id="" label="Operator" value={manifestInfo.From['Operator']} onChange={(value) => handlePropertyChange(value, 'From', 'Operator')} /> }
//                 {(manifestInfo.direction===2) && <div style={{ width: '100%'}}><CustomSelect disabled={disabled} onChange={handleCompanyFromChange} freeSolo={true} title="Company" options={companyList} value={manifestInfo.From['Company']}/> </div> }
//                 {<FormattedTextField disabled={disabled} id="" label="Contact" value={manifestInfo.From['Contact']} onChange={(value) => handlePropertyChange(value, 'From', 'Contact')}/> }
//                 {<FormattedTextField disabled={disabled} id="" label="Phone" value = {manifestInfo.From['Phone']} onChange={(value) => handlePropertyChange(value, 'From', 'Phone')}/> }
//                 {(manifestInfo.direction===2) && <FormattedTextField disabled={disabled} id="" label="Address" value={manifestInfo.From['Address']} onChange={(value) => handlePropertyChange(value, 'From', 'Address')}/> }
//                 {(manifestInfo.direction===2) && <FormattedTextField disabled={disabled} id="" label="City, State, Zip" value={manifestInfo.From['City, State, Zip']} onChange={(value) => handlePropertyChange(value, 'From', 'City, State, Zip')}/>}
//                 { <FormattedTextField disabled={disabled} id="from_country_rig" label="Country" value={manifestInfo.From['Country']} onChange={(value) => handlePropertyChange(value, 'From', 'Country')} />  }
//             </div> 
//             <div className={classes.fields}>
//                 <Typography style={{fontWeight:550, color: colors.N800, }} variant="subtitle2">To:</Typography>
//                 {(manifestInfo.direction===2 || manifestInfo.direction===3) && <FormattedTextField disabled={disabled} id="" label="Company" value={manifestInfo.To['Company']} onChange={(value) => handlePropertyChange(value, 'To', 'Company')} /> }
//                 {(manifestInfo.direction===2 || manifestInfo.direction===3) && <FormattedTextField disabled={disabled} id="" label="Rig" value={manifestInfo.To['Rig']} onChange={(value) => handlePropertyChange(value, 'To', 'Rig')} /> }
//                 {(manifestInfo.direction===2 || manifestInfo.direction===3) && <FormattedTextField disabled={disabled} id="" label="Operator" value={manifestInfo.To['Operator']} onChange={(value) => handlePropertyChange(value, 'To', 'Operator')} /> }
//                 {(manifestInfo.direction===1) && <div style={{width: '100%'}}><CustomSelect disabled={disabled} onChange={handleCompanyToChange} freeSolo={true} title="Company" options={companyList} value={manifestInfo.To['Company']}/> </div> }
//                 {<FormattedTextField disabled={disabled} id="" label="Contact" value={manifestInfo.To['Contact']} onChange={(value) => handlePropertyChange(value, 'To', 'Contact')}/> }
//                 {<FormattedTextField disabled={disabled} id="" label="Phone" value = {manifestInfo.To['Phone']} onChange={(value) => handlePropertyChange(value, 'To', 'Phone')}/> }
//                 {(manifestInfo.direction===1) && <FormattedTextField disabled={disabled} id="" label="Address" value={manifestInfo.To['Address']} onChange={(value) => handlePropertyChange(value, 'To', 'Address')}/> }
//                 {(manifestInfo.direction===1) && <FormattedTextField disabled={disabled} id="" label="City, State, Zip" value={manifestInfo.To['City, State, Zip']} onChange={(value) => handlePropertyChange(value, 'To', 'City, State, Zip')}/>}
//                 { <FormattedTextField disabled={disabled} id="to_country_rig" label="Country" value={manifestInfo.To['Country']} onChange={(value) => handlePropertyChange(value, 'To', 'Country')} />  }
//             </div> 
//         </div> 
//       {/* </Paper> */}
//       </div>
//   );
// }

// function FormattedTextField({disabled, onChange, value, label, id, showLabel}) {
//   const classes = useStyles();
//   return (
//     <Stack horizontal>
//       {disabled && 
//         (
//           <div>
//             {showLabel===true && <Typography variant="subtitle2">{label}</Typography>}
//             <Typography style={{fontFamily: 'Segoe UI', fontSize: '14px'}} variant="subtitle1">{value}</Typography>
//           </div>

//         )
//       }
//       {!disabled && <div style={{display: 'flex', justifyContent: 'center', flexDirection: 'column'}}><Label style={{width: '110px', textAlign: 'left', fontSize: '14px', fontFamily: 'Segoe UI'}}>{label}</Label></div>}
//       {!disabled && <TextField disabled={disabled} InputLabelProps={{shrink: true}} className={classes.textBoxItem} size="small" fullWidth id={id} value={value} onChange={({target: {value}}) => {if (!disabled) {onChange(value);}}} /> }
//     </Stack> 
//   );
// }


export default function CreateShipment({ document, isModal, setDocument, saveDocument, overrideSave }) { 

    const classes = useStyles();
    const [editMode, setEditMode] = useState('edit'); 
    const manifestInfoCopy = React.useRef(null); 
    const [downloadUrl, setDownloadUrl] = useState(null);
    const [loaded, setLoaded] = useState(false); 
    
    useEffect(() => {  
      if (document && document.id) {
        setEditMode('readonly'); 
        if (document.eventData.Manifest && document.eventData.Manifest.length > 0) {
          // console.log('MODIFIYING DATA')
          document.eventData.Manifest = document.eventData.Manifest.map(x => { 
            return {...x, id: x.id || uuidv4()};
          });
          setDocument({...document});
          // console.log(document);
        } 
        setAction({
          "type": 'event/load', 
          payload: {
              eventtype: 'Task',
              linkedEvent: document.id,
          },
        });
      }
      setLoaded(true);
    }, []);

    function handleCompanyFromChange(event, value) {
      // console.log(value); 
      if (!value || value.length === 0) {
        setDocument(s => { 
          s.eventData.From.Location = undefined; 
          return {...s};
        }); 
      } else if (['Rig', 'Client Facility', 'Repair Facility'].includes(value[0].label)) {
        setDocument(s => { 
          s.eventData.From.Location = value[0].label; 
          return {...s};
        });
      } else {
        value = value[0];
        setDocument(s => {
          s.eventData.From['Location Type'] = value.type;
          s.eventData.From.Location = value.label;
          s.eventData.From.Company = value.companyName;
          s.eventData.From.Contact = value.contact;
          s.eventData.From.Phone = value.phone;
          s.eventData.From.Address = value.address;
          s.eventData.From['City, State, Zip'] = value.cityStateZip;
          s.eventData.From.Country = value.country;
          return {...s};
        });
      }
      saveDocument(); 
    }

    function handleCompanyToChange(event, value) { 
      if (!value || value.length === 0) {
        setDocument(s => { 
          s.eventData.To.Location = undefined; 
          return {...s};
        }); 
      } else if (['Rig', 'Client Facility', 'Repair Facility'].includes(value[0].label)) {
        setDocument(s => { 
          s.eventData.To.Location = value[0].label; 
          return {...s};
        });
      } else {
        value = value[0];
        console.log(value);
        setDocument(s => { 
          s.eventData.To['Location Type'] = value.type;
          s.eventData.To.Location = value.label;
          s.eventData.To.Company = value.companyName;
          s.eventData.To.Contact = value.contact;
          s.eventData.To.Phone = value.phone;
          s.eventData.To.Address = value.address;
          s.eventData.To['City, State, Zip'] = value.cityStateZip;
          s.eventData.To.Country = value.country;
          return {...s};
        });
      }
      saveDocument(); 
    }

    function handlePropertyChange(value, field, subField) {
      setDocument(s => {   
        if (subField) {
          if (!s.eventData[field]) s.eventData[field] = {};
          s.eventData[field][subField] = value; 
        } else {
          s.eventData[field] = value; 
        }
        return {...s};
      }); 
      saveDocument(); 
    }

    useEffect(() => {
      if (overrideSave === true) {
        if (editMode === 'edit') {
          setEditMode('readonly');
          saveDocument(); 
        }
      }
    }, [overrideSave]);

    function handleOnEdit(trigger) {  
      if (trigger === 'cancel') {   
        setDocument(s => {
          s.eventData = JSON.parse(JSON.stringify(manifestInfoCopy.current));
          return {...s};
        }); 
        setEditMode('cancel'); 
      } else if (trigger === 'toggle') { 
        if (editMode === 'edit') { 
          setEditMode('readonly');
          saveDocument(); 
        } else {
          manifestInfoCopy.current = JSON.parse(JSON.stringify(document.eventData)); 
          setEditMode('edit');
        }
      }
    }  

    useEffect(() => { 
      if (downloadUrl) { 
        window.location.href = downloadUrl;
        setDownloadUrl(null);
      } 
    }, [downloadUrl]);
 
    const applicationState = useContext(AppContextState); 
    const applicationStateDispatcher = useContext(AppContext); 
    const [state, setAction] = useDocumentAPI();
 
    function handleSetData(item) { 
        setAction({
            "type": 'event/update', 
            payload: item
        });
    }
 
    if (!loaded) {
      return (<div>Loading...</div>)
    }

    return ( 
      <>  
        <div className={classes.root}>   
          {/* <CustomField label={'Location'} labelSpacing={'64px'} placeholder={'Enter a value'} value={customFieldValue} setValue={setCustomFieldValue}/> */}
          {!isModal && 
            <>
            <div style={{display:'flex', flexDirection: 'column', marginTop: '-50px'}}>
              <div style={{display: 'flex', width: '100%', flexDirection:'row', justifyContent: 'flex-end' }}>  
                <div style={{display: 'flex', alignItems:'center', flexDirection: 'row', marginRight: '10px'}}>
                    <Stack horizontal wrap>
                      {/* {editMode==='edit' && <CommandBarButton
                        iconProps={{ iconName: 'Cancel' }}
                        text="Cancel"  
                        onClick={() => handleOnEdit('cancel')}/>}  */}
                      {editMode==='edit' && <DefaultButton
                        iconProps={{ iconName: 'Save' }}
                        text="Save"  
                        primary
                        onClick={() => handleOnEdit('toggle')}/>}
                      {editMode==='edit' && <div style={{width: '16px'}}/>}
                      {/* {editMode!=='edit' && <CommandBarButton
                        iconProps={{ iconName: 'Edit' }}
                        text="Edit"  
                        onClick={() => handleOnEdit('toggle')}/>} */}
                      <CommandButton 
                        disabled={editMode==='edit'}
                        iconProps={{ iconName: 'Download' }}
                        text="Download manifest"  
                        onClick={() => setDownloadUrl(`${API_URL}/api/latest/ship/template?key=${document.id}&templateId=Manifest.docx&code=${API_CODE}`) }/>
                      <div style={{width: '16px'}}/>
                      <ButtonSplitExample document={document} disabled={editMode==='edit'} onChange={(status) => { 
                        document.eventData.status = status; 
                        setDocument({...document});
                        saveDocument();
                      }}/> 
                    </Stack> 
                </div>
              </div>   
            </div>
            {/* {editMode==='edit' && <Alert style={{marginTop: '20px', marginLeft: '10px', marginRight: '10px'}} severity="warning">You are editing this form. Click the save icon to confirm your changes!</Alert>} */}
            {document && document.eventData && document.eventData.status && document.eventData.status.key==='complete' && <Alert style={{marginTop: '20px', marginLeft: '10px', marginRight: '10px'}} severity="success">{'This shipment Was complete on ' + moment(document.eventData.status['date']).format('YYYY-MM-DD') + '.'}</Alert>}    
            {document && document.eventData && document.eventData.status && document.eventData.status.key==='canceled' && <Alert style={{marginTop: '20px', marginLeft: '10px', marginRight: '10px'}} severity="error">{'This shipment was abandoned on ' + moment(document.eventData.status['date']).format('YYYY-MM-DD') + '.'}</Alert>}    
            </>
          }    

          <AddressTable disabled={editMode!=='edit'} manifestInfo={document ? document.eventData : undefined} handlePropertyChange={handlePropertyChange} handleCompanyFromChange={handleCompanyFromChange} handleCompanyToChange={handleCompanyToChange} />
            
          <Divider /> 
          <PivotIconCountExample 
            items={(document && document.eventData) ? document.eventData.Manifest : undefined}
            tasks={applicationState.event.taskList}
            notes={['', '']}
            attachments={(document) ? document.attachments : []}
            onRenderAttachments={(!isModal) ? (() => {
              return ( 
                <>
                <Divider /> 
                <CustomFileDropper document={document} setDocument={setDocument}/>
                </>
              )
            }) : undefined}
            onRenderTasks={() => {
              return (
                <>
                <Alert 
                  style={{margin: '10px' }} 
                  // variant="outlined" 
                  severity="success"> 
                  <AlertTitle>Alert</AlertTitle>
                  You can now assign tasks for {document.id} by clicking on the task description and changing the <b>Assigned To</b> field. Tasks you assign to others will automatically appear in your <b>Watching</b> tab.
              </Alert>
                <ChecklistCollection 
                  tasks={applicationState.event.taskList} 
                  setTask={handleSetData}
                  onDeleteTask={(doc) => {
                    setAction({"type": 'event/delete', payload: doc })
                  }}
                  onAddTask={(newTask) => {
                    newTask.eventData.link = document.id;
                    setAction({"type": 'event/create', payload: newTask})
                  }}/>
                  </>
              )
            }}
            onRenderNotes={() => { 
              return (
                <RenderShipmentNotes 
                  overrideSave={overrideSave}
                  document={document} 
                  handlePropertyChange={handlePropertyChange}
                  onChange={() => {
                    saveDocument();
                  }}/>
              )
            }}
            onRenderItems={() => { 
              return (  
                <>
                <Divider /> 
                <ProductTable 
                  overrideSave={overrideSave}
                  isModal={isModal}
                  items={(document && document.eventData && document.eventData.Manifest) ? document.eventData.Manifest : []} 
                  setItems={(items) => {
                    setDocument(d => {
                      d.eventData.Manifest = [...items];
                      return {...d};
                    });
                    saveDocument();
                  }}
                  /> 

                {/* <SimpleList 
                  editMode={'edit'} 
                  manifestInfo={document.eventData} 
                  onChange={() => { 
                    // TODO: fix updater: updateShippingDocument(documentKey, {...manifestInfo});
                  }}/>   */}
                </>
              );
            }}/>  
    </div>  
</>
    );
};

function RenderShipmentNotes({document, handlePropertyChange, onChange, overrideSave}) {
  const [isEditing, setIsEditing] = useState(false);
  useEffect(() => {
    if (overrideSave === true) {
      if (isEditing) {
        onChange();
        setIsEditing(false);
      } 
    }
  }, [overrideSave])
  return (
    <Stack style={{margin: '16px'}}>
      {/* <Stack horizontal wrap styles={{ root: { height: 44 } }}> 
        <CommandBarButton  
          iconProps={{ iconName: (isEditing) ? 'Save' : 'Edit' }}
          text={(isEditing) ? "Save" : 'Edit'}
          onClick={() => {if (isEditing) {onChange();} setIsEditing(!isEditing);}}
          /> 
      </Stack>  */}
        <CustomTextField 
          vertical
          multiline
          rows={5} 
          placeholder="Provide some notes for the shipment." 
          value={(document && document.eventData) ? document.eventData['Shipment Notes'] : ''} 
          label="Shipment Notes" 
          setValue={(value) => handlePropertyChange(value, 'Shipment Notes')} />
        <CustomTextField 
          vertical
          multiline
          rows={5}
          placeholder="Include any special handling requirements, directions, or other instructions for the shipment." 
          value={(document && document.eventData) ? document.eventData['Special Instructions'] : ''} 
          label="Special Instructions" 
          setValue={(value) => handlePropertyChange(value, 'Special Instructions')} />
    </Stack>
  )
}

const ButtonSplitExample = (props) => {
  const { disabled, checked, document, onChange } = props;
  const statusMenuProps = {
    items: [
      {
        key: 'staging',
        text: 'Staging',
        category: 'unresolved',
        iconProps: { iconName: 'PlanView' },
        onClick: handleStatusChange
      },
      {
        key: 'complete',
        text: 'Complete',
        category: 'resolved',
        iconProps: { iconName: 'CompletedSolid' },
        onClick: handleStatusChange
      }, 
      {
        key: 'canceled',
        text: 'Canceled',
        category: 'resolved',
        iconProps: { iconName: 'Cancel' },
        onClick: handleStatusChange
      },
    ]
  };  
  
  const [options, setOptions] = useState(null)
  const [status, setStatus] = useState(statusMenuProps.items[0]);
  
  useEffect(() => { 
    if (document && document.eventData && document.eventData.status) {
      var i = statusMenuProps.items.findIndex(x => x.key === document.eventData.status.key);
      if (i >= 0) {
        setStatus(statusMenuProps.items[i]);
      }
    }
  }, [document]);

  useEffect(() => {
    setOptions({ items: statusMenuProps.items.filter(x => x.key !== status.key) });
  }, [status])

  function handleStatusChange(ev, o) {
    setStatus(o);
    if (onChange) onChange({key: o.key, text: o.text, category: o.category, date: (new Date())});
  }

  return (
    <Stack horizontal wrap> 
      <DefaultButton
        text={status.text}
        iconProps={status.iconProps}
        primary
        split
        splitButtonAriaLabel="See 2 options"
        aria-roledescription="split button"
        menuProps={options} 
        disabled={disabled}
        checked={checked}  
      /> 
    </Stack>
  );
};

  //  {/* {/* <AlertDialog 
  //         isOpen={completeDialog}  */}
  //         {/* // TODO: fix the date tracker on complete of shipment onClose={(isCompleted) => {  */}
  //         //   setCompleteDialog(false);  
  //         //   if (isCompleted) {
  //         //     setDocument(s => {
  //         //     // setManifestInfo(s => { 
  //         //       s.eventData['Date Shipped'] = Date(); 
  //         //       s.complete = true; 
  //         //      // TODO: remapp this:  updateShippingDocument(documentKey, s);
  //         //       return {...s};
  //         //     });  
  //         //   }
  //         // }} 
  //         // title='Complete the shipment?'
  //         // summary="The shipment will be considered 'shipped'. This means the manifest will no longer be editable and all products in the shipment will be updated with new location information."
  //         // yesLabel='OK' 
  //         // noLabel='Cancel'/> */}

const PivotIconCountExample = (props) => {
  const { items, tasks, notes, attachments, onRenderAttachments, onRenderItems, onRenderTasks, onRenderNotes } = props;
  return ( 
    <Pivot style={{textAlign: 'left'}} aria-label="pivottable"> 
      {onRenderItems && <PivotItem headerText="Manifest" itemCount={(items && items.length > 0) ? items.length : undefined} itemIcon="ScheduleEventAction">
        {onRenderItems(items)}
      </PivotItem>}
      {onRenderNotes && <PivotItem headerText="Notes" itemCount={(notes && notes.length > 0) ? notes.length : undefined} itemIcon="AddNotes">
        {onRenderNotes(notes)}
      </PivotItem>}
      {onRenderTasks && <PivotItem headerText="Tasks" itemCount={(tasks && tasks.length > 0) ? tasks.length : undefined} itemIcon="TaskLogo">
        {onRenderTasks(tasks)}
      </PivotItem>}
      {onRenderAttachments && <PivotItem headerText="Attachments" itemCount={(attachments && attachments.length > 0) ? attachments.length : undefined} itemIcon="Attach">
        {onRenderAttachments(attachments)}
      </PivotItem>}  
    </Pivot> 
  );
}; 

function FileDropper({document, setDocument}) {
  const chunkSize = 1048576 * 3;//its 3MB, increase the number measure in mb

  // const [showProgress, setShowProgress] = useState(false)
  // const [counter, setCounter] = useState(1)
  // const [fileToBeUpload, setFileToBeUpload] = useState({})
  // const [beginingOfTheChunk, setBeginingOfTheChunk] = useState(0)
  // const [endOfTheChunk, setEndOfTheChunk] = useState(chunkSize)
  // const [progress, setProgress] = useState(0)
  // const [fileGuid, setFileGuid] = useState("")
  // const [fileSize, setFileSize] = useState(0)
  // const [chunkCount, setChunkCount] = useState(0)
  const [loading, setLoading] = useState(false);
  const [frameEnter, setFrameEnter] = useState(false);
  const [dragEnter, setDragEnter] = useState(false); 
  const [progressPercent, setProgressPercent] = useState(null);
  const fileInputRef = React.useRef(null);

  const ax = axios.create({
    baseURL: `${API_URL}/api/latest/`, 
    headers: { 
      'Content-Type': 'application/json', 
    } 
  });

  function doUploadProgress(event) {
    if (!event || !event.loaded || !event.total || event.total === 0 ) {
      console.log('Bad event!', event);
      return;
    }
    setProgressPercent(Math.round((100 * event.loaded) / event.total));
  }

  const uploadChunk = async (file) => { 
    setLoading(true);
    if (document.attachments && document.attachments.filter(x => x.fileName === file.name).length > 0) {
      console.log('Attachment with file name already exists!');
      setLoading(false);
      return;
    }
    var formData = new FormData(); 
    formData.append("data", file, file.name); 
    const xconfig = {
      headers: { 'Content-Type': 'multipart/form-data' },
      onUploadProgress: doUploadProgress
    }; 
    const response = await ax.post(`/attachments?key=${document.id}&code=${AppConfig.PUBLIC_API_CODE}`, formData, xconfig)
    .catch((error) => {console.log(error); return {};}); 
    // console.log(response);  
    if (response.status===200) {
      setDocument(d => {
        d.attachments = [response.data, ...d.attachments || []];
        return {...d};
      });
    }
    setLoading(false);
    setProgressPercent(0);
  }; 

    // const uploadCompleted = async () => {
    //   var formData = new FormData();
    //   formData.append('fileName', fileGuid);
    //   const response = await ax.post(`/attachments?complete=true&code=${AppConfig.PUBLIC_API_CODE}`, {}, {
    //     params: {
    //       fileName: fileGuid,
    //     },
    //     data: formData,
    //   });
    //   const data = response.data;
    //   if (data.isSuccess) {
    //     setProgress(100);
    //   }
    // }

  // const resetChunkProperties = () => {
  //   setShowProgress(true)
  //   setProgress(0)
  //   setCounter(1)
  //   setBeginingOfTheChunk(0)
  //   setEndOfTheChunk(chunkSize)
  // }

  // const fileUpload = () => {
  //   setCounter(counter + 1);
  //   if (counter <= chunkCount) {
  //       var chunk = fileToBeUpload.slice(beginingOfTheChunk, endOfTheChunk);
  //       uploadChunk(chunk)
  //   }
  // }

  // const getFileContext = (e) => {
  //     console.log(e);
  //     resetChunkProperties();
  //     const _file = e;
  //     setFileSize(_file.size)
  //     const _totalCount = _file.size % chunkSize == 0 ? _file.size / chunkSize : Math.floor(_file.size / chunkSize) + 1; // Total count of chunks will have been upload to finish the file
  //     setChunkCount(_totalCount)
  //     setFileToBeUpload(_file)
  //     const _fileID = uuidv4() + "." + _file.name.split('.').pop();
  //     setFileGuid(_fileID)
  //   }

  const onFileInputChange = (event) => {
    const { files } = event.target;  
    Object.keys(files).forEach(key => {
      uploadChunk(files[key]); 
    }); 
  }

  const onTargetClick = () => {
    if (fileInputRef.current) fileInputRef.current.click();
  }

  function handleFrameEnter(event) { 
    // console.log(event.target)
    setFrameEnter(true);
  }

  function handleFrameLeave(event) { 
    // console.log(event.target)
    setFrameEnter(false);
  }

  function handleFrameDrop(event) { 
    // console.log(event.target)
    setFrameEnter(false);
  }

  function handleDragOver(event) { 
    // console.log(event.target)
    setDragEnter(true);
  }

  function handleDragLeave(event) { 
    // console.log(event.target)
    setDragEnter(false);
  }

  function handleDrop(files, event) { 
    setDragEnter(false);
    Object.keys(files).forEach(key => {
      // streamFile(files[key]); 
      uploadChunk(files[key]);
    }); 
  }

//   function handleEvent(event) {
//     var e = `${event.type}: ${event.loaded} bytes transferred\n`;
//     console.log(e);
// }

  // function streamFile(file) {
  //   const reader = new FileReader();
  //   reader.addEventListener('loadstart', handleEvent);
  //   reader.addEventListener('load', handleEvent);
  //   reader.addEventListener('loadend', handleEvent);
  //   reader.addEventListener('progress', handleEvent);
  //   reader.addEventListener('error', handleEvent);
  //   reader.addEventListener('abort', handleEvent);
  //   reader.onload = () => {
  //     const bs = reader.result;
  //     console.log(bs);
  //   };
  //   reader.readAsArrayBuffer(file);
  // }

  // function streamReadFile(file) {
  //   var data = '';
  //   var rs = fs.createReadStream(file);
  //   rs
  //   .on('data', (chunk) => {
  //     data += chunk;
  //   })
  //   .on('end', () => {
  //     console.log(data);
  //   });
  // }
 
  // useEffect(() => {
  //   console.log(progress);
  //   if (fileSize > 0) {
  //     fileUpload(counter);
  //   }
  // }, [fileToBeUpload, progress]);

  async function handleDeleteAttachment(fileId) {
    if (!fileId) return;
    const response = await axiosInstance.delete(`/attachments?key=${document.id}&fileId=${fileId}&code=${AppConfig.PUBLIC_API_CODE}`, {});
      console.log('DELETE', response);
    if (response && response.status === 200) {
      setDocument(d => {
        d.attachments = [...d.attachments.filter(x => x.fileId !== fileId)];
        return {...d};
      });
    }
  }

  return (
    <Paper elevation={(dragEnter) ? 12 : ((frameEnter) ? 3 : 0)} style={{display: 'flex', borderRadius: 0, width: '100%', height: '100%' }}>
      <input onChange={onFileInputChange} ref={fileInputRef} type="file" style={{visibility:'collapse', width: 0, height: 0}}/>
      <div style={{display: 'flex', flexDirection: 'column', width: '100%', height: '100%'}}>
        {/* <Divider/> */}
        <Stack horizontal styles={{ root: { height: 44 } }}> 
          <CommandBarButton
            iconProps={{ iconName: 'Upload' }}
            text="Upload"  
            onClick={onTargetClick}/>
          <Stack tokens={{ childrenGap: 20 }} style={{minWidth:'100px', marginLeft: '8px', marginTop: '6px', cursor: 'not-allowed'}}>
            <SearchBox 
                disabled
                styles={{ root: { width: 200 } }}
                placeholder="Filter" 
                iconProps={{ iconName: 'Filter' }}
                // onChange={(ev, newValue) => {setFileSearchValue(newValue.toLowerCase());}} 
                // onSearch={newValue => {setFileSearchValue(newValue);}} 
                />
            </Stack>  
        </Stack> 
        {loading && !progressPercent && <LinearProgress style={{width: '100%'}}/>}
        {loading && progressPercent && <LinearProgress variant="determinate" value={progressPercent} style={{width: '100%'}}/>}
        <FileDrop  
          onFrameDragEnter={handleFrameEnter}
          onFrameDragLeave={handleFrameLeave}
          onFrameDrop={handleFrameDrop}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}>
            <div style={{alignItems: 'center', justifyContent: 'center', borderRadius: '4px', display: 'flex', flexDirection:'column', height: '100%', width: '100%' }}>
              {document.attachments && document.attachments.length > 0 && <RenderAttachments document={document} deleteAttachment={handleDeleteAttachment}/>}
              {(!document.attachments || document.attachments.length === 0) &&  <EmptyListPage title="No attachments found." body="This shipment doesn't have any attachments."/>}
            </div>
        </FileDrop>
      </div>
    </Paper>
  );
}

function EmptyListPage({title, body}) {
  return (
    <div style={{marginTop:'50px', display: 'flex', flexDirection: 'column', justifyContent: 'center', textAlign: 'center'}}>
      <img height='128px' src='/queue-empty.svg'/>
      <Typography style={{fontWeight:600, marginTop: '16px', marginLeft: '16px', fontFamily: 'Segoe UI'}} variant='h6'>{title}</Typography>
      <Typography style={{fontFamily: 'Segoe UI', marginTop: '16px'}} variant='body2'>{body}</Typography>
    </div>
  )
}

const useAttachmentsStyles = makeStyles((theme) => ({
  root: { 
    width: '100%', 
    backgroundColor: 'transparent', 
  },
}));

function RenderAttachments({document, deleteAttachment}) {
  const [dl, setDL] = useState(null);
  const classes = useAttachmentsStyles(); 
  useEffect(() => {
    if (dl !== null) {
      window.location.href = dl;
      setDL(null);
    }
  }, [dl]);
  
  function getSizeString(size) {
    var val = size;
    var k = 'Bytes';
    if (val > 1024) {
      val = Math.round(val/1024, 2);
      k = 'KB';
    }
    if (val > 1024) {
      val = Math.round(val/1024, 2);
      k = 'MB';
    }
    if (val > 1024) {
      val = Math.round(val/1024, 2);
      k = 'GB';
    }
    return val + ' ' + k;
  }

  useEffect(() => {
    console.log(document.attachments);
  }, [document.attachments])

  return (
    <div className={classes.root}> 
      <List style={{backgroundColor: 'transparent'}} component="nav" aria-label="main mailbox folders">
      {document.attachments && document.attachments.length > 0 && document.attachments.map((attach) => { 
        return (
          <>
            <ListItem style={{backgroundColor: 'transparent'}} >
              <ListItemIcon>
                <Document24Icon />
              </ListItemIcon>
              <ListItemText primary={attach.fileName} secondary={getSizeString(attach.size)}/>
              <ListItemSecondaryAction>
                <IconButton onClick={(e) => {e.preventDefault(); setDL(attach.self + '&code=' + AppConfig.PUBLIC_API_CODE)}}  edge="end" aria-label="download">
                  <GetAppIcon />
                </IconButton>
                <IconButton onClick={(e) => {e.preventDefault(); deleteAttachment(attach.fileId)}} edge="end" aria-label="delete">
                  <DeleteIcon />
                </IconButton>
              </ListItemSecondaryAction> 
            </ListItem>  
            <Divider/>
          </>
        )
      })}
      </List>
    </div>
  );
} 

function CustomMultilineField({disabled, placeholder, rows, id, label, onChange, text}) {
  return (
    <>
    {disabled && <Typography style={{color: colors.N800, fontWeight:550, marginTop:'10px', marginLeft:'10px', textAlign:'left', }} variant="subtitle2">{label}</Typography>}
    {disabled && ((text && text.length === 0) || !text) && <Typography style={{color: colors.N80, fontSize:'12px', marginTop:'10px', marginLeft:'10px', textAlign:'left', }}>None</Typography>}
    {disabled && <Typography variant="caption" style={{display: 'flex', textAlign:'left', margin:'10px', maxWidth: '500px'}}>{text}</Typography>}
    {!disabled && <TextField placeholder={placeholder} value={text} disabled={disabled} style={{margin:'10px'}} size="small" fullWidth multiline rows={rows || 5} id={id} label={label} onChange={({target: {value}}) => onChange(value)} /> }
    </>
  )
}
 
const companyList = [
  { "countryCode": "USA", "label": "Altitude-Midland", "type": "Client Staging Area", "country": "United States", "address": "2308 SCR 1245", "phone": "(406) 690-6008", "contact": "Tim Cramer", "cityStateZip": "Midland, TX 79706", },
  { "countryCode": "USA", "label": "Nabors-Houston", "type": "Client Staging Area", "country": "United States", "address": "515 W. Greens Rd STE 1200", "phone": "(210) 887-8573", "contact": "Chris Alex", "cityStateZip": "Houston, TX 77067", },
  { "countryCode": "USA", "label": "Halliburton-Houston", "type": "Client Staging Area", "country": "United States", "address": "3950 Interwood S Pkwy", "phone": "N/A", "contact": "N/A", "cityStateZip": "Houston, TX 77302", },
  { "countryCode": "USA", "label": "Evolution-Conroe", "type": "Client Staging Area", "country": "United States", "address": "2418 N Frazier St. #103", "phone": "(877) 265-0345", "contact": "Joshua Sikorski", "cityStateZip": "Conroe, TX 77303", "companyName": "Evolution Energy Technologies"},
  { "countryCode": "USA", "label": "Premier-Fairmont", "type": "Client Staging Area", "country": "United States", "address": "732 Middletown Road", "phone": "(304) 282-8218", "contact": "Robert Hunley", "cityStateZip": "Fairmont, WV 26554", "companyName": "Premier Directional"},
  { "countryCode": "USA", "label": "Polaris-Spring", "type": "Client Staging Area", "country": "United States", "address": "21617 Rhodes Rd", "phone": "N/A", "contact": "N/A", "cityStateZip": "Spring TX, 77388", "companyName": "Polaris" },
  { "countryCode": "USA", "label": "Baker-MtPleasant", "type": "Client Staging Area", "country": "United States", "address": "370 Westec Dr", "phone": "N/A", "contact": "N/A", "cityStateZip": "Mt Pleasant, PA 15666", "companyName": "Baker Hughes" },
  { "countryCode": "USA", "label": "Phoenix-Houston", "type": "Client Staging Area", "country": "United States", "address": "12329 Cutten Rd.", "phone": "N/A", "contact": "N/A", "cityStateZip": "Houston, TX 77066", "companyName": "Phoenix Technology Services" },
  { "countryCode": "USA", "label": "Panther-OKC", "type": "Client Staging Area", "country": "United States", "address": "10600, 10626 W Reno Ave", "phone": "N/A", "contact": "N/A", "cityStateZip": "Oklahoma City, OK 73127", "companyName": "Panther Drilling Systems"},
  { "countryCode": "USA", "label": "Premier-Midland", "type": "Client Staging Area", "country": "United States", "address": "12910 TX-191", "phone": "N/A", "contact": "N/A", "cityStateZip": "Midland, TX 79707", "companyName": "Premier Directional Drilling"},
  { "countryCode": "USA", "label": "Halliburton-Bakersfield", "type": "Client Staging Area", "country": "United States", "address": "34772 7th Standard Rd", "phone": "N/A", "contact": "N/A", "cityStateZip": "Bakersfield, CA 93314", "companyName": "Halliburton" },
  { "countryCode": "USA", "label": "Scientific-Odessa", "type": "Client Staging Area", "country": "United States", "address": "325 S. Faudree Road", "phone": "(432) 306-0118", "contact": "Michael Williams", "cityStateZip": "Odessa, TX 79765", },
  { "countryCode": "USA", "label": "Scout-Conroe", "type": "Client Staging Area", "country": "United States", "address": "1125 Beach Airport Blvd", "phone": "N/A", "contact": "N/A", "cityStateZip": "Conroe, TX 77301", "companyName": "Scout Downhole"},
  { "countryCode": "USA", "label": "Baker-Houston", "type": "Client Staging Area", "country": "United States", "address": "17015 Aldine Westfield Rd", "phone": "N/A", "contact": "N/A", "cityStateZip": "Houston, TX 77073", "companyName": "Baker Hughes" },
  { "countryCode": "USA", "label": "Premier-Houston", "type": "Client Staging Area", "country": "United States", "address": "5611 Baird Court", "phone": "N/A", "contact": "N/A", "cityStateZip": "Houston, TX 77042", "companyName": "Premier Directional Drilling"},
  { "countryCode": "USA", "label": "Baker-Wyoming", "type": "Client Staging Area", "country": "United States", "address": "350 Cole Creek Road", "phone": "(307) 797-2438", "contact": "Joey Sanders", "cityStateZip": "Evansville, WY 82636", "companyName": "Baker Hughes" },
  { "countryCode": "USA", "label": "Baker-Midland", "type": "Client Staging Area", "country": "United States", "address": "2105 Market Street", "phone": "(701) 441-1552", "contact": "Mack Montgomery, Jr.", "cityStateZip": "Midland, TX 79703", "companyName": "Baker Hughes"},
  { "countryCode": "CAN", "label": "Cathedral-Calgary","type": "Client Staging Area", "country": "Canada", "address": "6030 3rd Street SE", "phone": "(403) 819-9909", "contact": "Mike McLaine", "cityStateZip": "Calgary AB, T2H 1K2", "companyName": "Cathedral" },
  { "countryCode": "CAN", "label": "Cathedral-Nisku", "type": "Client Staging Area", "country": "Canada", "address": "1105 16 Ave", "phone": "(403) 819-9909", "contact": "Mike McLaine", "cityStateZip": "Nisku, AB T9E 0A8", "companyName": "Cathedral" },
  { "countryCode": "CAN", "label": "Evolution-Calgary", "type": "Client Staging Area", "country": "Canada", "address": "Bay 2, 1460 28th Street NE", "phone": "(587) 390-6761", "contact": "Kyle Makowski", "cityStateZip": "Calgary, AB T2A 7W6", "companyName": "Evolution Energy Technologies"},
  { "countryCode": "CAN", "label": "Drillers-Edmonton", "type": "Client Staging Area", "country": "Canada", "address": "5312 68 Ave", "phone": "(780) 288-9080", "contact": "Steph Schipper", "cityStateZip": "Edmonton, AB T6B 3M4", "companyName": "Drillers Directional"},
  // { "countryCode": "CAN", "label": "Logic-Calgary", "type": "Client Staging Area", "country": "Canada", "address": "", "phone": "", "contact": "", "cityStateZip": "", },
  { "countryCode": "CAN", "label": "Precision-Calgary", "type": "Client Staging Area", "country": "Canada", "address": "#106, 5726 Burleigh Crescent SE", "phone": "(403) 724-4100", "contact": "Sean Waeyen", "cityStateZip": "Calgary, AB T2H 1Z8", "companyName": "Precision Directional Services Ltd." },
  { "countryCode": "CAN", "label": "Weatherford-Nisku", "type": "Client Staging Area", "country": "Canada", "address": "", "phone": "(780) 979-4500", "contact": "Michael Dawe", "cityStateZip": "Nisku, AB T9E 0C2", "companyName": "Weatherford" },
  { "countryCode": "CAN", "label": "Baker-Leduc", "type": "Client Staging Area", "country": "Canada", "address": "3905 - 71st Ave", "phone": "(780) 612-3206", "contact": "Teo Maranan", "cityStateZip": "Leduc, AB T9E 0R8", "companyName": "Baker Hughes" },
  { "countryCode": "USA", "label": "Amega-Houston", "type": "3rd Party Repair", "country": "United States", "address": "18601 Intercontinental Crossing Dr.", "phone": "N/A", "contact": "N/A", "cityStateZip": "Houston, TX 77073", "companyName": "Amega West Services LLC" },
  { "countryCode": "USA", "label": "Accutest-Houston", "type": "3rd Party Repair", "country": "United States", "address": "7821 Pinemont Dr.", "phone": "N/A", "contact": "N/A", "cityStateZip": "Houston, TX 77040", "companyName": "Accutest Labs LLP" },
  { "countryCode": "USA", "label": "A9MFG-Houston", "type": "3rd Party Repair", "country": "United States", "address": "14401 Interdrive W", "phone": "N/A", "contact": "N/A", "cityStateZip": "Houston, TX 77032", "companyName": "A9 Manufacturing Inc."},
  { "countryCode": "USA", "label": "EOS-Houston", "type": "3rd Party Repair", "country": "United States", "address": "3520 Aldine Bender Rd", "phone": "(282) 442-6600", "contact": "Elvin", "cityStateZip": "Houston, TX 77032", "companyName": "Expedite Oilfield Services" },
 // { "countryCode": "USA", "label": "Evolution-Conroe", "type": "3rd Party Repair", "country": "United States", "address": "", "phone": "", "contact": "", "cityStateZip": "", },
  { "countryCode": "CAN", "label": "Hydrotestors-RedDeer", "type": "3rd Party Repair", "country": "Canada", "address": "Bay 3, 7889 49th Avenue", "phone": "(403) 896-1688", "contact": "Regan Painchaud", "cityStateZip": "Red Deer, AB T4P 2B4", "companyName": "Hydrotestors"},
  { "countryCode": "CAN", "label": "Ensign-Nisku", "type": "3rd Party Repair", "country": "Canada", "address": "2101 - 4th Street", "phone": "(780) 668-0831", "contact": "Collin Reuther", "cityStateZip": "Nisku, AB T9E 7X3", "companyName": "Ensign Drilling Services" },
  { "countryCode": "CAN", "label": "RAM-Nisku", "type": "3rd Party Repair", "country": "Canada", "address": "503 15 Ave", "phone": "(780) 980-3900", "contact": "Craig Brown", "cityStateZip": "Nisku, AB T9E 7M6", "companyName": "RAM Oil Tools" },
  { "countryCode": "CAN", "label": "Logic-Calgary", "type": "3rd Party Repair", "country": "Canada", "address": "Bay 6 & 7, 6304 Burbank Road", "phone": "(403) 969-8815", "contact": "Joel O'Hanley", "cityStateZip": "Calgary, AB T2H 2C2", "companyName": "Logic NDT Solutions" },
  // { "countryCode": "", "label": "Rig", "type": "Rig", "country": "", "address": "", "phone": "", "contact": "", "cityStateZip": "", "companyName": "" },
  // { "countryCode": "", "label": "Repair Facility", "type": "Repair Facility", "country": "", "address": "", "phone": "", "contact": "", "cityStateZip": "", "companyName": "" },
  // { "countryCode": "", "label": "Client Facility", "type": "Client Facility", "country": "", "address": "", "phone": "", "contact": "", "cityStateZip": "", "companyName": "" },
  // //{ "countryCode": "CAN", "label": "Evolution-Calgary", "type": "3rd Party Repair", "country": "Canada", "address": "", "phone": "", "contact": "", "cityStateZip": "", }, 
];

 
  
// const useCountryStyles = makeStyles({
//   textBoxItem: {
//     marginBottom: '5px', 
//   },
//   option: {
//     fontSize: 15,
//     '& > span': {
//       marginRight: 10,
//       fontSize: 18,
//     },
//   },
// });

// function CustomSelect({ options, title, freeSolo, onChange, disabled, value }) {
//     const classes = useCountryStyles();
  
//     return (
//       <>
//         {disabled && <div style={{display:'flex', width: '100%', }}><Typography variant="caption">{value}</Typography></div>}
//         {!disabled && <Autocomplete
//           id="country-select-demo"
//           disabled={disabled}
//           onChange={onChange}
//           style={{ width: '100%' }}
//           options={options}
//           classes={{
//             option: classes.option,
//           }}
//           autoHighlight
//           freeSolo={freeSolo}
//           getOptionLabel={(option) => option.label}
//           renderOption={(option) => (
//             <Label> 
//               {`${option.label}`}
//             </Label>
//           )}
//           renderInput={(params) => (
//             <TextField 
//               {...params}
//               disabled={disabled}
//               size="small"
//               label={title}
//               variant="outlined"
//               inputProps={{
//                 ...params.inputProps,
//                 autoComplete: 'new-password', // disable autocomplete and autofill
//               }}
//             />
//           )}
//         />}
//       </>
//     );
// } 

            // {/* <Tabs onSelect={(selected, selectedIndex) => {if (editMode!=='edit') {return;} else { setManifestInfo(s => {s['Shipment Type Index'] = selectedIndex; s['Shipment Type'] = selected.label; return {...s};})}}} selected={manifestInfo['Shipment Type Index']} tabs={[
            //       { "label": 'Client Facility', content: <AddressTable disabled={editMode!=='edit'} manifestInfo={manifestInfo} handlePropertyChange={handlePropertyChange} handleCompanyFromChange={handleCompanyFromChange} handleCompanyToChange={handleCompanyToChange} /> },
            //       { "label": 'Repair Facility', content: <AddressTable disabled={editMode!=='edit'} manifestInfo={manifestInfo} handlePropertyChange={handlePropertyChange} handleCompanyFromChange={handleCompanyFromChange} handleCompanyToChange={handleCompanyToChange} /> },
            //       { "label": 'Rig', content: <RigTable disabled={editMode!=='edit'} manifestInfo={manifestInfo} handlePropertyChange={handlePropertyChange} handleCompanyFromChange={handleCompanyFromChange} handleCompanyToChange={handleCompanyToChange} /> },
            //       { "label": 'Intercompany', content: <AddressTable disabled={editMode!=='edit'} manifestInfo={manifestInfo} handlePropertyChange={handlePropertyChange} handleCompanyFromChange={handleCompanyFromChange} handleCompanyToChange={handleCompanyToChange} /> },
            //     ]}/> */}
const LOREM_IPSUM = (
  'lorem ipsum dolor sit amet consectetur adipiscing elit sed do eiusmod tempor incididunt ut ' +
  'labore et dolore magna aliqua ut enim ad minim veniam quis nostrud exercitation ullamco laboris nisi ut ' +
  'aliquip ex ea commodo consequat duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore ' +
  'eu fugiat nulla pariatur excepteur sint occaecat cupidatat non proident sunt in culpa qui officia deserunt '
).split(' ');
let loremIndex = 0;
function _lorem(wordCount) {
    const startIndex = loremIndex + wordCount > LOREM_IPSUM.length ? 0 : loremIndex;
    loremIndex = startIndex + wordCount;
    return LOREM_IPSUM.slice(startIndex, loremIndex).join(' ');
} 
function getRandomInt(min, max) {
    var val = Math.random();
    val *= max;
    val /= (max - min);
    val += min;
    return Math.floor(val);
}
function coinFlip(weight) {
    if (!weight) weight = 0.5;
    if (Math.random() < weight) {
        return true;
    }
    return false;
}
function createSampleTasks(count, docLink) {
    const globalTasks = [
        // { 
        //     key: "", 
        //     checked: false, 
        //     text: "", 
        //     assignedTo: undefined, 
        //     createdBy: undefined, 
        //     completedDate: undefined
        // }
    ];
    for (let i = count; i > 0; i--) {
        globalTasks.push({
            key: `TASK-${i}`,
            link: docLink,
            checked: false,
            text: _lorem(getRandomInt(10, 20)),
            "label": {
              text: (coinFlip(0.25)) ? 'Approval' : undefined,
              appearance: '#FFAB00',
              tooltip: "This task requires your approval",
            },
            assignedTo: { 
                displayName: 'Cody Robson',
                imageUrl: 'https://www.suffolkgazette.com/wp-content/uploads/2017/02/cookie-monster-diabetes.jpg'
            }
        });
    }
    return globalTasks;
}