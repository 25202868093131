import React, { useState, useEffect } from 'react'; 
import { Link } from 'react-router-dom';
import {
  DetailsList,
  DetailsListLayoutMode, 
  SelectionMode, 
  TooltipHost,  
  mergeStyleSets, 
  Text,
  DropdownMenuItemType,
  Dropdown,
  Label,
} from '@fluentui/react';  
import { Typography, Divider } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import moment from 'moment';
import { AppConfig } from '../../utils/config.utils'; 
import { IconButton } from '@fluentui/react/lib/Button';
 
const MSTable = ({label, inputItems, filter, setDisplayCount, setInputItems, hideDisplayOptions}) => { 
    const [dl, setDL] = useState(null);
    const [items, setItems] = useState(_generateDocuments(inputItems));
    const [allItems, seAllItems] = useState(_generateDocuments(inputItems));

    useEffect(() => {
      if (filter || filter.length > 0) {
        return;
      }
      setItems(_generateDocuments(inputItems))
      seAllItems(_generateDocuments(inputItems))
    }, [inputItems]); 
    
    useEffect(() => {
      if (dl !== null) { 
        window.location.href = dl;
        setDL(null);
      }
    }, [dl]);

    function _onChangeText(ev, text) { 
      if (text && text.length > 0) {
        const test =  allItems.filter(i => i.value.toLowerCase().indexOf(text.toLowerCase()) >= 0); 
        setItems([...test]);
      } else { 
        setItems([...allItems]);
      } 
    }

    useEffect(() => { 
      _onChangeText(null, filter);
  }, [filter]);

    const columns = [
        {
          key: 'column1',
          name: 'Type',
          className: msclassNames.fileIconCell,
          iconClassName: msclassNames.fileIconHeaderIcon,
          ariaLabel: 'Column operations for File type, Press to sort on File type',
          iconName: 'Page',
          isIconOnly: true,
          fieldName: 'name',
          minWidth: 16,
          maxWidth: 16,
          onColumnClick: (ev, c) => _onColumnClick(ev, c, columns, items),
          onRender: (item) => (
            <TooltipHost content={`${item.fileType}`}>
              <img src={item.iconName} className={msclassNames.fileIconImg} alt={`${item.fileType}`} />
            </TooltipHost>
          ),
        },
        {
          key: 'column2',
          name: 'Key',
          fieldName: 'eventKey',
          minWidth: 50,
          maxWidth: 70,
          isRowHeader: true,
          isResizable: true,
          isSorted: true,
          isSortedDescending: false,
          sortAscendingAriaLabel: 'Sorted A to Z',
          sortDescendingAriaLabel: 'Sorted Z to A',
          onColumnClick: (ev, c) => _onColumnClick(ev, c, columns, items),
          data: 'string',
          // isPadded: true,
        },
        {
          key: 'column3',
          name: 'Summary',
          fieldName: 'summary',
          minWidth: 230,
          maxWidth: 250,
          isRowHeader: true,
          isResizable: true,
          isSorted: true,
          isSortedDescending: false,
          sortAscendingAriaLabel: 'Sorted A to Z',
          sortDescendingAriaLabel: 'Sorted Z to A',
          onColumnClick: (ev, c) => _onColumnClick(ev, c, columns, items),
          data: 'string', 
          onRender: (item) => {
              return (
                <TooltipHost content={`Contains ${item.objectCount} items`}>
                    <Link to={`/browse/${item.eventKey}`} style={{ textDecoration: 'none' }} key={item.eventKey}>
                        {item.summary}
                    </Link>
                </TooltipHost>
              );
          }
        },
        {
          key: 'column-attachments',
          name: '',
          fieldName: 'attachmentCount',
          minWidth: 25,
          maxWidth: 35,
          isResizable: true,
          onColumnClick: (ev, c) => _onColumnClick(ev, c, columns, items),
          data: 'string',
          onRender: (item) => {
            if (item.attachmentCount && item.attachmentCount > 0) {
              return ( 
                <TooltipHost content={`${item.attachmentCount} attachment${(item.attachmentCount > 1) ? 's' : ''}`}> 
                  <IconButton iconProps={{ iconName: 'Attach' }} title="Files" ariaLabel="Files" />
                </TooltipHost>  
              )
            }
            
          }, 
        },
        {
          key: 'column5',
          name: 'Created By',
          fieldName: 'createdBy',
          minWidth: 70,
          maxWidth: 90,
          isResizable: true,
          isCollapsible: true,
          data: 'string',
          onColumnClick: (ev, c) => _onColumnClick(ev, c, columns, items),
          onRender: (item) => {
            return <span>{item.createdBy}</span>;
          }, 
        },
        {
          key: 'column4',
          name: 'Created',
          fieldName: 'dateCreatedValue',
          minWidth: 70,
          maxWidth: 90,
          isResizable: true,
          onColumnClick: (ev, c) => _onColumnClick(ev, c, columns, items),
          data: 'number',
          onRender: (item) => {
            return <span>{item.dateCreated}</span>;
          }, 
        },
        // {
        //   key: 'column4',
        //   name: 'Modified',
        //   fieldName: 'dateModifiedValue',
        //   minWidth: 70,
        //   maxWidth: 90,
        //   isResizable: true,
        //   onColumnClick: (ev, c) => _onColumnClick(ev, c, columns, items),
        //   data: 'number',
        //   onRender: (item) => {
        //     return <span>{item.dateModified}</span>;
        //   },
        //   // isPadded: true,
        // },
        {
          key: 'column6',
          name: '',
          fieldName: 'fileSizeRaw',
          minWidth: 70,
          maxWidth: 90,
          isResizable: true,
          isCollapsible: true,
          data: 'number',
          onColumnClick: (ev, c) => _onColumnClick(ev, c, columns, items),
          onRender: (item) => { 
            return (
                <TooltipHost content={`Download manifest`}>
                    <IconButton size='small' onClick={(e) => {e.preventDefault(); setDL(`${AppConfig.PUBLIC_API_URL}/api/latest/ship/template?key=${item.eventKey}&templateId=Manifest.docx&code=${AppConfig.PUBLIC_API_CODE}`)}} edge="end" aria-label="download">
                        <GetAppIcon/>
                    </IconButton>
                </TooltipHost>
            ); 
          },
        },
    ];

    function _onItemInvoked(item) {
        setDL(`/browse/${item.eventKey}`); 
    }


    const dropdownStyles = {
      dropdown: { width: 80 },
    };
    
    const options  = [
      // { key: 'fruitsHeader', text: 'Count', itemType: DropdownMenuItemType.Header },
      { key: 10, text: '10' },
      { key: 25, text: '25' },
      { key: 50, text: '50' },
      { key: 100, text: '100' }, 
      { key: 'All', text: 'All' }, 
      // { key: 'divider_1', text: '-', itemType: DropdownMenuItemType.Divider },
      // { key: 'vegetablesHeader', text: 'Recent', itemType: DropdownMenuItemType.Header },
      // { key: 'broccoli', text: 'This month' },
      // { key: 'carrot', text: 'Last month' },
      // { key: 'lettuce', text: 'This year' },
    ];

    const onDropdownChange = (event, item) => {
      if (setDisplayCount) setDisplayCount(item.key);
    }

    return (
        <div style={{display: 'flex', justifyContent:'center', border: 'solid 1px #E0E0E0', padding: '16px 32px', backgroundColor: 'white'}}>
            <div style={{display: 'flex', flexDirection: 'column'}}>
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                  <Typography style={{display: 'flex', fontWeight:550, fontSize:'18px', justifySelf: 'flex-start'}} variant="overline">{label}</Typography>
                  {!hideDisplayOptions && <div style={{display: 'flex'}}>
                    <Label style={{marginRight: '16px'}}>Display</Label>
                    <Dropdown
                      onChange={onDropdownChange}
                      placeholder="25" 
                      options={options}
                      styles={dropdownStyles}
                    />
                  </div>}
                </div>
                <Divider style={{margin: '0px 0px'}}/>
                <DetailsList 
                    items={items}
                    compact={true}
                    columns={columns}
                    selectionMode={SelectionMode.none}
                    getKey={_getKey}
                    setKey="none"
                    layoutMode={DetailsListLayoutMode.fixedColumns}
                    isHeaderVisible={true} 
                    style={{backgroundColor: 'transparent'}} 
                /> 
            </div>
        </div>
    ); 
}
 
const msclassNames = mergeStyleSets({
    fileIconHeaderIcon: {
      padding: 0,
      fontSize: '16px',
    },
    fileIconCell: {
      textAlign: 'center',
      selectors: {
        '&:before': {
          content: '.',
          display: 'inline-block',
          verticalAlign: 'middle',
          height: '100%',
          width: '0px',
          visibility: 'hidden',
        },
      },
    },
    fileIconImg: {
      verticalAlign: 'middle',
      maxHeight: '16px',
      maxWidth: '16px',
    },
    controlWrapper: {
      display: 'flex',
      flexWrap: 'wrap', 
    },
    exampleToggle: {
      display: 'inline-block',
      marginBottom: '10px',
      marginRight: '30px',
    },
    selectionDetails: {
      marginBottom: '20px',
    },
});
 
const LOREM_IPSUM = (
    'lorem ipsum dolor sit amet consectetur adipiscing elit sed do eiusmod tempor incididunt ut ' +
    'labore et dolore magna aliqua ut enim ad minim veniam quis nostrud exercitation ullamco laboris nisi ut ' +
    'aliquip ex ea commodo consequat duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore ' +
    'eu fugiat nulla pariatur excepteur sint occaecat cupidatat non proident sunt in culpa qui officia deserunt '
).split(' ');
let loremIndex = 0;
function _lorem(wordCount) {
    const startIndex = loremIndex + wordCount > LOREM_IPSUM.length ? 0 : loremIndex;
    loremIndex = startIndex + wordCount;
    return LOREM_IPSUM.slice(startIndex, loremIndex).join(' ');
} 

const FILE_ICONS = [
    // { name: 'accdb' },
    // { name: 'audio' },
    // { name: 'code' },
    // { name: 'csv' },
    // { name: 'docx' },
    // { name: 'dotx' },
    // { name: 'mpp' },
    // { name: 'mpt' },
    // { name: 'model' },
    // { name: 'one' },
    // { name: 'onetoc' },
    // { name: 'potx' },
    // { name: 'ppsx' },
    // { name: 'pdf' },
    // { name: 'photo' },
    // { name: 'pptx' },
    { name: 'presentation' },
    // { name: 'potx' },
    // { name: 'pub' },
    // { name: 'rtf' },
    // { name: 'spreadsheet' },
    // { name: 'txt' },
    // { name: 'vector' },
    // { name: 'vsdx' },
    // { name: 'vssx' },
    // { name: 'vstx' },
    // { name: 'xlsx' },
    // { name: 'xltx' },
    // { name: 'xsn' },
];
  
function _randomFileIcon() {
    const docType = FILE_ICONS[Math.floor(Math.random() * FILE_ICONS.length)].name;
    return {
        docType: docType,
        url: `https://static2.sharepointonline.com/files/fabric/assets/item-types/16/${docType}.svg`,
    };
} 
   
function _getKey(item, index) {
    return item.key;
}
 
function _onColumnClick (ev, column, columns, items) {
    // const { columns, items } = this.state;
    const newColumns = columns.slice();
    const currColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
    newColumns.forEach((newCol) => {
    if (newCol === currColumn) {
        currColumn.isSortedDescending = !currColumn.isSortedDescending;
        currColumn.isSorted = true;
        // this.setState({
        //   announcedMessage: `${currColumn.name} is sorted ${
        //     currColumn.isSortedDescending ? 'descending' : 'ascending'
        //   }`,
        // });
    } else {
        newCol.isSorted = false;
        newCol.isSortedDescending = true;
    }
    });
    const newItems = _copyAndSort(items, currColumn.fieldName, currColumn.isSortedDescending);
    // this.setState({
    //   columns: newColumns,
    //   items: newItems,
    // });
};
  
function _copyAndSort(items, columnKey, isSortedDescending) {
    const key = columnKey;
    return items.slice(0).sort((a, b) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
}
    
function _generateDocuments(source) {
    const items = [];
    if (source) {
        for (let i = 0; i < source.length; i++) {
            let item = source[i]; 
            const randomFileType = _randomFileIcon(); 
            const summary = getSummary(item);
            let userName = _lorem(2); 
            userName = userName
                .split(' ')
                .map((name) => name.charAt(0).toUpperCase() + name.slice(1))
                .join(' ');
            items.push({ 
                key: i.toString(),
                objectCount: (item.eventData.Manifest) ? item.eventData.Manifest.length : 0, 
                eventKey: item.id,
                summary: summary,
                value: summary,
                iconName: randomFileType.url,
                fileType: item.eventType,
                attachmentCount: (item.attachments) ? item.attachments.length : 0,
                createdBy: (item.createdBy) ? item.createdBy.name : 'Unknown',
                dateCreated: moment(item.eventTime).format('MMM DD, YYYY'),
                dateModified: (item.eventData.modifiedDate) ? moment(item.eventData.modifiedDate).format('MMM DD, YYYY') : undefined,
                dateCreatedValue: (new Date(item.eventTime)).valueOf(), 
                dateModifiedValue: (item.eventData.modifiedDate) ? (new Date(item.eventData.modifiedDate)).valueOf() : undefined, 
            });
        }
        const key = 'dateCreatedValue';
        const isSortedDescending = true;
        return items.sort((a, b) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));;
    }
    return []; 
}

function getSummary(item) {
    if (!item || !item.eventData || !item.eventData.From || !item.eventData.To) {
      // console.log(item);
      return `Shipment (not specified)`;
    }  

    var toLoc = item.eventData.To.Location || item.eventData.To.Company;
    var fromLoc = item.eventData.From.Location || item.eventData.From.Company;

    if (fromLoc && !toLoc) return `Shipment from ${fromLoc}`
    if (!fromLoc && toLoc) return `Shipment to ${toLoc}`
    if (fromLoc && toLoc) return `${fromLoc} \u2794 ${toLoc}`
    return `Shipment (not specified)`;
}

export default MSTable;

