import React, { useEffect } from 'react'; 
import { Switch, Route } from 'react-router-dom';
 
// Styles
import './App.css';  

// Pages
import Dashboard from './pages/Dashboard'; 
import AllManifests from './pages/AllManifests';
import AllJobs from './pages/AllJobs'; 
import DocumentBrowser from './pages/DocumentBrowser'; 
import JiraIssueBrowser from './pages/JiraIssueBrowser'; 
import ServiceOrdersApp from './pages/ServiceOrdersApp';
import AppBrowser from './pages/AppBrowser';
import ProductsPage from './pages/ProductsPage';
import ProductCardPage from './pages/ProductCardPage';

// Components
import { initializeIcons } from '@uifabric/icons';
import { initializeFileTypeIcons } from '@uifabric/file-type-icons'; 
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

// Contexts
import { AppContext, AppContextState } from './contexts/AppContext';

// MS Graph
import { getUserDetails, userProfilePhoto } from './utils/graph';
import { MsalAuthenticationTemplate, MsalProvider, useMsal, useAccount } from "@azure/msal-react";
import { EventType, InteractionType } from "@azure/msal-browser"; 

initializeFileTypeIcons(); 
initializeIcons();

const msalConfig = {  
  scopes: [
    'openid', 
    'profile', 
    'User.Read', 
    'User.ReadBasic.All', 
    'People.Read', 
    'Presence.Read.All', 
    'Presence.Read'
  ] 
}


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: "row",
    minHeight: "100vh",
    backgroundColor: '#F4F5F7',
  },
  content: {
    flexGrow: 1,
    marginBottom: "20px", 
    backgroundColor: '#F4F5F7',
  },
}))

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#71AABB',
      contrastText: "#FFFFFF"
    },
    secondary: {
      main: '#48656b',
    },
  },
});

function App({ pca }) {  
  const classes = useStyles();    
  const applicationState = React.useContext(AppContextState);
  const applicationStateDispatcher = React.useContext(AppContext); 

  useEffect(() => {
    if (applicationState.user.id) {
      return;
    }
    // console.log('Logging in...');

    async function getAccessToken(scopes) {
      try {
        const accounts = pca.getAllAccounts(); 
        if (accounts.length <= 0) throw new Error('login_required');
        // Get the access token silently
        // If the cache contains a non-expired token, this function
        // will just return the cached token. Otherwise, it will
        // make a request to the Azure OAuth endpoint to get a token
        var silentResult = await pca.acquireTokenSilent({
            scopes: scopes,
            account: accounts[0]
          }); 
        return silentResult.accessToken;
      } catch (err) {
        // If a silent request fails, it may be because the user needs
        // to login or grant consent to one or more of the requested scopes
        if (this.isInteractionRequired(err)) {
          var interactiveResult = await pca.acquireTokenPopup({
              scopes: scopes
            }); 
          return interactiveResult.accessToken;
        } else {
          throw err;
        }
      }
    }  

    const accounts = pca.getAllAccounts(); 
    if (accounts && accounts.length > 0) {
      getAccessToken(msalConfig.scopes) 
      .then((accessToken) => {
        if (accessToken) { 
          getUserDetails(accessToken)
          .then((user) => {  
            var result = {...user, accessToken};
            delete result['@odata.context']
            userProfilePhoto(accessToken, result.id) 
            .then((photo) => {
              result.profilePhoto = photo; 
              applicationStateDispatcher({type: 'user/data', payload: result}); 
            })
            .catch(console.log);
          })
          .catch(console.log);
        } else {
          console.log('Failed to get access token');
        }
      })
      .catch(console.log);
    } 
  }, [applicationState]); 

  useEffect(() => { 
    applicationStateDispatcher({type: 'application/pca', payload: pca});
  }, [pca]); 

  useEffect(() => {
    if (applicationState.user.id) return;
    // console.log('Watching login...')
    setTimeout(() => {
      if (!applicationState.user.id) {
        // console.log('Retrigger login...')
        applicationStateDispatcher({type: 'fields/update', field: 'loginAttempts', payload: applicationState.fields.loginAttempts + 1})
      } 
      // else {
        // console.log('Timed out')
      // }
    }, 1000);
  }, [applicationState]);

  return ( 
    <ThemeProvider theme={theme}> 
      <MsalProvider instance={pca}>  
        <MsalAuthenticationTemplate
          interactionType={InteractionType.Redirect}
          authenticationRequest={msalConfig}
          errorComponent={ErrorComponent}
          loadingComponent={Loading}>
          <div className="App">
            <div className={classes.root}> 
              <div className={classes.content}> 
              {/* {applicationState.user.id &&   */}
                <Switch>
                  <Route path="/" exact component={Dashboard} /> 
                  <Route path="/apps/:app" component={AppBrowser} />
                  <Route path="/browse/SHIP" component={AllManifests} />
                  <Route path="/browse/shipments" component={AllManifests} />
                  <Route path="/browse/logistics" component={AllManifests} />
                  <Route path="/browse/jobs" component={AllJobs} />
                  <Route path="/browse/products/:id" component={ProductCardPage} />
                  <Route path="/browse/products" component={ProductsPage} />
                  <Route path="/browse/service-orders/:amo" component={ServiceOrdersApp} /> 
                  <Route path="/browse/issues/:key" component={JiraIssueBrowser} />
                  <Route path='/browse/:key' component={DocumentBrowser} /> 
                </Switch>
              {/* } */}
              {/* {!applicationState.user.id && 
              <div>
                <button onClick={() =>  applicationStateDispatcher({type: 'user/data', payload: {}})}>Log in</button>
              </div>
              } */}
              </div>
            </div>
          </div>  
        </MsalAuthenticationTemplate>
      </MsalProvider>
    </ThemeProvider> 
  );
}

export default App; 

function ErrorComponent({ error }) {
  return <Typography variant="h6">An Error Occurred: {error.errorCode}</Typography>;
}

function Loading() {
  return <Typography variant="h6">Authentication in progress...</Typography>
}


// {/* <Route exact path="/login" render={(props) => <LoginPage {...props} location={location} history={history} redirect={location.pathname}/>}/> */}
// {/* <Route path="/:id_token" component={AuthorizeComponent}/> */}
// {/* <Route exact path="/test" component={TestPage} /> */}
// {/* <Route path="/search/:query" component={SearchDetails} />  */}
// {/* <Route path="/shipments/track" component={MapPage} />  */}
// {/* <Route path="/assets/assembly-manager" component={CreateAssembly} /> */}
// {/* <Route path="/assets/view-all" component={AllAssets} /> */}
// {/* <Route path="/assets/:serial" component={AssetDetails} /> */}
// {/* <Route path="/account" component={AccountDetails} /> */}
// {/* <Route path="/settings" component={null} /> */}