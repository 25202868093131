import React, { useEffect, useState, useContext } from 'react'; 
import { colors } from '@atlaskit/theme'; 
import { motion } from 'framer-motion';
import {
    Alert,
    AlertTitle
} from '@material-ui/lab'; 
import {
    Pivot, 
    Label, 
    PivotItem,
} from '@fluentui/react';
import {
    ChecklistCollection,
} from '../components/Checklist'; 
 
import { AppContext, AppContextState, useDocumentAPI } from '../contexts/AppContext';
import { Divider } from '@material-ui/core';

const labelStyles = {
    root: { marginTop: 10 },
};

// const A = () => {
//     const applicationState = useContext(AppContextState);
//     return (
//         <div>
//             value: {applicationState.fields.value}
//         </div>
//     );
// }

// const B = () => {
//     const applicationStateDispatcher = useContext(AppContext);
//     return (
//         <div>
//             <input onChange={e => applicationStateDispatcher({ type: 'fields/value', field: 'value', payload: e.target.value })}/>
//         </div>
//     )
// }

const DashboardApp = ({hideAlert}) => { 
    const applicationState = useContext(AppContextState); 
    // const applicationStateDispatcher = useContext(AppContext); 
    const [state, setAction] = useDocumentAPI();
    const [loaded, setLoaded] = useState(false);

    function handleSetData(item) { 
        setAction({
            type: 'workspace/update',
            payload: item,
        }); 
    }

    function handleSetApprovals(item) {
        setAction({
            type: 'approvals/update',
            payload: item,
        }); 
    }

    function handleSetWatching(item) {
        setAction({
            type: 'watch/update',
            payload: item,
        }); 
    }

    useEffect(() => {
        if (loaded) return;

        if (applicationState && applicationState.user && applicationState.user.id) { 
            if (applicationState.user) {
                setAction({
                    type: 'workspace/load', 
                    payload: {
                        eventType: 'Task',
                        workspaceUserId: applicationState.user.id,
                    },
                }); 
                setLoaded(true);
            } 
        } else {
            console.log('Unable to load workspace', applicationState);
        }
    }, [applicationState.user]);
 
    return (
        <div style={{textAlign: 'left', backgroundColor: colors.N20}}> 
            {!hideAlert && <FancyAlert 
                style={{marginTop: '10px', marginBottom: '10px' , backgroundColor: 'white'}} 
                variant="outlined" 
                severity="success">
                <AlertTitle>Alert</AlertTitle>
                You can now assign tasks by clicking on the task description and changing the <b>Assigned To</b> field. Tasks you assign to others will automatically appear in your <b>Watching</b> tab.
            </FancyAlert> }
            <Pivot aria-label="Count and Icon Pivot Example">
                <PivotItem headerText="My Tasks" itemCount={(applicationState.userTaskList && applicationState.userTaskList.filter) ? applicationState.userTaskList.filter(x => !x.eventData.checked).length : undefined} itemIcon="TaskManager">
                    <>
                    <ChecklistCollection 
                        tasks={(applicationState.userTaskList && applicationState.userTaskList.filter) ? applicationState.userTaskList.filter(x => !x.eventData.checked) : []} 
                        setTask={handleSetData}
                        onDeleteTask={(doc) => {
                            setAction({type: 'workspace/delete', payload: doc})
                        }}
                        onAddTask={(newTask) => { 
                            setAction({type: 'workspace/create', payload: newTask })
                        }}/> 
                    </>
                </PivotItem>
                <PivotItem headerText="Approval Requests" itemCount={(applicationState.approvalsList) ? applicationState.approvalsList.length : undefined} itemIcon="TeamFavorite"> 
                    <Label styles={labelStyles}>Awaiting your approval</Label>
                    <ChecklistCollection 
                        tasks={(applicationState.approvalsList && applicationState.approvalsList.filter) ? applicationState.approvalsList.filter(x => x.eventData.approvalRecords.filter(y => y.active===true && y.approverId===applicationState.user.id).length > 0) : []} 
                        setTask={handleSetApprovals} />
                    <Label styles={labelStyles}>Responded</Label>
                    <ChecklistCollection 
                        tasks={(applicationState.approvalsList && applicationState.approvalsList.filter) ? applicationState.approvalsList.filter(x => x.eventData.approvalRecords.filter(y => y.active===false && y.approverId===applicationState.user.id).length > 0) : []}
                        setTask={handleSetApprovals} />
                </PivotItem>
                <PivotItem headerText="Watching" itemIcon="RedEye" itemCount={(applicationState.watchList && applicationState.watchList.filter) ? applicationState.watchList.filter(x => !x.eventData.checked).length : undefined}>
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                    <Label styles={labelStyles}>Outstanding</Label>
                    <ChecklistCollection 
                        setTask={handleSetWatching}
                        tasks={(applicationState.watchList && applicationState.watchList.filter) ? applicationState.watchList.filter(x => !x.eventData.checked) : []}/>
                        <Divider style={{marginTop: '20px', width: '100%'}}/>
                    <Label styles={labelStyles}>Completed</Label>
                    <ChecklistCollection 
                        setTask={handleSetWatching}
                        tasks={(applicationState.watchList && applicationState.watchList.filter) ? applicationState.watchList.filter(x => x.eventData.checked) : []}/>
                    </div>
                </PivotItem> 
                <PivotItem headerText="Completed recently" itemCount={(applicationState.userTaskList && applicationState.userTaskList.filter) ? applicationState.userTaskList.filter(x => x.eventData.checked).length : undefined} itemIcon="Recent">
                    <ChecklistCollection 
                        tasks={(applicationState.userTaskList && applicationState.userTaskList.filter) ? applicationState.userTaskList.filter(x => x.eventData.checked) : []} 
                        setTask={handleSetData} />
                </PivotItem>
            </Pivot> 
        </div>
    );
}

export default DashboardApp;

function FancyAlert(props) {
    const [hover, setHover] = useState(false);
    return (
        <motion.div   
            onHoverStart={() => {setHover(true);}}
            onHoverEnd={() => {setHover(false);}}
            whileHover={{ 
                scale: 1.005, 
                boxShadow: "0px 2px 6px rgba(175,167,166,0.8)", 
            }} 
            >
            <Alert elevation={hover ? 0 : 0} {...props}/>
        </motion.div>
    )
} 

// const LOREM_IPSUM = (
//     'lorem ipsum dolor sit amet consectetur adipiscing elit sed do eiusmod tempor incididunt ut ' +
//     'labore et dolore magna aliqua ut enim ad minim veniam quis nostrud exercitation ullamco laboris nisi ut ' +
//     'aliquip ex ea commodo consequat duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore ' +
//     'eu fugiat nulla pariatur excepteur sint occaecat cupidatat non proident sunt in culpa qui officia deserunt '
// ).split(' ');
// let loremIndex = 0;
// function _lorem(wordCount) {
//     const startIndex = loremIndex + wordCount > LOREM_IPSUM.length ? 0 : loremIndex;
//     loremIndex = startIndex + wordCount;
//     return LOREM_IPSUM.slice(startIndex, loremIndex).join(' ');
// } 
// function getRandomInt(min, max) {
//     var val = Math.random();
//     val *= max;
//     val /= (max - min);
//     val += min;
//     return Math.floor(val);
// }
// function coinFlip(weight) {
//     if (!weight) weight = 0.5;
//     if (Math.random() < weight) {
//         return true;
//     }
//     return false;
// }
