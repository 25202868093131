import React from 'react';      
import { 
    Label,   
} from '@fluentui/react';  
import {CustomAppBar} from '../components/UserProfilePopper'; 
import DashboardApp from '../pages/DashboardApp';
  
 
const Dashboard = () => {  
    return ( 
        <CustomAppBar>    
            <div style={{padding: '16px 64px'}}>
                <Label style={{fontSize: '28px', textAlign: 'left'}}>Your Workspace</Label>
                <DashboardApp hideAlert />
            </div>  
        </CustomAppBar> 
    );
}

export default Dashboard; 