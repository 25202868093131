import React, { useState, createContext, useEffect, useReducer, useContext } from 'react'; 
import { AppConfig } from '../utils/config.utils'; 
import useLocalStorage from '../utils/auth/useLocalStorage.hook';

const axios = require('axios');

export const AppContext = createContext(() => {});
export const AppContextState = createContext({ state: null });

export default function AppProvider({children}) {   
    const [local, setLocal] = useLocalStorage('user', {}); 
    const [applicationState, applicationStateDispatcher] = useReducer(reducer, {
        user: {},
        pca: undefined,
        userTaskList: [], 
        watchList: [],
        approvalsList: [],
        event: {
            id: undefined, 
            taskList: [],
        },
        fields: {
            loginAttempts: 0,
            value: 'test'
        }, 
        addressBook: [
          { "type": "Address Book Entry", "countryCode": "USA", "label": "Altitude-Midland", "locationType": "Client Staging Area", "country": "United States", "address": "2308 SCR 1245", "phone": "(406) 690-6008", "contact": "Tim Cramer", "cityStateZip": "Midland, TX 79706", },
          { "type": "Address Book Entry", "countryCode": "USA", "label": "Nabors-Houston", "locationType": "Client Staging Area", "country": "United States", "address": "515 W. Greens Rd STE 1200", "phone": "(210) 887-8573", "contact": "Chris Alex", "cityStateZip": "Houston, TX 77067", },
          { "type": "Address Book Entry", "countryCode": "USA", "label": "Halliburton-Houston", "locationType": "Client Staging Area", "country": "United States", "address": "3950 Interwood S Pkwy", "phone": "N/A", "contact": "N/A", "cityStateZip": "Houston, TX 77302", },
          { "type": "Address Book Entry", "countryCode": "USA", "label": "Evolution-Conroe", "locationType": "Client Staging Area", "country": "United States", "address": "2418 N Frazier St. #103", "phone": "(877) 265-0345", "contact": "Joshua Sikorski", "cityStateZip": "Conroe, TX 77303", "companyName": "Evolution Energy Technologies"},
          { "type": "Address Book Entry", "countryCode": "USA", "label": "Premier-Fairmont", "locationType": "Client Staging Area", "country": "United States", "address": "732 Middletown Road", "phone": "(304) 282-8218", "contact": "Robert Hunley", "cityStateZip": "Fairmont, WV 26554", "companyName": "Premier Directional"},
          { "type": "Address Book Entry", "countryCode": "USA", "label": "Polaris-Spring", "locationType": "Client Staging Area", "country": "United States", "address": "21617 Rhodes Rd", "phone": "N/A", "contact": "N/A", "cityStateZip": "Spring TX, 77388", "companyName": "Polaris" },
          { "type": "Address Book Entry", "countryCode": "USA", "label": "Baker-MtPleasant", "locationType": "Client Staging Area", "country": "United States", "address": "370 Westec Dr", "phone": "N/A", "contact": "N/A", "cityStateZip": "Mt Pleasant, PA 15666", "companyName": "Baker Hughes" },
          { "type": "Address Book Entry", "countryCode": "USA", "label": "Phoenix-Houston", "locationType": "Client Staging Area", "country": "United States", "address": "12329 Cutten Rd.", "phone": "N/A", "contact": "N/A", "cityStateZip": "Houston, TX 77066", "companyName": "Phoenix Technology Services" },
          { "type": "Address Book Entry", "countryCode": "USA", "label": "Panther-OKC", "locationType": "Client Staging Area", "country": "United States", "address": "10600, 10626 W Reno Ave", "phone": "N/A", "contact": "N/A", "cityStateZip": "Oklahoma City, OK 73127", "companyName": "Panther Drilling Systems"},
          { "type": "Address Book Entry", "countryCode": "USA", "label": "Premier-Midland", "locationType": "Client Staging Area", "country": "United States", "address": "12910 TX-191", "phone": "N/A", "contact": "N/A", "cityStateZip": "Midland, TX 79707", "companyName": "Premier Directional Drilling"},
          { "type": "Address Book Entry", "countryCode": "USA", "label": "Halliburton-Bakersfield", "locationType": "Client Staging Area", "country": "United States", "address": "34772 7th Standard Rd", "phone": "N/A", "contact": "N/A", "cityStateZip": "Bakersfield, CA 93314", "companyName": "Halliburton" },
          { "type": "Address Book Entry", "countryCode": "USA", "label": "Scientific-Odessa", "locationType": "Client Staging Area", "country": "United States", "address": "325 S. Faudree Road", "phone": "(432) 306-0118", "contact": "Michael Williams", "cityStateZip": "Odessa, TX 79765", },
          { "type": "Address Book Entry", "countryCode": "USA", "label": "Scout-Conroe", "locationType": "Client Staging Area", "country": "United States", "address": "1125 Beach Airport Blvd", "phone": "N/A", "contact": "N/A", "cityStateZip": "Conroe, TX 77301", "companyName": "Scout Downhole"},
          { "type": "Address Book Entry", "countryCode": "USA", "label": "Baker-Houston", "locationType": "Client Staging Area", "country": "United States", "address": "17015 Aldine Westfield Rd", "phone": "N/A", "contact": "N/A", "cityStateZip": "Houston, TX 77073", "companyName": "Baker Hughes" },
          { "type": "Address Book Entry", "countryCode": "USA", "label": "Premier-Houston", "locationType": "Client Staging Area", "country": "United States", "address": "5611 Baird Court", "phone": "N/A", "contact": "N/A", "cityStateZip": "Houston, TX 77042", "companyName": "Premier Directional Drilling"},
          { "type": "Address Book Entry", "countryCode": "USA", "label": "Baker-Wyoming", "locationType": "Client Staging Area", "country": "United States", "address": "350 Cole Creek Road", "phone": "(307) 797-2438", "contact": "Joey Sanders", "cityStateZip": "Evansville, WY 82636", "companyName": "Baker Hughes" },
          { "type": "Address Book Entry", "countryCode": "USA", "label": "Baker-Midland", "locationType": "Client Staging Area", "country": "United States", "address": "2105 Market Street", "phone": "(701) 441-1552", "contact": "Mack Montgomery, Jr.", "cityStateZip": "Midland, TX 79703", "companyName": "Baker Hughes"},
          { "type": "Address Book Entry", "countryCode": "CAN", "label": "Cathedral-Calgary","locationType": "Client Staging Area", "country": "Canada", "address": "6030 3rd Street SE", "phone": "(403) 819-9909", "contact": "Mike McLaine", "cityStateZip": "Calgary AB, T2H 1K2", "companyName": "Cathedral" },
          { "type": "Address Book Entry", "countryCode": "CAN", "label": "Cathedral-Nisku", "locationType": "Client Staging Area", "country": "Canada", "address": "1105 16 Ave", "phone": "(403) 819-9909", "contact": "Mike McLaine", "cityStateZip": "Nisku, AB T9E 0A8", "companyName": "Cathedral" },
          { "type": "Address Book Entry", "countryCode": "CAN", "label": "Evolution-Calgary", "locationType": "Client Staging Area", "country": "Canada", "address": "Bay 2, 1460 28th Street NE", "phone": "(587) 390-6761", "contact": "Kyle Makowski", "cityStateZip": "Calgary, AB T2A 7W6", "companyName": "Evolution Energy Technologies"},
          { "type": "Address Book Entry", "countryCode": "CAN", "label": "Drillers-Edmonton", "locationType": "Client Staging Area", "country": "Canada", "address": "5312 68 Ave", "phone": "(780) 288-9080", "contact": "Steph Schipper", "cityStateZip": "Edmonton, AB T6B 3M4", "companyName": "Drillers Directional"},
          { "type": "Address Book Entry", "countryCode": "CAN", "label": "Precision-Calgary", "locationType": "Client Staging Area", "country": "Canada", "address": "#106, 5726 Burleigh Crescent SE", "phone": "(403) 724-4100", "contact": "Sean Waeyen", "cityStateZip": "Calgary, AB T2H 1Z8", "companyName": "Precision Directional Services Ltd." },
          { "type": "Address Book Entry", "countryCode": "CAN", "label": "Weatherford-Nisku", "locationType": "Client Staging Area", "country": "Canada", "address": "", "phone": "(780) 979-4500", "contact": "Michael Dawe", "cityStateZip": "Nisku, AB T9E 0C2", "companyName": "Weatherford" },
          { "type": "Address Book Entry", "countryCode": "CAN", "label": "Baker-Leduc", "locationType": "Client Staging Area", "country": "Canada", "address": "3905 - 71st Ave", "phone": "(780) 612-3206", "contact": "Teo Maranan", "cityStateZip": "Leduc, AB T9E 0R8", "companyName": "Baker Hughes" },
          { "type": "Address Book Entry", "countryCode": "USA", "label": "Amega-Houston", "locationType": "3rd Party Repair", "country": "United States", "address": "18601 Intercontinental Crossing Dr.", "phone": "N/A", "contact": "N/A", "cityStateZip": "Houston, TX 77073", "companyName": "Amega West Services LLC" },
          { "type": "Address Book Entry", "countryCode": "USA", "label": "Accutest-Houston", "locationType": "3rd Party Repair", "country": "United States", "address": "7821 Pinemont Dr.", "phone": "N/A", "contact": "N/A", "cityStateZip": "Houston, TX 77040", "companyName": "Accutest Labs LLP" },
          { "type": "Address Book Entry", "countryCode": "USA", "label": "A9MFG-Houston", "locationType": "3rd Party Repair", "country": "United States", "address": "14401 Interdrive W", "phone": "N/A", "contact": "N/A", "cityStateZip": "Houston, TX 77032", "companyName": "A9 Manufacturing Inc."},
          { "type": "Address Book Entry", "countryCode": "USA", "label": "EOS-Houston", "locationType": "3rd Party Repair", "country": "United States", "address": "3520 Aldine Bender Rd", "phone": "(282) 442-6600", "contact": "Elvin", "cityStateZip": "Houston, TX 77032", "companyName": "Expedite Oilfield Services" },
          { "type": "Address Book Entry", "countryCode": "CAN", "label": "Hydrotestors-RedDeer", "locationType": "3rd Party Repair", "country": "Canada", "address": "Bay 3, 7889 49th Avenue", "phone": "(403) 896-1688", "contact": "Regan Painchaud", "cityStateZip": "Red Deer, AB T4P 2B4", "companyName": "Hydrotestors"},
          { "type": "Address Book Entry", "countryCode": "CAN", "label": "Ensign-Nisku", "locationType": "3rd Party Repair", "country": "Canada", "address": "2101 - 4th Street", "phone": "(780) 668-0831", "contact": "Collin Reuther", "cityStateZip": "Nisku, AB T9E 7X3", "companyName": "Ensign Drilling Services" },
          { "type": "Address Book Entry", "countryCode": "CAN", "label": "RAM-Nisku", "locationType": "3rd Party Repair", "country": "Canada", "address": "503 15 Ave", "phone": "(780) 980-3900", "contact": "Craig Brown", "cityStateZip": "Nisku, AB T9E 7M6", "companyName": "RAM Oil Tools" },
          { "type": "Address Book Entry", "countryCode": "CAN", "label": "Logic-Calgary", "locationType": "3rd Party Repair", "country": "Canada", "address": "Bay 6 & 7, 6304 Burbank Road", "phone": "(403) 969-8815", "contact": "Joel O'Hanley", "cityStateZip": "Calgary, AB T2H 2C2", "companyName": "Logic NDT Solutions" },
        ]
    }); 

    // useEffect(() => {
    //     console.log(local);
    //     applicationStateDispatcher({type: 'user/data', payload: local});
    // }, [local])

    return (
        <AppContext.Provider value={applicationStateDispatcher}>
            <AppContextState.Provider value={applicationState}>
                {children}
            </AppContextState.Provider> 
        </AppContext.Provider>
    )
}

const reducer = (state, action) => { 
    if (!action || !action.type) {
        return state;
    }
    switch (action.type) { 
        case 'application/pca':
            return {...state, pca: action.payload};

        case 'user/data':
            return {...state, user: action.payload};

        case 'fields/update': 
            state.fields[action.field] = action.payload;
            return {...state};

        case 'workspace/tasks/new':
            if (!state.userTaskList) state.userTaskList = []; 
            state.userTaskList = [action.payload, ...state.userTaskList];
            return {...state}; 
        case 'workspace/tasks/load': 
            // console.log('WORKSPACE', action.payload)
            state.userTaskList = action.payload.assignments;//  .filter(x => x.eventData.assignedTo.userId === state.user.account.accountIdentifier);
            state.watchList = action.payload.watching;//.filter(x => x.eventData.watchers && x.eventData.watchers.includes(state.user.account.accountIdentifier));
            state.approvalsList = action.payload.approvals;//.filter(x => x.eventData.approvers // && x.eventData.approvers.includes(state.user.account.accountIdentifier));
            return {...state};
        case 'workspace/tasks/update': 
            var idx = state.userTaskList.findIndex(x => x.id === action.payload.id); 
            if (idx >= 0) {
                state.userTaskList[idx] = action.payload;
            }  
            return {...state}; 
        case 'workspace/tasks/delete': 
            state.userTaskList = state.userTaskList.filter(x => x.id !== action.payload.id);
            return {...state};

        case 'event/load': 
            state.event = {
                id: action.id, 
                taskList: [],
            };
            return {...state};
        case 'event/tasks/new':
            if (!state.event.taskList) state.event.taskList = []; 
            state.event.taskList = [action.payload, ...state.event.taskList];
            return {...state}; 
        case 'event/tasks/load':
            state.event.taskList = action.payload;
            return {...state};
        case 'event/tasks/update': 
            var idx = state.event.taskList.findIndex(x => x.id === action.payload.id); 
            if (idx >= 0) {
                state.event.taskList[idx] = action.payload;
            }  
            return {...state};
        case 'event/tasks/delete': 
            state.event.taskList = state.event.taskList.filter(x => x.id !== action.payload.id);
            return {...state};
            
            
        case 'watch/tasks/update':
            var idx = state.watchList.findIndex(x => x.id === action.payload.id); 
            if (idx >= 0) {
                state.watchList[idx] = action.payload;
            }  
            return {...state};

        case 'watch/tasks/update':
            var idx = state.approvalsList.findIndex(x => x.id === action.payload.id); 
            if (idx >= 0) {
                state.approvalsList[idx] = action.payload;
            }  
            return {...state};

        case 'nav': 
        console.log('Storing in nav - ' + action.payload)
            state.nav = action.payload;
            return {...state};
        default: 
            return state;
    }
} 

export const useDocumentAPI = () => {
    const applicationState = useContext(AppContextState);
    const applicationStateDispatcher = useContext(AppContext); 
    const [action, setAction] = useState(undefined); // {type: '', payload: {}}
    const [state, dispatch] = useReducer(dataFetchReducer, {
      isLoading: false,
      isError: false,
      data: undefined,
      error: undefined
    }); 

    const axiosInstance = axios.create({
        baseURL: `${AppConfig.PUBLIC_API_URL}/api/latest`, 
        headers: {'Content-Type': 'application/json'}
    });
        
    useEffect(() => {
        async function createDocument(doc) { 
            return new Promise((resolve, reject) => {
                axiosInstance.post(`/document?code=${AppConfig.PUBLIC_API_CODE}`, doc)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
            }); 
        }
        
        async function updateDocument(doc) {
            return new Promise((resolve, reject) => { 
                axiosInstance.put(`/document?code=${AppConfig.PUBLIC_API_CODE}`, doc)
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
            });
        } 
    
        async function getDocument(doc) {
            return new Promise((resolve, reject) => { 
                axiosInstance.get(`/document?key=${doc.id}&code=${AppConfig.PUBLIC_API_CODE}`, {})
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
            });
        }    
            
        async function deleteDocument(doc) {
            return new Promise((resolve, reject) => { 
                axiosInstance.delete(`/document?key=${doc.id}&eventType=${doc.eventType}&code=${AppConfig.PUBLIC_API_CODE}`, {})
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
            });
        }

        async function loadDocuments(params) {
            if (!params) params = {};
            if (!params.user) params.user = {};
            return new Promise((resolve, reject) => { 
                // TODO: Later we'll need to put something in place that allows paging or infinite scrolling 
                // on the queue API call on the client side.
                axiosInstance.get(`/queue?workspace=${params.workspaceUserId}&eventType=${params.eventType}&status=${params.status}&statusCategory=${params.statusCategory}&watcher=${params.watcherId}&complete=${params.complete}&checked=${params.checked}&start=${params.start}&count=${params.count}&linkedEvent=${params.linkedEvent}&assignedTo=${params.assignedTo}&lastModifiedBy=${params.lastModifiedBy}&createdBy=${params.createdBy}&code=${AppConfig.PUBLIC_API_CODE}`, {})
                .then((response) => resolve(response.data))
                .catch((error) => reject(error));
            });
        }

        const fetchData = async () => {
            dispatch({ type: 'FETCH_INIT' }); 
            try {
                switch (action.type) {
                    case 'event/get':
                        await getDocument(action.payload)
                        .then((result) => dispatch({ type: 'FETCH_SUCCESS', payload: result }))
                        .catch((error) => dispatch({ type: 'FETCH_FAILURE', payload: error }));
                        break;
                    case 'event/update':
                        await updateDocument(action.payload)
                        .then((result) => { 
                            applicationStateDispatcher({
                                type: 'event/tasks/update', 
                                payload: result
                            });
                            dispatch({ type: 'FETCH_SUCCESS', payload: result });
                        })
                        .catch((error) => dispatch({ type: 'FETCH_FAILURE', payload: error }));
                        break;
                    case 'event/load':  
                        // console.log(action.payload);
                        await loadDocuments(action.payload)
                        .then((result) => { 
                            // console.log(result);
                            if (result.result && result.result !== 'SUCCESS') {
                                result = [];
                            }
                            applicationStateDispatcher({type: 'event/tasks/load', payload: result});
                            dispatch({ type: 'FETCH_SUCCESS', payload: result });
                        })
                        .catch((error) => dispatch({ type: 'FETCH_FAILURE', payload: error }));
                        break;
                    case 'event/create':  
                        var newDoc = {...action.payload, createdBy: {...applicationState.user}};
                        await createDocument(newDoc)
                        .then((result) => {
                            console.log('CREATE', result);
                            if (result.result === 'SUCCESS') {
                                applicationStateDispatcher({type: 'event/tasks/new', payload: result.detail});
                                dispatch({ type: 'FETCH_SUCCESS', payload: result });
                            } else {
                                // error
                            }
                        })
                        .catch((error) => dispatch({ type: 'FETCH_FAILURE', payload: error }));
                        break;
                    case 'event/delete': 
                        console.log('DELETE', action.payload);
                        await deleteDocument(action.payload)
                        .then((result) => {
                            console.log(result);
                            if (result.result === 'SUCCESS') {
                                applicationStateDispatcher({type: 'event/tasks/delete', payload: action.payload});
                                dispatch({ type: 'FETCH_SUCCESS', payload: result });
                            } else {
                                // handle error
                            }
                        })
                        .catch((error) => dispatch({ type: 'FETCH_FAILURE', payload: error }));
                        break;

                    case 'workspace/get':
                        await getDocument(action.payload)
                        .then((result) => dispatch({ type: 'FETCH_SUCCESS', payload: result }))
                        .catch((error) => dispatch({ type: 'FETCH_FAILURE', payload: error }));
                        break;
                    case 'workspace/update':
                        await updateDocument(action.payload)
                        .then((result) => { 
                            applicationStateDispatcher({
                                type: 'workspace/tasks/update', 
                                payload: result
                            });
                            dispatch({ type: 'FETCH_SUCCESS', payload: result });
                        })
                        .catch((error) => dispatch({ type: 'FETCH_FAILURE', payload: error }));
                        break;
                    case 'workspace/load':  
                        await loadDocuments(action.payload)
                        .then((result) => { 
                            if (result.result && result.result !== 'SUCCESS') {
                                result = [];
                            }
                            applicationStateDispatcher({type: 'workspace/tasks/load', payload: result});
                            dispatch({ type: 'FETCH_SUCCESS', payload: result });
                        })
                        .catch((error) => dispatch({ type: 'FETCH_FAILURE', payload: error }));
                        break;
                    case 'workspace/create': 
                        var newDoc = {...action.payload, createdBy: {...applicationState.user}};
                        await createDocument(newDoc)
                        .then((result) => {
                            // console.log('CREATE', result);
                            if (result.result === 'SUCCESS') {
                                result.detail.createdBy = {...applicationState.user};
                                applicationStateDispatcher({type: 'workspace/tasks/new', payload: result.detail});
                                dispatch({ type: 'FETCH_SUCCESS', payload: result });
                            } else {
                                // error
                            }
                        })
                        .catch((error) => dispatch({ type: 'FETCH_FAILURE', payload: error }));
                        break;
                    case 'workspace/delete': 
                        // console.log('DELETE', action.payload);
                        await deleteDocument(action.payload)
                        .then((result) => {
                            console.log(result);
                            applicationStateDispatcher({type: 'workspace/tasks/delete', payload: action.payload});
                            dispatch({ type: 'FETCH_SUCCESS', payload: result });
                        })
                        .catch((error) => dispatch({ type: 'FETCH_FAILURE', payload: error }));
                        break;

                    case 'watch/update':
                            await updateDocument(action.payload)
                            .then((result) => { 
                                applicationStateDispatcher({
                                    type: 'watch/tasks/update', 
                                    payload: result
                                });
                                dispatch({ type: 'FETCH_SUCCESS', payload: result });
                            })
                            .catch((error) => dispatch({ type: 'FETCH_FAILURE', payload: error }));
                            break;

                    case 'approvals/update':
                        await updateDocument(action.payload)
                        .then((result) => { 
                            applicationStateDispatcher({
                                type: 'approvals/tasks/update', 
                                payload: result
                            });
                            dispatch({ type: 'FETCH_SUCCESS', payload: result });
                        })
                        .catch((error) => dispatch({ type: 'FETCH_FAILURE', payload: error }));
                        break;
                    default: 
                        break;
                }  
            } catch (error) {
                dispatch({ type: 'FETCH_FAILURE' });
            }
        }; 
        fetchData();
    }, [action]); 
    return [state, setAction];
};

const dataFetchReducer = (state, action) => {
    switch (action.type) {
      case 'FETCH_INIT':
        return {
          ...state,
          isLoading: true,
          isError: false
        };
      case 'FETCH_SUCCESS':
        return {
          ...state,
          isLoading: false,
          isError: false,
          data: action.payload,
        };
      case 'FETCH_FAILURE':
        return {
          ...state,
          isLoading: false,
          isError: true,
          error: action.payload,
        };
      default:
        throw new Error();
    }
}; 