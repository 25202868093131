import React, { useState, useContext, useEffect } from 'react'; 
import { motion, resolveMotionValue } from 'framer-motion';
import { colors } from '@atlaskit/theme'; 
import { makeStyles, withStyles } from '@material-ui/core/styles';   
// import Tooltip from '@material-ui/core/Tooltip';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import CheckIcon from '@material-ui/icons/Check';
import LinkIcon from '@material-ui/icons/Link';
import ClearIcon from '@material-ui/icons/Clear';
import DeleteIcon from '@material-ui/icons/Delete';
import ScheduleIcon from '@material-ui/icons/Schedule';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { AppContextState } from '../contexts/AppContext';
import {
    CustomTextField
} from '../components/CustomFields';
import {
    Paper,
    Typography,
    Chip,
    // IconButton,
    Divider,
} from '@material-ui/core'; 
import {
    getTheme,
    TextField,
    FontWeights,
    ActivityItem,
    Link, 
    mergeStyleSets, 
    Checkbox,
    Persona, 
    PersonaSize, 
    PersonaPresence,
    Panel, 
    Pivot, 
    Modal,
    PivotItem,
    IconButton,
    PanelType,
    PersonaInitialsColor,  
    TooltipHost,
    Stack,
    Icon,
    // Label,   
    CommandButton,
    DefaultButton,
    PrimaryButton,
    CommandBarButton,
    // Dialog, DialogType, DialogFooter
} from '@fluentui/react';  
import GraphPeoplePicker from '../components/GraphPeoplePicker';  
import CustomFileDropper from './CustomFileDropper';
  
import { useId } from '@fluentui/react-hooks';

import {
    userAllPresences, 
    userProfilePhoto, 
  } from '../utils/graph';

const classNames = mergeStyleSets({
    exampleRoot: {
      marginTop: '20px',
    },
    nameText: {
      fontWeight: 'bold',
    },
});

function ActivityLog({document}) {
    const [items, setItems] = useState([]); 
    const applicationState = useContext(AppContextState);

    useEffect(() => {
        async function getProfileImage(userId) {
            return new Promise((resolve, reject) => {
                userProfilePhoto(applicationState.user.accessToken, userId)
                .then((pp) => {
                    if (pp) { 
                        const url = window.URL || window.webkitURL;
                        const blobUrl = url.createObjectURL(pp);
                        pp = blobUrl;
                    } 
                    resolve(pp);
                })
                .catch((error) => reject(error));
            });
        }

        async function loadHistory(h) {
            if (!h || h.length === 0) return;
            const events = [];
            for (let j = 0; j < h.length; j++) {
                await getProfileImage(h[j].user.id)
                .then((imageUrl) => {
                    h[j].user.imageUrl = imageUrl;
                    events.push({ 
                        key: j, 
                        iconName: h[j].iconName,
                        iconColor: h[j].iconColor,
                        type: h[j].type,
                        activityPersonas: [{ imageUrl: h[j].user.imageUrl }],
                        comments: h[j].comment,
                        timeStamp: h[j].timeStamp,
                        activityDescription: [
                            <Link
                                key={1}
                                className={classNames.nameText}
                                onClick={() => {
                                    console.log(h[j]); 
                                }}
                                >
                            {h[j].user.displayName}
                            </Link>,
                            <span key={2}> {h[j].content} </span>,
                            <span key={3} className={classNames.nameText}>
                                {document.id}
                            </span>,
                        ],
                    })
                })
                .catch((error) => console.log(error));
                
            } 
            return events;
        }

        if (document.history) {
            loadHistory(document.history)
            .then((h) => {
                console.log(h);
                if (h && h.length > 0) {
                    setItems(s => [
                        ...h, 
                        ...s,
                    ]);
                }
            })             
        } 
    }, []);
 
    return (
        <div>
            {items.length > 0 && items.map((item) => {
                switch (item.type) {
                    case 'history': return (
                        <div style={{display: 'flex', alignItems: 'center', }}> 
                            {item.iconName && <div style={{height: '100%', paddingTop: '24px', paddingRight: '8px', color: item.iconColor || 'inherited', fontWeight: 600}}><Icon iconName={item.iconName}/></div>}
                            <ActivityItem {...item} key={item.key} className={classNames.exampleRoot} />
                        </div>
                    );
                    case 'approval': 
                    default: return (
                        <div style={{display: 'flex', alignItems: 'center', }}> 
                            {item.iconName && <div style={{height: '100%', paddingTop: '24px', paddingRight: '8px', color: item.iconColor || 'inherited', fontWeight: 600}}><Icon iconName={item.iconName}/></div>}
                            <ActivityItem {...item} key={item.key} className={classNames.exampleRoot} />
                        </div>
                    );
                }
                
            })}
        </div>
    );
}
 
const DialogBasicExample = ({children, title, description, open, onCancel, cancelText, onConfirm, confirmText, onEscape}) => {
    const dialogStyles = { main: { maxWidth: 450 } };
    // const dialogContentProps = {
    //     type: DialogType.close,
    //     title: title || 'Confirmation Dialog',
    //     closeButtonAriaLabel: 'Close',
    //     subText: description || 'Confirm your action.',
    // };

    const [dialogOpen, setDialogOpen] = useState(false); 
    const labelId = useId('dialogLabel');
    const subTextId = useId('subTextLabel');

    const titleId = useId('title');

    // const modalProps = React.useMemo(
    //     () => ({
    //         titleAriaId: labelId,
    //         subtitleAriaId: subTextId,
    //         isBlocking: false,
    //         styles: dialogStyles, 
    //     }),
    //     [labelId, subTextId],  
    // );

    React.useEffect(() => {
        setDialogOpen(open);
    }, [open]); 

    return (  
      <Modal
        titleAriaId={'modal-'+title}
        isOpen={dialogOpen}
        isBlocking={false}
        onDismiss={() => {
            if (onEscape) onEscape();
            else if (onCancel) onCancel();
        }}
        containerClassName={contentStyles.container} 
        >
        <div className={contentStyles.header}>
            <span id={titleId}>{title}</span>
            <IconButton
                styles={iconButtonStyles}
                iconProps={{ iconName: 'Cancel' }}
                ariaLabel="Close"
                onClick={() => {
                    if (onEscape) onEscape();
                    else if (onCancel) onCancel();
                }}/>
        </div> 
        <div className={contentStyles.body}>
            <>
            <span id={subTextId}>{description}</span>
            {children}
            </>
        </div> 
        <div className={contentStyles.footer}> 
            <PrimaryButton 
                text={confirmText || 'OK'}
                onClick={() => {
                    if (onConfirm) onConfirm();
                }}/>
            <div style={{width: '8px'}}/>
            <DefaultButton
                text={cancelText || 'Cancel'}
                onClick={() => {
                    if (onCancel) onCancel();
                }}/>
        </div> 
      </Modal> 
    );
};


const theme = getTheme();
const contentStyles = mergeStyleSets({
  container: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
    minWidth: '500px',
  },
  header: [ 
    theme.fonts.xLargePlus,
    {
      flex: '1 1 auto',
      borderTop: `4px solid ${theme.palette.themePrimary}`,
      color: theme.palette.neutralPrimary,
      display: 'flex',
      alignItems: 'center',
      fontWeight: FontWeights.semibold,
      padding: '12px 12px 14px 24px',
    },
  ],
  body: {
    flex: '4 4 auto',
    padding: '0 24px 24px 24px',
    overflowY: 'hidden',
    selectors: {
      p: { margin: '14px 0' },
      'p:first-child': { marginTop: 0 },
      'p:last-child': { marginBottom: 0 },
    },
  },
  footer: {
    flex: '1 1 auto',
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '0 24px 24px 24px',
  }
}); 
const iconButtonStyles = {
  root: {
    color: theme.palette.neutralPrimary,
    marginLeft: 'auto',
    marginTop: '4px',
    marginRight: '2px',
  },
  rootHovered: {
    color: theme.palette.neutralDark,
  },
};


function getPersona(name, hideName) {
    var initials = '?';
    if (name) {
        var init = name.split(' ');
        if (init.length > 0) initials = init[0][0];
        if (init.length > 1) initials += init[1][0];
        initials = initials.toUpperCase();
    }
    return { 
        secondaryText: '',
        tertiaryText: '',
        optionalText: '',
        text: name,
        hidePersonaDetails: (hideName) ? hideName : undefined,
        imageInitials: initials,
    }
}

function recordHistory(data, account, { comment, iconColor, iconName, content }) {
    if (!data.history) data.history = []; 
    data.history.push({ 
        comment: comment,
        iconColor: iconColor,
        iconName: iconName,
        content: content,
        timeStamp: (new Date()).toString(), 
        user: {
            id: account.id,
            displayName: account.displayName,  
        } 
    });
}

function ChecklistItemPanelEditor({open, setOpen, data, setData, onDelete}) { 
    const applicationState = useContext(AppContextState);
    const [deleteConfirmation, setDeleteConfirmation] = useState(false); 
    const [approvalConfirmation, setApprovalConfirmation] = useState(false); 
    const [approvalDialogFeedback, setApprovalDialogFeedback] = useState('');
    return (
        <>
        <DialogBasicExample 
            title={'Delete Task'} 
            description={"Are you sure you'd like to delete " + data.id + "? This will remove it from the assignee's and all watchers' workspaces."} 
            open={deleteConfirmation} 
            onCancel={() => setDeleteConfirmation(false)} 
            onConfirm={() => {
                setDeleteConfirmation(false); 
                if (data && data.id && onDelete) {
                    onDelete();
                } else { 
                    console.log('Error deleting task', data); 
                }
            }}
            confirmText={"Delete"}/>
        <DialogBasicExample 
            title={'Approval Request'} 
            description={data.id + " requires your approval."} 
            open={approvalConfirmation} 
            onEscape={() => {
                setApprovalConfirmation(false);
                setApprovalDialogFeedback('');
            }}
            onCancel={() => {
                setApprovalConfirmation(false); 
                var idx = data.eventData.approvalRecords.findIndex(x => x.requestId === data.eventData.currentApprovalId &&  x.approverId === applicationState.user.id);
                if (idx >= 0) {
                    recordHistory(data, applicationState.user, {
                        iconName: 'ChromeClose',
                        iconColor: 'red',
                        content: 'denied', 
                        comment: approvalDialogFeedback
                    });
                    data.eventData.approvalRecords[idx].status = 'denied';
                    data.eventData.approvalRecords[idx].feedback = approvalDialogFeedback;
                    data.eventData.approvalRecords[idx].resultDate = (new Date()).toString();
                    data.eventData.approvalRecords[idx].active = false;
                } else {
                    console.log('Failed to find an active approval request');
                }
                const activeRecords = data.eventData.approvalRecords.filter(x => x.active);
                console.log(activeRecords);
                if (activeRecords.length === 0) {
                    data.eventData.submittedForApproval = false;
                }
                console.log(data.eventData)
                setData({...data});
                setApprovalDialogFeedback('');
            }} 
            onConfirm={() => {
                setApprovalConfirmation(false);  
                var idx = data.eventData.approvalRecords.findIndex(x => x.requestId === data.eventData.currentApprovalId &&  x.approverId === applicationState.user.id);
                if (idx >= 0) { 
                    recordHistory(data, applicationState.user, {
                        iconName: 'CheckMark',
                        iconColor: 'green',
                        content: 'approved', 
                        comment: approvalDialogFeedback
                    }); 
                    data.eventData.approvalRecords[idx].status = 'approved';
                    data.eventData.approvalRecords[idx].feedback = approvalDialogFeedback;
                    data.eventData.approvalRecords[idx].resultDate = (new Date()).toString();
                    data.eventData.approvalRecords[idx].active = false;
                }
                const activeRecords = data.eventData.approvalRecords.filter(x => x.active);
                console.log(activeRecords);
                if (activeRecords.length === 0) {
                    data.eventData.submittedForApproval = false;
                }
                console.log(data.eventData)
                setData({...data});
                setApprovalDialogFeedback('');
            }}
            cancelText={"Deny"}
            confirmText={"Approve"}>
                <TextField label="Comment" onChange={(ev, value) => setApprovalDialogFeedback(value)} multiline rows={3} placeholder="Provide feedback for the request" />
            </DialogBasicExample>
        <Panel
            isLightDismiss={!(approvalConfirmation || deleteConfirmation)}
            headerText={
                <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <Typography style={{marginTop:'4px', fontFamily: 'Segoe UI', fontWeight: 600}} variant="h5">{(data && data.id) ? data.id : 'Task'}</Typography>
                        {data.eventData.link && 
                            <>
                                <div style={{width:'8px'}}/>
                                <LabelTag 
                                    onClick={() => {}}
                                    tooltip={`This task was created for ${data.eventData.link}`}
                                    appearance={colors.R200 }
                                    icon={<LinkIcon style={{height: '14px', color: 'white'}}/>} 
                                    label={data.eventData.link}/>
                            </> 
                        }
                        {data.eventData.submittedForApproval && 
                            <>
                            <div style={{width:'8px'}}/>
                            <LabelTag 
                                onClick={() => {}}
                                tooltip={`This task has a pending approval request`}
                                appearance={colors.G300}
                                icon={<ThumbUpIcon style={{height: '14px', color: 'white'}}/>} 
                                label="Pending Approval"/>
                                </> 
                        }
                    </div>
                    <div style={{display: 'flex'}}> 
                    {/* <AnimatedIconButton style={{visibility: 'hidden'}}/>   */}
                    <AnimatedIconButton   
                        disabled
                        selected={data.eventData.isApproval===true} 
                        tooltip={(data.eventData.isApproval===true) ? "Turn off approvals for this task" : "Make this an approval task"}
                        onClick={() => {
                            if (data.eventData.submittedForApproval) {
                                data.eventData.isApproval = true;
                                setData({...data});
                                return;
                            }
                            if (!data.eventData.isApproval) data.eventData.isApproval = true;
                            else data.eventData.isApproval = false; 
                            if (data.eventData.isApproval) {
                                data.eventData.label = {
                                    text: 'Approval',
                                    tooltip: 'Approval request'
                                }
                            } else {
                                data.eventData.label = {}
                            }
                            setData({...data});
                        }}>
                        <ThumbUpIcon/>
                    </AnimatedIconButton>
                    {/* <AnimatedIconButton  
                        selected={(data.eventData.link !== undefined && data.eventData.link !== null)}
                        disabled 
                        tooltip={(data.eventData.link !== undefined && data.eventData.link !== null) ? `Unlink ${data.eventData.link}`: "Link an event"}
                        onClick={() => {}}>
                        <LinkIcon/>
                    </AnimatedIconButton>
                    <AnimatedIconButton  
                        selected={false}
                        disabled 
                        tooltip="Make this a recurring task"
                        onClick={() => {}}>
                        <ScheduleIcon/>
                    </AnimatedIconButton> */}
                    <AnimatedIconButton  
                        selected={(data.eventData.watchers && data.eventData.watchers.length > 0 && data.eventData.watchers.filter(x => x === applicationState.user.id).length > 0)}
                        tooltip={((data.eventData.watchers && data.eventData.watchers.length > 0 && data.eventData.watchers.filter(x => x === applicationState.user.id).length > 0)) ? "Stop watching" : "Watch this task"}
                        onClick={() => { 
                            if (!data.eventData.watchers) data.eventData.watchers = [];              
                            var idx = data.eventData.watchers.findIndex(x => x === applicationState.user.id); 
                            if (idx < 0) { 
                                console.log('Could not find watcher, adding...');
                                data.eventData.watchers.push(applicationState.user.id);
                                recordHistory(data, applicationState.user, { 
                                    iconName: 'View',
                                    content: 'started watching',
                                });
                            } else {
                                console.log('Found user as watcher, removing...');
                                data.eventData.watchers.splice(idx, 1); // remove me as a watcher 
                                recordHistory(data, applicationState.user, { 
                                    iconName: 'View',
                                    content: 'stopped watching',
                                });
                            }
                            setData({...data});
                        }}>
                        <VisibilityIcon/>
                    </AnimatedIconButton>
                    {/* TODO: only allow deletes if current user id === createdBy user id */}
                    <AnimatedIconButton   
                        tooltip="Delete this task"
                        onClick={() => {
                            setDeleteConfirmation(true);
                        }}>
                        <DeleteIcon/>
                    </AnimatedIconButton>
                    </div>
                </div> 
            }
            isOpen={open}
            type={PanelType.medium}
            onDismiss={() => setOpen(false)} 
            closeButtonAriaLabel="Close">
            <Divider style={{margin: '16px 0px'}}/>
            <div style={{display: 'flex'}}>
                <div style={{marginTop: '7px'}}>
                    <Checkbox 
                        checked={data.eventData.checked}
                        onChange={(e, v) => {
                            data.eventData.checked = v;
                            if (data.eventData.checked) data.eventData.checkedDate = (new Date());
                            recordHistory(data, applicationState.user, {
                                iconName: 'Completed',
                                content: 'completed',
                            });
                            setData({...data});
                        }}/>  
                </div>
                <CustomTextField  
                    placeholder="Got something to do?" 
                    value={data.eventData.text} 
                    textSize="18px"
                    textWeight={600}
                    setValue={(v) => {
                        if (data.eventData.text !== v) {
                            recordHistory(data, applicationState.user, {
                                iconName: 'Edit',
                                content: 'edited',
                                comment: data.eventData.text + ' → ' + v,
                            });
                            data.eventData.text = v;
                            setData({...data});
                        }
                    }}/>  
            </div> 
            <Divider style={{margin: '16px 0px'}}/> 
            {/* {data.eventData.link && <CustomTextField 
                    vertical
                    label="Link"
                    placeholder="Enter an event key (ie. SHIP-123)" 
                    value={data.eventData.link} 
                    setValue={(v) => {
                        data.eventData.link = v;
                        setData({...data});
                    }}/>} */} 
            
            <div style={{display: 'flex'}}>
                <GraphPeoplePicker
                    label="Assigned to" 
                    users={[data.eventData.assignedTo]} 
                    updateUsers={(v) => {
                        if (v && v.length > 0) { 
                            if (v[0].text !== data.eventData.assignedTo.displayName) {
                                recordHistory(data, applicationState.user, {
                                    iconName: 'Assign',
                                    content: 'reassigned', 
                                    comment: 'From ' + data.eventData.assignedTo.displayName + ' to ' + v[0].text
                                });
                            }
                            data.eventData.assignedTo = {
                                userId: v[0].id,
                                displayName: v[0].text,
                            }
                            if (v[0].id !== applicationState.user.id) {                  // If I'm assigning this task to another person
                                if (!data.eventData.watchers) data.eventData.watchers = [];                     // Make sure the watcher list exists
                                if (data.eventData.watchers.filter(x => x === applicationState.user.id).length === 0) {   // If I am not already a watcher
                                    data.eventData.watchers.push(applicationState.user.id);
                                }
                            }
                            setData({...data});
                        }
                    }}/>
                {(data.eventData.isApproval===true) && 
                    <div style={{display: 'flex', width: '100%', justifyContent: 'flex-end'}}>
                        {!data.eventData.submittedForApproval && applicationState.user.id === data.eventData.assignedTo.userId &&
                        <PrimaryButton 
                            text={'Request approval'} 
                            onClick={() => { 
                                recordHistory(data, applicationState.user, {
                                    iconName: 'SchoolDataSyncLogo',
                                    content: 'requested an approval for', 
                                });
                                data.eventData.submittedForApproval = true;
                                data.eventData.submittedForApprovalDate = (new Date()).toString();
                                if (!data.eventData.currentApprovalId) data.eventData.currentApprovalId = 0;
                                data.eventData.currentApprovalId = data.eventData.currentApprovalId + 1;
                                if (!data.eventData.approvalRecords) data.eventData.approvalRecords = [];
                                for (let i = 0; i < data.eventData.approvers.length; i++) {
                                    data.eventData.approvalRecords.push({
                                        type: 'approval',
                                        active: true,
                                        resultDate: data.eventData.submittedForApprovalDate,
                                        status: 'pending', // 'denied', 'approved'
                                        approverId: data.eventData.approvers[i].userId, 
                                        requestId: data.eventData.currentApprovalId, // each approval group will have a unique index 
                                    });
                                } 
                                setData({...data}); 
                            }}/> 
                        }
                        {data.eventData.submittedForApproval && applicationState.user.id === data.eventData.assignedTo.userId &&
                            <PrimaryButton 
                                text="Cancel approval request"
                                onClick={() => {
                                    recordHistory(data, applicationState.user, {
                                        iconName: 'SchoolDataSyncLogo',
                                        content: 'cancelled an approval request for', 
                                    });
                                    data.eventData.submittedForApproval = false;
                                    data.eventData.approvalRecords = data.eventData.approvalRecords.map(x => {
                                        x.active = false;
                                        if (x.status === 'pending') {
                                            x.resultDate = (new Date()).toString();
                                            x.status = 'cancelled';
                                        }
                                        return x;
                                    });
                                    setData({...data}); 
                                }}
                                />
                        }
                    </div>
                }
            </div>
            {(data.eventData.isApproval===true) && 
            <div style={{display: 'flex', marginTop: '8px'}}>
                <GraphPeoplePicker
                    variant="multiple"
                    label="Approvers"
                    users={data.eventData.approvers}
                    updateUsers={(v) => {
                        if (v && v.length > 0) {
                            data.eventData.approvers = v.map(x => {
                                return {
                                    userId: x.id,
                                    displayName: x.text,
                                };
                            });
                        } else { 
                            data.eventData.approvers = [];
                        } 
                        setData({...data});
                    }}
                    />
                {(data.eventData.submittedForApproval && 
                    data.eventData.approvalRecords && 
                    data.eventData.approvalRecords.findIndex(x => x.status === 'pending' && x.approverId === applicationState.user.id) >= 0) && 
                    <div style={{display: 'flex', width: '100%', justifyContent: 'flex-end'}}>
                        <PrimaryButton 
                            text={'Respond to approval request'}
                            onClick={() => {   
                                setApprovalConfirmation(true);
                            }}/> 
                    </div>
                }
            </div> 
            } 
            <Divider style={{marginBottom: '16px', marginTop: '8px'}}/> 
            
            {/* <Divider style={{margin: '16px 0px'}}/> */}

            <Pivot style={{textAlign: 'left'}} aria-label="pivottable">
                <PivotItem headerText="Notes">
                    <>
                        <div style={{marginTop: '16px'}}/>
                        <CustomTextField 
                            vertical
                            // label="Notes"
                            placeholder="Provide some additional notes about the task" 
                            value={data.eventData.notes} 
                            setValue={(v) => {
                                data.eventData.notes = v;
                                setData({...data});
                            }}/>
                    </>
                </PivotItem>
                <PivotItem headerText="Attachments">
                    <CustomFileDropper document={data} setDocument={setData}/>
                </PivotItem>
                <PivotItem headerText="History">
                    <ActivityLog document={data}/>
                </PivotItem>
            </Pivot>  
        </Panel>
        </>
    )
}

export function ChecklistItem({data, setData, onDelete, hover, noPanel, editable, onSubmit, onCancel, hideAvatar, previewMode}) {
    const [isHovered, setIsHovered] = useState(false);
    const [editPanelOpen, setEditPanelOpen] = useState(false);
    const [persona, setPersona] = useState(data.eventData.assignedTo); 
    
    const applicationState = useContext(AppContextState); 

    React.useEffect(() => {
        function extractInitials(name) {
            var initials = '?';
            if (name) {
                var init = name.split(' ');
                if (init.length > 0) initials = init[0][0];
                if (init.length > 1) initials += init[init.length - 1][0];
                initials = initials.toUpperCase();
            }
            return initials;
        } 
    
        function convertStatusToPresence(availability) {
            switch (availability) {
                case "Available": return PersonaPresence.online;
                case "Offline": return PersonaPresence.offline;
                case "Busy": return PersonaPresence.busy;
                case "InAMeeting" : return PersonaPresence.dnd;
                case "InACall": return PersonaPresence.dnd;
                case 'Away': return PersonaPresence.away;
                case "PresenceUnknown": return PersonaPresence.blocked;
                default: 
                    return PersonaPresence.none;
            }
        }

        function formatPersona(profile) { 
            var secondary = (profile.presence) ? profile.presence.activity : undefined;
            if (secondary === 'PresenceUnknown') secondary = 'Not available';
            else if (secondary === 'InAMeeting') secondary = 'In a meeting';
            else if (secondary === 'InACall') secondary = 'On a call';
            var presTitle = (profile.presence) ? profile.presence.availability : undefined;
            if (presTitle === 'PresenceUnknown') presTitle = 'Not available';
            else if (presTitle === 'InAMeeting') presTitle = 'In a meeting';
            else if (presTitle === 'InACall') presTitle = 'On a call'; 
            const r = {
                presence: convertStatusToPresence((profile.presence) ? profile.presence.availability : undefined),
                // secondaryText: secondary, 
                presenceTitle: presTitle,
                id: profile.userId,
                imageUrl: profile.imageUrl,
                // text: profile.displayName, 
                imageInitials: extractInitials(profile.displayName),
            }; 
            return r;
        }

        async function loadPersona(user) {
            const _persona = user;
            await userAllPresences(applicationState.user.accessToken, [_persona.userId])
            .then((presence) => {
                if (presence && presence.value && presence.value.length > 0) presence = presence.value[0];
                else presence = {}; 
                _persona.presence = presence;
            })
            .catch((error) => console.log(error));
            await userProfilePhoto(applicationState.user.accessToken, _persona.userId)
            .then((pp) => {
                if (pp) { 
                    const url = window.URL || window.webkitURL;
                    const blobUrl = url.createObjectURL(pp);
                    pp = blobUrl;
                } 
                _persona.imageUrl = pp;  
            })
            .catch((error) => console.log(error));
            return formatPersona(_persona);
        }
        loadPersona(persona)
        .then(result => setPersona(result))
        .catch(error => console.log(error));
    }, [])
 
    return (
        <>
         {!noPanel && <ChecklistItemPanelEditor 
                open={editPanelOpen} 
                setOpen={setEditPanelOpen} 
                onDelete={onDelete}
                data={data} 
                setData={setData}/>}
        <div style={{textAlign: 'left', cursor: 'pointer' }}>
            <FocusCard previewMode={previewMode} hover={hover} onHoverChanged={setIsHovered}>
                <div style={{display: 'flex', alignItems: 'center', padding: '6px 10px 6px 10px', }}>
                    <Checkbox 
                        disabled={previewMode}
                        checked={data.eventData.checked}
                        onChange={(e, v) => {
                            data.eventData.checked = v;
                            if (data.eventData.checked) data.eventData.checkedDate = (new Date());
                            setData({...data});
                        }}/> 
                    <div style={{width: '12px'}}/>
                    {data.eventData.assignedTo && 
                        <Persona
                            {...persona} 
                            size={PersonaSize.size32} 
                            />
                    }
                    {!data.eventData.assignedTo && !hideAvatar && 
                        <Persona
                        {...getPersona('Unassigned')}
                        imageInitials="?"
                        initialsColor={PersonaInitialsColor.gray}
                        size={PersonaSize.size32}
                        presence={PersonaPresence.none} 
                        imageAlt={'Unassigned'}
                        />}
                    <div style={{display: 'flex'}} onClick={() => setEditPanelOpen(true)}>
                    {data.id && <Typography 
                        style={{
                            marginLeft: '16px', 
                            minWidth: '70px',
                            fontFamily: 'Segoe UI', 
                            color: colors.N100, 
                            width: 'auto'
                        }} 
                        variant='caption'>
                        {data.id}
                    </Typography>}
                    {!data.id && <div style={{width:'16px'}}/>}
                    {data.eventData.link && 
                        <LabelTag 
                            tooltip={`This task was created for ${data.eventData.link}`}
                            appearance={colors.R200 }
                            icon={<LinkIcon style={{height: '14px', color: 'white'}}/>} 
                            label={data.eventData.link}/>
                    }
                    { data.eventData.submittedForApproval && 
                        <LabelTag 
                            tooltip="This task has a pending approval request"
                            appearance={colors.G300}
                            icon={<ThumbUpIcon style={{height: '14px', color: 'white'}}/>} 
                            label="Pending Approval"/>
                    }
                    {/* {data.eventData.label && data.eventData.label.text && 
                        <LabelTag 
                            tooltip={data.eventData.label.tooltip}
                            appearance={data.eventData.label.appearance || colors.G200}
                            icon={data.eventData.label.icon || <ThumbUpIcon style={{height: '14px', color: 'white'}}/>} 
                            label={data.eventData.label.text}/>
                    } */}
                    <div style={{width:'4px'}}/>
                    {data.eventData.notes && 
                    <div style={{marginRight: '8px'}}>
                        <CustomToolTop title={data.eventData.notes}>
                            <Icon iconName="Info" />
                        </CustomToolTop>
                    </div>}
                    {data.attachments && data.attachments.length > 0 &&
                    <div style={{marginRight: '8px'}}>
                        <CustomToolTop title={`${data.attachments.length} attachment${(data.attachments.length > 1) ? 's':''}`}>
                         <Icon iconName="Attach" />
                         </CustomToolTop>
                    </div>}
                    {data.eventData.text && !editable && <Typography style={{fontFamily: 'Segoe UI', fontSize: '14px'}}>{data.eventData.text}</Typography>}
                    {!data.eventData.text && !editable && <Typography style={{fontFamily: 'Segoe UI', fontStyle: 'italic',  fontSize: '14px', color: colors.N100}}>Got something to do?</Typography>}
                    {editable &&  
                        <CustomTextField 
                            placeholder="Got something to do?" 
                            value={data.eventData.text} 
                            setValue={(v) => {
                                data.eventData.text = v;
                                const result = {...data};
                                setData(result);
                                if (onSubmit) onSubmit(result);
                            }}
                            />
                    }
                    {previewMode && isHovered && 
                        <AnimatedIconButton  
                            style={{height: '28px', width: '28px', marginLeft: '8px', marginRight: '8px'}} 
                            tooltip="Cancel"
                            onClick={() => {if (onCancel) onCancel()}}>
                            <ClearIcon/>
                        </AnimatedIconButton>
                    } 
                    </div>
                </div>
            </FocusCard>
        </div>
        </>
    );
} 

function FocusCard({children, previewMode, disabled, hover, onHoverChanged, scaleHover, scaleTap, shadowHover, shadowTap, style}) {
    const [isHovering, setIsHovering] = useState(false);
    function handleHover(h) {
        if (hover) {
            setIsHovering(h);
            if (onHoverChanged) {
                onHoverChanged(h);
            }
        }
    }
    return (
        <>
        {(disabled) ? 
            (
                <CardWrapper>
                    {children}
                </CardWrapper>
            ) : ( 
                <motion.div
                    style={Object.assign({
                        borderRadius: (previewMode) ? '0px' : '0px', 
                        border: (previewMode) ? '1px dotted #505F7988' : '1px solid #DFE1E6', 
                        backgroundColor: (previewMode) ? "0xFFFFFF27" : '0xFFFFFF', 
                        }, style)}
                    onHoverStart={() => {handleHover(true);}}
                    onHoverEnd={() => {handleHover(false);}}
                    whileHover={{ 
                        scale: (hover) ? (scaleHover || 1.004) : (1.0),  
                    }}
                    whileTap={{
                        scale: (hover) ? (scaleTap || 1.001) : (1.0),
                    }}>
                    <Paper 
                        style={{
                            backgroundColor: (previewMode) ? "0xFFFFFF27" : '0xFFFFFF', 
                            borderRadius: (previewMode) ? '0px' : '0px', 
                        }} 
                        elevation={(isHovering) ? 4 : (previewMode ? 0 : 1)}>
                        {children}
                    </Paper>
                </motion.div>
            )
        }
        </>
    )
}

function CardWrapper({children, style, className}) {
    return (
      <div 
        className={className} 
        style={Object.assign({
        border: '1px solid #DFE1E6',
        borderRadius: '0px',
        backgroundColor: "white",
        }, style)}>
          {children}
      </div>
    )
} 

function AnimatedIconButton({children, disabled, onClick, className, tooltip, size, selected}) { 
    return (
      <CustomToolTop title={tooltip}> 
          <IconButton  
            size={size} 
            disabled={disabled} 
            style={{color: (selected) ? undefined : '#8993A4'}} 
            onClick={onClick} 
            className={className}>
            {children}
          </IconButton> 
      </CustomToolTop>
    )
}

function CustomToolTop({children, title}) {
    if (!title) {
        return (
            <>
                {children}
            </>
        );
    }
    return (
        <TooltipHost content={title}>
            {children}
        </TooltipHost>
    )
}

function LabelTag({label, appearance, icon, bold, tooltip, onClick}) {
    return (
        <div style={{margin:'0px 2px'}}>
        <CustomToolTop title={tooltip}>
            <Chip 
                onClick={onClick}
                style={{
                    borderRadius: 2, 
                    fontWeight: (bold) ? 600: 400, 
                    padding:'4px 0px', 
                    color: 'white', 
                    backgroundColor: appearance || '#0078D4', 
                    fontFamily: 'Segoe UI', 
                    fontSize: '10px'
                }} 
                icon={icon} 
                size="small" 
                label={label}/>
        </CustomToolTop>
        </div>
    )
}

function createCustomLabelData({ tooltip, text, appearance, iconName }) {
    var icon = undefined;
    switch (iconName) {
        case 'Check': icon = (<CheckIcon style={{color: 'white',}}/>); break;
    }
    return {
        id: '',
        text: text,
        appearance: appearance,
        icon: icon,
        iconName: iconName, 
        tooltip: tooltip
    }
}

function createEmptyTask(assignedTo) {
    // console.log('ASSIGN', assignedTo)
    return {
        id: '',
        productIds: [],
        eventType: 'Task',
        eventTime: '',
        createdBy: {},
        history: [],
        attachments: [],
        eventData: {
            checked: false,
            text: '', 
            label: createCustomLabelData({ 
                text: 'New task',
                appearance: '#00875A', 
                iconName: 'Check', 
                tooltip: "Assign yourself a new task"
            }), 
            assignedTo: assignedTo,
            // assignedTo: {
            //     userId: '', 
            //     displayName: 'Cody Robson', 
            //     imageUrl: 'https://www.suffolkgazette.com/wp-content/uploads/2017/02/cookie-monster-diabetes.jpg'
            // }, 
        }
    };
}

export function ChecklistCollection({tasks, setTask, onAddTask, onDeleteTask}) {
    const applicationState = useContext(AppContextState); 
    const [createTaskVisible, setCreateTaskVisible] = useState(false);
    const [createTaskData, setCreateTaskData] = useState(undefined);
    // console.log(tasks);
    return (
        <Stack>
            {onAddTask && <Stack horizontal wrap>
                <CommandButton 
                    onClick={() => { 
                        setCreateTaskData(createEmptyTask({userId: applicationState.user.id, displayName: applicationState.user.displayName}));
                        setCreateTaskVisible(true);
                    }}
                    iconProps={{ iconName: 'Add' }}
                    text="Add"/> 
            </Stack>}
            <div style={{display: 'flex', flexDirection: 'column', marginTop: '16px'}}>   
                {createTaskVisible && 
                <>
                    <ChecklistItem 
                        noPanel previewMode editable 
                        data={createTaskData} 
                        onSubmit={(result) => { 
                            setCreateTaskVisible(false);
                            if (!result.eventData.text || result.eventData.text.length === 0) {
                                return;
                            }
                            delete result.eventData.label;
                            onAddTask(result); 
                        }}
                        onCancel={() => {
                            setCreateTaskVisible(false);
                        }}
                        setData={setCreateTaskData}/>
                    <div style={{ height: '16px' }}/>
                </>
                }

                {tasks && tasks.map && tasks.map(task => {
                    return (
                        <div style={{marginBottom: '-1px'}}>
                            <ChecklistItem 
                                hover 
                                data={task} 
                                setData={setTask}
                                onDelete={() => { if(onDeleteTask) onDeleteTask(task) }}/>
                        </div>
                    )
                })}
            </div>
        </Stack>
    )
}