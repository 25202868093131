import React, { useState, useEffect } from 'react'; 
// import { colors } from '@atlaskit/theme' 
// import Paper from '@material-ui/core/Paper'; 
import Typography from '@material-ui/core/Typography'; 
import AppHeader from '../components/AppHeader';
import MSTable from '../components/Tables/msTable';
import { useId } from '@fluentui/react-hooks';
import CreateJob from './CreateJob';
import { AppConfig } from '../utils/config.utils';
import useLocalStorage from '../utils/auth/useLocalStorage.hook';
import {
    CommandBarButton,
    SearchBox,
    Stack,
    getTheme,
    mergeStyleSets,
    FontWeights,
    ContextualMenu, 
    Modal, 
    DefaultButton,
    IconButton, 
    Breadcrumb,
} from '@fluentui/react';
  
function AllEmptyPage() {  
    function getRandomInt(max) {
        return Math.floor(Math.random() * Math.floor(max));
    }
    function getRandomOption() {
        var o = getRandomInt(randomTextTable.length);
        return randomTextTable[o];
    }
    const randomTextTable = [
        {title: 'You’ve cleaned up here perfectly', text: 'The only thing left to clean is your computer screen'},
        {title: 'Your queue is empty but what is empty?', text: 'Time to ponder the mysteries of the Universe'},
        {title: 'Your queue is empty', text: 'Cupcakes for all!'},
        {title: "Queue's empty! Dance party!", text: 'Congratulate yourself on a job well done'},
        {title: 'An empty queue deserves a quote of the day:', text: `"I'm not superstitious, but I am a little stitious." - Michael Scott`},
        {title: 'An empty queue deserves a quote of the day:', text: '"You miss 100% of the shots you don’t take. - Wayne Gretzky" - Michael Scott'},
        {title: 'An empty queue deserves a quote of the day:', text: '"Wikipedia is the best thing ever. Anyone in the world can write anything they want about any subject so you know you are getting the best possible information." - Michael Scott'},
        {title: 'An empty queue deserves a quote of the day:', text: '"I learned a while back that if I do not text 911, people do not return my calls. Um, but people always return my calls because they think that something horrible has happened." - Michael Scott'},
        {title: 'An empty queue deserves a quote of the day:', text: '"And I knew exactly what to do. But in a much more real sense, I had no idea what to do." - Michael Scott'},
        {title: 'An empty queue deserves a quote of the day:', text: '"I love inside jokes. I hope to be a part of one someday." - Michael Scott'},
        {title: 'An empty queue deserves a quote of the day:', text: `"Sometimes I'll start a sentence and I don't even know where it's going. I just hope I find it along the way." - Michael Scott`},
        {title: 'An empty queue deserves a quote of the day:', text: '"Would I rather be feared or loved? Easy. Both. I want people to be afraid of how much they love me." - Michael Scott'},
        {title: 'An empty queue deserves a quote of the day:', text: '"Webster’s Dictionary defines a wedding as: The fusing of two metals with a hot torch." - Michael Scott'},
        {title: 'An empty queue deserves a quote of the day:', text: '"When the son of the deposed king of Nigeria emails you directly, asking for help, you help! His father ran the freaking country! OK?" - Michael Scott'},
        {title: 'An empty queue deserves a quote of the day:', text: '"I saved a life. My own. Am I a hero?… I really can’t say, but yes!" - Michael Scott'},
        {title: 'An empty queue deserves a quote of the day:', text: '"There’s no such thing as an appropriate joke. That’s why it’s called a joke." - Michael Scott'},
        {title: 'An empty queue deserves a quote of the day:', text: '"People will never be replaced by machines. In the end, life and business are about human connections. And computers are about trying to murder you in a lake. And to me the choice is easy." - Michael Scott'},
        {title: 'Amazing! Nothing to do here but lament on a fact of the day:', text: 'Most car horns are in the key of F. Better hold it for a minute just to be sure.'},
        {title: 'Amazing! Nothing to do here but lament on a fact of the day:', text: 'Despite his prolific musical talents, Wolfgang Amadeus Mozart has been decomposing almost continuously since 1791.'},
        {title: 'Amazing! Nothing to do here but lament on a fact of the day:', text: 'About 11,000 Americans injure themselves while trying out weird positions per year.'},
        {title: 'Amazing! Nothing to do here but lament on a fact of the day:', text: 'Alaska is the only state whose name is on one row on a keyboard.'},
        {title: 'Amazing! Nothing to do here but lament on a fact of the day:', text: "They say people in glass houses shouldn't throw stones. What's worse - they always have to answer the door."},
        {title: 'Amazing! Nothing to do here but lament on a fact of the day:', text: 'Selfies now cause more deaths than shark attacks. Remember to bring a selfie stick with you to ward off attackers!'},
        {title: 'Amazing! Nothing to do here but lament on a fact of the day:', text: '"It’s impossible to hum while holding your nose." - Not with that attitude, I say!'},
        {title: 'Amazing! Nothing to do here but lament on a fact of the day:', text: "People used to say “prunes” instead of “cheese” when having their pictures taken. Now we say...Oh, someone's calling!"},
        {title: 'Amazing! Nothing to do here but lament on a fact of the day:', text: 'It snowed in the Sahara desert for 30 minutes on February 18, 1979.'},
        {title: 'Amazing! Nothing to do here but lament on a fact of the day:', text: 'A cubic inch of human bone can bear the weight of five standard pickup trucks. And yet, it still hurts to stub your toe on a wooden dining chair.'},
        {title: 'Amazing! Nothing to do here but lament on a fact of the day:', text: "A dragonfly has a lifespan of only one day. Make yours a good one!"},
        {title: 'Amazing! Nothing to do here but lament on a fact of the day:', text: "The average person falls asleep in seven minutes. Not something you want to be above average at."},
        {title: 'Amazing! Nothing to do here but lament on a fact of the day:', text: 'Rhubarb can spring up so fast that you can actually hear it grow.'}, 
    ];

    const [contentText, setContentText] = useState(getRandomOption())
  
    return (
      <div style={{marginTop:'50px', display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
        <img height='128px' src='/queue-empty.svg'/>
        <Typography style={{fontWeight:600}} variant='h6'>{contentText.title}</Typography>
        <Typography variant='body2'>{contentText.text}</Typography>
      </div>
    )
  } 

const axios = require('axios');
const axiosInstance = axios.create({
  baseURL: `${AppConfig.PUBLIC_API_URL}/api/latest`, 
  headers: {'Content-Type': 'application/json'}
});

async function updateDocument(doc) {
  return new Promise((resolve, reject) => { 
    axiosInstance.put(`/document?code=${AppConfig.PUBLIC_API_CODE}`, doc)
    .then(function (response) {
      console.log(response); 
      resolve();
    })
    .catch(function (error) {
      console.log(error);
      reject(error);
    });
  });
} 
          
async function createDocument(doc) { 
  return new Promise((resolve, reject) => {
    axiosInstance.post(`/document?code=${AppConfig.PUBLIC_API_CODE}`, doc)
    .then(function (response) {
      console.log(response); 
      resolve(response.data); 
    })
    .catch(function (error) {
      console.log(error);
      reject(error);
    });
  }); 
}

  
const rootTemplate = {
  id: '',
  productIds: [],
  eventType: 'Shipment', 
  eventTime: '',
  createdBy: {},
  history: [],
  attachments: [],
  eventData: {
    'key': '',
    'status': {
      'key': 'staging',
      'text': 'Staging',
      'category': 'unresolved',
    },
    'Date Shipped': '',
    'Special Instructions': '', 
    'Shipment Notes': '',  
    'From': {
      'Company': '',
      'Contact': '',
      'Phone': '', 
      'Address': '', 
      'City, State, Zip': '', 
      'Country': '',  
      'Rig': '',
      'Operator': '',
    },
    'To': {
      'Company': '',
      'Contact': '',
      'Phone': '', 
      'Address': '', 
      'City, State, Zip': '', 
      'Country': '', 
      'Rig': '',
      'Operator': '',
    }
  }
};

function createManifestTemplate() {
  return Object.assign({}, rootTemplate);
}

const AllJobs = ({hideFrame}) => {
  const [local, setLocal] = useLocalStorage('user', {});
    const [manifests, setManifests] = useState([]);
    const [altmanifests, setAltManifests] = useState([]);
    // const [childManifests, setChildManifests] = useState([]);
    const [filters, setFilters] = useState({ 
        eql: 'data.eventData.complete=false and data.eventType="Job"',
    }); 
    const [altfilters, setAltFilters] = useState({ 
        eql: 'data.eventData.complete=true and data.eventType="Job"',
    });
    // const [dialogs, setDialogs] = useState({});
  //  / const [selected, setSelected] = useState([]);
    // const [invalidSerial, setInvalid] = useState([]);
    // const [manifestCount, setManifestCount] = useState(0);
    // const [altmanifestCount, setAltManifestCount] = useState(0);
    const [activeFilters, setActiveFilters] = useState({});
    const [modalOpen, setModalOpen] = useState(false);
    const [fileSearchValue, setFileSearchValue] = useState('');
    const [document, setDocument] = useState(createManifestTemplate()); 
    const [overrideSave, setOverrideSave] = useState(false);
    const [downloadUrl, setDownloadUrl] = useState(null);
    
    // const [anchor, setAnchor] = useState(null);
    // const [nextDialog, setNext] = useState("");
    // const [override, setOverride] = useState(false);
    // const [success, setSuccess] = useState({ succeeded: null, message: '' });
    // const [hoverIndex, setHoverIndex] = useState(null);

    //sample data
   // const sampleManifests = [{"createdBy" : "John Doe", "created" : "2021-01-21", "status" : "completed" , "shipmentType" : "incoming", "shipTo" : "Houston", "shipFrom" : "Calgary"},{"createdBy" : "James Doe", "created" : "2021-01-28", "status" : "completed", "shipmentType" : "outgoing", "shipTo" : "Calgary", "shipFrom" : "Houston" },{"createdBy" : "Jane Doe", "created" : "2021-01-28", "status" : "staged", "shipmentType" : "outgoing", "shipTo" : "Calgary", "shipFrom" : "Houston" }];

    // const handleKeyDown = (e) => {
    //     if (e.key === 'Enter') {
    //         setFilters(s => ({ ...s, search: e.target.value }))
    //     }
    // }

    // const handleClick = (event) => {
    //     setAnchor(event.currentTarget);
    // }

    // const handleClose = () => {
    //     setAnchor(null);
    // }

    // const handleMenuClick = (event) => {
    //     setAnchor(null);
    //     const children = [];
    //     setNext(event.target.getAttribute("name"));

    //     Promise.all(
    //         selected.map(serial =>
    //             fetch(`${AppConfig.PUBLIC_API_URL}/shipments/${serial}?project=parentId`)
    //                 .then(resp => {
    //                     if (resp.status < 300) {
    //                         return resp.json()
    //                     }
    //                     return null;
    //                 })
    //         )
    //     ).then(jsons => {
    //         jsons.forEach((item, idx) => {
    //             if (item) {
    //                 if (!item.parentId) return;
    //                 if (!selected.includes(item.parentId)) {
    //                     children.push(selected[idx]);
    //                 }
    //             }
    //             return;
    //         })
    //         setChildManifests(children)
    //     });

    // }

    // useEffect(() => {
    //     if (!nextDialog) return;
    //     if (childManifests.length > 0) {
    //         setDialogs({ assetEditWarning: true });
    //     } else {
    //         setDialogs({ [nextDialog]: true });
    //     }
    // }, [childManifests, nextDialog]);

    // const onSuccess = (succeeded, message) => {
    //     if (succeeded) {
    //         setSelected([]);
    //         setSuccess({ succeeded: succeeded, message: message });
    //         setActiveFilters({ ...activeFilters });
    //     } else {
    //         setSuccess({ succeeded: succeeded, message: message });
    //     }
    // };

    // //for use with creation of assets
    // const onSemiSuccess = (invalidSerials) => {
    //     if (invalidSerials.length > 0) {
    //         setInvalid(invalidSerials);

    //     }
    // }

    // useEffect(() => {
    //     if (invalidSerial.length > 0) {
    //         setDialogs({ invalid: true });
    //     }
    // }, [invalidSerial])

   useEffect(() => {
        //generate the fetch url based on active filters and their keys
        const generateURL = (filters) => {
            let url = `${AppConfig.PUBLIC_API_URL}/api/latest/ship/queue`;
            const keys = Object.keys(filters);
            keys.forEach((key, idx) => {
                if (idx === 0) {
                    url = `${url}?${key}=${filters[key]}`;
                } else {
                    url = `${url}&${key}=${filters[key]}`;
                }
            });
            url += `&code=${AppConfig.PUBLIC_API_CODE}`;
            return url;
        };

        const urlToFetch = generateURL(filters);
        fetch(urlToFetch)
            .then(response => {
                if (response.status < 300) {
                    // console.log(response);
                    return response.json();
                } else {
                    return { data: [], count: [{ count: 0 }] };
                }
            })
            .then(json => {
                // console.log(json.length, json);
                setManifests(json);
                // setManifestCount(json.length);
            });
    }, [filters]);

    useEffect(() => {
        //generate the fetch url based on active filters and their keys
        const generateURL = (altfilters) => {
            let url = `${AppConfig.PUBLIC_API_URL}/api/latest/ship/queue`;
            const keys = Object.keys(altfilters);
            keys.forEach((key, idx) => {
                if (idx === 0) {
                    url = `${url}?${key}=${altfilters[key]}`;
                } else {
                    url = `${url}&${key}=${altfilters[key]}`;
                }
            });
            url += `&code=${AppConfig.PUBLIC_API_CODE}`;
            return url;
        };

        const urlToFetch = generateURL(altfilters);
        fetch(urlToFetch)
            .then(response => {
                if (response.status < 300) {
                    // console.log(response);
                    return response.json();
                } else {
                    return { data: [], count: [{ count: 0 }] };
                }
            })
            .then(json => {
                // console.log(json.length, json);
                setAltManifests(json);
                // setAltManifestCount(json.length);
            });
    }, [altfilters]);


    useEffect(() => {
        setFilters(s => ({ ...s, page: 0 }));
    }, [activeFilters])

    useEffect(() => {
        if (downloadUrl) { 
          window.location.href = downloadUrl;
          setDownloadUrl(null);
        } 
    }, [downloadUrl]);

    // function handleOnHoverChange(index, hover) { 
    //     setHoverIndex((hover) ? index : null); 
    // }

    // function handleCompleteShipment(index) {
    //     setManifests(s => {
    //         s[index].eventData['Date Shipped'] = Date(); 
    //         s[index].eventData.complete = true; 
    //         updateShippingDocument(s[index].id, s[index].eventData);
    //         return [...s];
    //     }); 
    // }

    // function CustomThemeButton({children, primary, secondary, hover, text, staticColor }) {
    //     const [isHover, setHover] = useState(false);
    //     const [click, setClick] = useState(false);
    //     return (
    //       <div 
    //         onMouseEnter={() => {
    //           setHover(true);
    //         }}
    //         onMouseLeave={() => {
    //           setHover(false);
    //         }}
    //         onMouseDown={() => {
    //           setClick(true);
    //         }}
    //         onMouseUp={() => {
    //           setClick(false);
    //         }}
    //         style={{
    //           borderRadius: '4px', 
    //           width: '100%', 
    //           height: '28px',  
    //           backgroundColor: (staticColor) ? (primary || colors.G300) : ((click) ? (secondary || colors.G400) : ((isHover) ? (hover || colors.G200) : (primary || colors.G300))),
    //           paddingTop: '8px',
    //           paddingLeft: '14px',
    //           paddingRight: '14px',
    //           color: text || 'white' ,
    //         //   cursor: 'pointer',
    //         }}>
    //         {children && <Typography style={{fontWeight: 550}} variant="subtitle2">{children}</Typography>}
    //       </div>
    //     );
    // } 

    function handleDocumentCreate(created) {
        if (!created) {
            console.log('Canceled creation');
            setModalOpen(false);
            return;
        }
        setOverrideSave(true);   
        console.log(local);
        createDocument({...document, createdBy: local}) 
        .then((resp) => {
          console.log(resp);
          if (resp.result === 'SUCCESS') {
            setDocument(resp.detail);
            window.location.href = '/browse/' + resp.detail.id;
          }
          setModalOpen(false);
        })
        .catch((error) => {
          console.log(error);
          setModalOpen(false);
        });
    }

    // useEffect(() => {
    //   if (overrideSave === true) { 
     
    //   }
    // }, [overrideSave]);

    function _onBreadcrumbItemClicked(ev, item) {
      switch (item.key) {
        case 'document':   
          window.location.href = item.to;
          break;
        case 'browse': 
          window.location.href = item.to;
          break;
        case 'app':
          window.location.href = item.to;
          break;
        case 'home':
          window.location.href = item.to;
          break;
        default: 
          break;
      } 
    } 

    return (
        <AppHeader hide={hideFrame} appSelected={'Operations'}>
            <ModalBasicExample title="New shipment" open={modalOpen} onClose={handleDocumentCreate}>
                <CreateJob 
                  overrideSave={overrideSave}
                  isModal={true} 
                  document={document} 
                  setDocument={setDocument} 
                  saveDocument={() => {
                    // console.log('Saving...', document);
                  }}/>
            </ModalBasicExample>
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
              {!hideFrame && <div style={{marginBottom: '16px', width: '100%'}}> 
                <Breadcrumb
                  items={[
                    { to: '/', text: 'Home', key: 'home', onClick: _onBreadcrumbItemClicked }, 
                    { to: '/browse/JOB', text: 'Operations', key: 'browse', onClick: _onBreadcrumbItemClicked },
                  ]}
                  maxDisplayedItems={2}
                  ariaLabel="Breadcrumb"
                  overflowAriaLabel="More links"
                /> 
              </div> }
                <Stack horizontal styles={{ root: { height: 44, width: '942px' } }}>
                    <CommandBarButton
                        iconProps={{ iconName: 'AddToShoppingList' }}
                        text="Create"  
                        onClick={() => {setModalOpen(true)}}
                        /> 
                    <Stack tokens={{ childrenGap: 20 }} style={{minWidth:'100px', marginLeft: '8px', marginTop: '6px'}}>
                        <SearchBox 
                            styles={{ root: { width: 200 } }}
                            placeholder="Filter" 
                            iconProps={{ iconName: 'Filter' }}
                            onChange={(ev, newValue) => {setFileSearchValue(newValue.toLowerCase());}} 
                            onSearch={newValue => {setFileSearchValue(newValue.toLowerCase());}} />
                    </Stack> 
                </Stack> 
                {manifests.length === 0 && <AllEmptyPage/>}
                {manifests.length > 0 && <MSTable filter={fileSearchValue} label={'Planning'} inputItems={manifests} setInputItems={setManifests}/>} 
                <div style={{marginTop: '20px'}}/>
                <MSTable filter={fileSearchValue} divider label={'Active'} inputItems={altmanifests} setInputItems={setAltManifests}/>
            </div> 
         </AppHeader>
    );
 
    // return (
    //     <div>
    //         {/* <Header heading="Manifests" subheading="View All" /> */}
    //         <div>
    //             <CustomTable
    //                 data={manifests}
    //                 selectedFields={selectedFields}
    //                 selected={selected}
    //                 setSelected={setSelected}
    //                 filters={filters}
    //                 setFilters={setFilters}
    //                 count={manifestCount}
    //                 variant="shipment"> 
    //                 <TableToolbar
    //                     title=""
    //                     selected={selected}>
    //                     {selected.length > 0 ?
    //                         <>
                                
    //                             <Menu
    //                                 id="edit-menu"
    //                                 anchorEl={anchor}
    //                                 keepMounted
    //                                 open={Boolean(anchor)}
    //                                 onClose={handleClose}>
    //                                 <MenuItem onClick={handleMenuClick} name="retire">Retire Assets</MenuItem>
    //                                 <MenuItem onClick={handleMenuClick} name="groupTag">Change Group Tag</MenuItem>
    //                                 <MenuItem onClick={handleMenuClick} name="assignee">Reassign</MenuItem>
    //                                 <MenuItem onClick={handleMenuClick} name="owner">Change Owner</MenuItem>
    //                                 <MenuItem onClick={handleMenuClick} name="assignmentType">Change Assignment Type</MenuItem>
    //                             </Menu>
    //                         </>
    //                         :
    //                         <> 
    //                             <Link to="/shipments/create" >
    //                                 <AtlasButton  size="large" appearance="primary">Create</AtlasButton>
    //                             </Link>
    //                             <Container className='searchBar' align='right'>
    //                                 <div >
    //                                     <TextField id="searchBox"
    //                                         variant="outlined"
    //                                         size="small"
    //                                         InputProps={{
    //                                             startAdornment: (
    //                                                 <InputAdornment position="start">
    //                                                     <SearchIcon />
    //                                                 </InputAdornment>
    //                                             )
    //                                         }}
    //                                         onKeyDown={handleKeyDown}
    //                                     />
    //                                 </div>
    //                             </Container>
    //                             <IconButton onClick={() => setDialogs(s => ({ ...s, filter: true}))}>
    //                                     <FilterListIcon />
    //                                 </IconButton>
    //                         </>
    //                     }
    //                 </TableToolbar>

    //                 {/* Chips representing all the active filters */}
    //                 <ChipBar
    //                     activeFilters={activeFilters}
    //                     setActiveFilters={setActiveFilters}
    //                     setFilters={setFilters} />

    //             </CustomTable>

    //         </div>
    //        { /*put manifest filter here*/}
    //        <ShipmentFilter open={dialogs["filter"]} setOpen={(isOpen) => setDialogs(d => ({ ...d, filter: isOpen }))} setActiveFilters={setActiveFilters} />
           
            
    //         {/* Displays success or failure message */}
    //         <Snackbar open={success.succeeded !== null} autoHideDuration={5000} onClose={() => setSuccess({ succeeded: null, message: '' })} anchorOrigin={{ vertical: "top", horizontal: "center" }} style={{ boxShadow: "1px 2px 6px #5f5f5f", borderRadius: "3px" }}>
    //             <Alert onClose={() => setSuccess({ succeeded: null, message: '' })} severity={success.succeeded ? "success" : "error"}>
    //                 {success.message}
    //             </Alert>
    //         </Snackbar>
    //     </div>);

}

// function CardWrapper({children, style, className}) {
//     return (
//       <div className={className} style={Object.assign({
//         border: '1px solid #DFE1E6',
//         borderRadius: '4px',
//         backgroundColor: "white",
//         }, style)}>
//           {children}
//       </div>
//     )
//   }

// function FocusItem(props) {
//     const { children, enabled, onHoverChanged, disableFocus, scaleHover, scaleTap, shadowHover, shadowTap, style } = props;
//     if (disableFocus) {
//         return (
//           <CardWrapper>
//             {children}
//           </CardWrapper>
//         )
//     }
//     return (
//         <motion.div 
//             style={Object.assign({
//                 zIndex: 0,
//                 margin: '0px 20px',
//                 borderRadius: '2px',  
//                 // padding: '0px 4px',
//                 // border: '1px solid #DFE1E6', 
//                 backgroundColor: "white",
//             }, style)}
//             onHoverStart={() => {if (onHoverChanged) onHoverChanged(true);}}
//             onHoverEnd={() => {if (onHoverChanged) onHoverChanged(false);}}
//             whileHover={{ 
//                 zIndex: 2,
//                 backgroundColor: '#F4F5F7',
//                 scale: (enabled) ? (scaleHover || 1.005) : (1.0), 
//                 boxShadow: (enabled) ? (shadowHover || "0px 2px 6px rgba(0,0,0,0.1)") : undefined, 
//             }}
//             whileTap={{
//                 zIndex: 2,
//                 scale: (enabled) ? (scaleTap || 1.01) : (1.0),
//                 boxShadow: (enabled) ? (shadowTap || "0px 2px 10px rgba(0,0,0,0.35)") : undefined, 
//             }}> 
//             {children}
//         </motion.div>
//     )
// }

export default AllJobs;


const dragOptions = {
    moveMenuItemText: 'Move',
    closeMenuItemText: 'Close',
    menu: ContextualMenu,
  };
  const cancelIcon = { iconName: 'Cancel' };
  
  const ModalBasicExample = ({title, children, id, open, onClose}) => {
    // const [isModalOpen, { setTrue: showModal, setFalse: hideModal }] = useBoolean(false);
    // const [isDraggable, { toggle: toggleIsDraggable }] = useBoolean(false);
 
    // Use useId() to ensure that the IDs are unique on the page.
    // (It's also okay to use plain strings and manually ensure uniqueness.)
    const titleId = useId(id || 'title');
  
    return (
      <div> 
        <Modal
          titleAriaId={titleId}
          isOpen={open}
          onDismiss={() => onClose(false)}
          isBlocking={false}
          containerClassName={contentStyles.container}
        //   dragOptions={isDraggable ? dragOptions : undefined}
        >
          <div className={contentStyles.header}>
            <span id={titleId}>{title}</span>
            <IconButton
              styles={iconButtonStyles}
              iconProps={cancelIcon}
              ariaLabel="Close popup modal"
              onClick={() => onClose(false)}
            />
          </div>
          <div className={contentStyles.body}>
            {children}
          </div>
          <div style={{backgroundColor: '#F9F9F9', height: '56px', paddingTop: '12px', paddingRight: '12px', width: '100%', position: 'absolute', bottom: 0, right: 0}}>
            <Stack horizontal tokens={{ childrenGap: 5 }} styles={{ root: { height: 44, display: 'flex', justifyContent: 'flex-end' } }}>
              <DefaultButton onClick={() => onClose(false)} text="Cancel" /> 
              <DefaultButton primary onClick={() => onClose(true)} text="Create" /> 
            </Stack>
          </div>
        </Modal>
      </div>
    );
  };
  
  const theme = getTheme();
  const contentStyles = mergeStyleSets({
    container: {
      display: 'flex',
      flexFlow: 'column nowrap',
      alignItems: 'stretch',
    },
    header: [ 
      theme.fonts.xLargePlus,
      {
        flex: '1 1 auto',
        borderTop: `4px solid ${theme.palette.themePrimary}`,
        color: theme.palette.neutralPrimary,
        display: 'flex',
        alignItems: 'center',
        fontWeight: FontWeights.semibold,
        padding: '12px 12px 14px 24px',
      },
    ],
    body: {
      flex: '4 4 auto',
      padding: '0 24px 24px 24px',
      overflowY: 'hidden',
      selectors: {
        p: { margin: '14px 0' },
        'p:first-child': { marginTop: 0 },
        'p:last-child': { marginBottom: 0 },
      },
    },
  });
  const toggleStyles = { root: { marginBottom: '20px' } };
  const iconButtonStyles = {
    root: {
      color: theme.palette.neutralPrimary,
      marginLeft: 'auto',
      marginTop: '4px',
      marginRight: '2px',
    },
    rootHovered: {
      color: theme.palette.neutralDark,
    },
  };
  