import React, { useEffect, useState } from 'react'; 

import styled from 'styled-components';

// Components
import { CustomAppBar } from '../components/UserProfilePopper'; 
import { AppConfig } from '../utils/config.utils';
import ReactJson from 'react-json-view';
import { 
    CustomTextField,
    CustomPickerField
  } from '../components/CustomFields';
import { makeStyles } from '@material-ui/core/styles'; 
import { Label } from '@fluentui/react';
import { Button, Chip, Divider, InputBase, Typography } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

// Utilities
const queryString = require('query-string');
const axios = require('axios');
const moment = require('moment');

const axiosInstance = axios.create({
    baseURL: `${AppConfig.PUBLIC_API_URL}/api/latest`, 
    headers: {'Content-Type': 'application/json'}
});

async function updateDocument(product) {
    return new Promise((resolve, reject) => { 
        axiosInstance.put(`/product?code=${AppConfig.PUBLIC_API_CODE}`, product)
        .then(function (response) { 
            resolve(response.data);
        })
        .catch(function (error) { 
            reject(error);
        });
    });
}

async function getDocument(productId) {
    return new Promise((resolve, reject) => { 
        axiosInstance.get(`/product?id=${productId}&code=${AppConfig.PUBLIC_API_CODE}`, {})
        .then(function (response) { 
            resolve(response.data);
        })
        .catch(function (error) { 
            reject(error);
        });
    });
}

async function getChildDocuments(productId) {
    return new Promise((resolve, reject) => { 
        axiosInstance.get(`/product?parentId=${productId}&code=${AppConfig.PUBLIC_API_CODE}`, {})
        .then(function (response) { 
            resolve(response.data);
        })
        .catch(function (error) { 
            reject(error);
        });
    });
}

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: '#FFFFFF',
        border: 'solid 1px #E0E0E0',
        height: '100%',
        textAlign: 'left',
        borderRadius: '6px',
        // paddingTop: theme.spacing(1),
        // paddingLeft: theme.spacing(2), 
        // paddingRight: theme.spacing(2), 
        // paddingBottom: theme.spacing(2), 
    }, 
    jsonView: {
        backgroundColor: '#002B36',
        padding: '12px', 
        textAlign: 'left', 
        marginTop: '16px', 
        borderRadius: '6px'
    }
})); 

export default function ProductsPage(props) {
    const classes = useStyles();  
    const [data, setData] = useState({});

    function handleSetData(update) {
        // console.log(update);
        setData({...update});
        updateDocument(data) 
        .then((result) => {
            console.log(result)
        })
        .catch(console.log);
    }

    return (
        <DynamicCardViewerBase data={data} setData={setData} {...props}>  
            <ProductCard data={data} setData={handleSetData}/>
        </DynamicCardViewerBase>
    )
}

function ProductCard({data, setData, bannerText, bannerType}) {
    return (
        <div style={{padding: '12px'}}> 
            {bannerText && <Alert severity={bannerType || "info"}>
                {bannerText}
            </Alert>}
            {data && data.id && 
            <>
                <div style={{ width: '100%' }}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexGrow: 1 }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Label style={{ fontSize: '24px', fontWeight: 600 }}>{data.serial}</Label>
                            <div style={{ width: '16px' }}/>
                            <Chip clickable style={{fontFamily: 'Segoe UI' }} label={data.productTypeName} style={{ backgroundColor: '#2684FF', color: '#FFFFFF',  fontWeight: 600, borderRadius: '4px' }}/>
                            <div style={{ width: '8px' }}/>
                            {data.assignment==='rental' && <Chip clickable style={{fontFamily: 'Segoe UI' }} label="Rental" style={{ backgroundColor: '#FFF0B3', color: '#FF8B00',  fontWeight: 600, borderRadius: '4px' }}/>}
                        </div>
                        <div style={{display: 'flex'}}>  
                            {data.checkedOut===true && 
                            <>
                                <div style={{ width: '8px' }}/>
                                <Alert style={{padding: "4px 12px"}} severity="warning">
                                    CHECKED OUT
                                </Alert>
                            </>
                            }
                            {data.checkedOut===false && 
                            <>
                                <div style={{ width: '8px' }}/>
                                <Alert style={{padding: "4px 12px"}} severity="success">
                                    CHECKED IN
                                </Alert>
                            </>
                            }
                            {data.retired && 
                            <>
                                <div style={{ width: '8px' }}/>
                                <Alert style={{padding: "4px 12px"}} severity="error">
                                    RETIRED
                                </Alert>
                            </>
                            }
                        </div>
                    </div>
                </div> 
                <div style={{width: '100%', marginTop: '8px'}}>
                    <Divider style={ {marginBottom: '8px' }}/> 
                    <div>
                        <Alert severity="info">
                            Created by {data.createdBy.displayName} on {moment(data.created).format('MMM D, yyyy')} and was last updated on {moment(data.updated).format('MMM D, yyyy')}.
                        </Alert>
                        <div style={{ height: '16px' }}/>
                        <Autocomplete
                            multiple
                            freeSolo
                            size="small"
                            id="tags-outlined"
                            options={groupTagList}
                            autoHighlight
                            autoSelect
                            getOptionLabel={(option) => option}
                            defaultValue={data.groupTag}
                            filterSelectedOptions
                            onChange={(event, change) => {
                                data.groupTag = change;
                                data.updated = moment().format();
                                setData(data);
                            }}
                            renderInput={(params) => ( 
                                <TextField 
                                    {...params}
                                    variant="outlined" 
                                    placeholder="Add a product tag..."
                                />
                            )}
                        />
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: '12px' }}>  
                            <CustomTextField 
                                vertical 
                                label="Location" 
                                placeholder="N/A" 
                                value={data.location} 
                                setValue={(value) => {
                                    data.location = value;
                                    data.updated = moment().format();
                                    setData(data);
                                }}/>   
                            <CustomTextField 
                                vertical 
                                label="Owned By" 
                                placeholder="N/A" 
                                value={data.owner} 
                                setValue={(value) => {
                                    data.owner = value;
                                    data.updated = moment().format();
                                    setData(data);
                                }}/>   
                            <CustomTextField 
                                vertical 
                                label="Assigned To" 
                                placeholder="Not allocated" 
                                value={data.assignee} 
                                setValue={(value) => {
                                    data.assignee = value;
                                    data.updated = moment().format();
                                    setData(data);
                                }}/>   
                        </div> 
                    </div>
                </div>
                </>
                }
            
        </div>
    )
}

const groupTagList = [
    'Premier Rental Pool',
    'Premier Rental Pool',
    'Baker-USA Rental Pool',
    'Baker-CAN Rental Pool',
]

function DynamicCardViewerBase(props) {
    const classes = useStyles();

    const { x_jjv } = queryString.parse(props.location.search);
    const { children, data, setData }  = props;
    const { id } = props.match.params; 

    useEffect(() => {
        if (id && id.length > 0) {
            getDocument(id) 
            .then((result) => {  
                getChildDocuments(id) 
                .then((ch) => {
                    if (ch) {
                        if (ch.result === 'ERROR') {
                            // console.log(ch);
                        } else {
                            result.children = [...ch];
                            console.log(result.children)
                        }
                    } 
                    if (setData) setData(result);
                })
                .catch((error) => {
                    // console.log(error);
                    if (setData) setData(result);
                }) 
            })
            .catch(console.log);
        } else {
            console.log('Invalid key format')
        }
    }, [id]);

    return (
        <CustomAppBar> 
                <div className={classes.root}>  
                    {children} 
                </div>
            {x_jjv==='true' && 
                <div className={classes.jsonView}>
                    <ReactJson 
                        src={data}
                        theme="solarized"/>
                </div>
            }
        </CustomAppBar>
    )
}