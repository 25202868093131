import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import WorkIcon from '@material-ui/icons/Work';
import { AppContext, AppContextState } from '../contexts/AppContext'; 
import Popper from '@material-ui/core/Popper';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Fade from '@material-ui/core/Fade';
import Paper from '@material-ui/core/Paper';
import AppsIcon from '@material-ui/icons/Apps';
import NotificationsIcon from '@material-ui/icons/Notifications';
import useLocalStorage from '../utils/auth/useLocalStorage.hook';
import { colors } from '@atlaskit/theme'
import LocalShippingIcon from "@material-ui/icons/LocalShipping"; 
import BuildIcon from '@material-ui/icons/Build';
import FavoriteIcon from '@material-ui/icons/Favorite';
import EmojiObjectsIcon from '@material-ui/icons/EmojiObjects';
import Badge from '@material-ui/core/Badge';
import { makeStyles, withStyles } from '@material-ui/core/styles'
import ControlCameraIcon from '@material-ui/icons/ControlCamera';
import CustomCoachMark from './CustomCoachMark';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import {
    // Checkbox,
    Persona, 
    PersonaSize, 
    PersonaPresence, 
    DefaultButton,
    // Panel, 
    // PanelType,
    // PersonaInitialsColor,  
    // TooltipHost,
    // Stack,
    // Icon,
    CommandButton
} from '@fluentui/react'; 
import {
    userAllPresences, 
    userProfilePhoto, 
} from '../utils/graph';
import SearchBar from './Searchbar/Searchbar';
// import { SignOut, SignIn } from '../AuthProvider';
import { Typography } from '@material-ui/core';

// import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar'; 
import CssBaseline from '@material-ui/core/CssBaseline';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
// import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';

function ElevationScroll(props) {
    const { children, window } = props;
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({
      disableHysteresis: true,
      threshold: 0,
      target: window ? window() : undefined,
    });
  
    return React.cloneElement(children, {
      elevation: trigger ? 4 : 0,
    });
  }

export function CustomAppBar(props) {
    return (
      <React.Fragment>
        <CssBaseline />
        <ElevationScroll {...props}>
          <AppBar style={{paddingTop: '6px', paddingBottom: '6px', color: 'inherit', backgroundColor: '#F4F5F7'}}>
            <UserProfilePopper/> 
          </AppBar>
        </ElevationScroll>
        <Toolbar />
        <Container>
            {props.children}
        </Container>
      </React.Fragment>
    );
  }

const appIconSize = '50px';
const appIconFontSize = '12px';

const StyledBadge = withStyles((theme) => ({
    badge: {
        zIndex: 3,
        right: 42,
        top: 0,
        height: '24px',
        width: '24px',
        border: `2px solid ${theme.palette.background.paper}`,
    //   padding: '0 4px',
    },
  }))(Badge);

const useStyles = makeStyles((theme) => ({
    moduleHeader: {
        marginTop: "40px",
        flexGrow: "1",
        alignSelf: "center"
    },
    moduleName: {
        color:"#2E474E",
        flexGrow: "1",
        marginTop: '10px', 
        fontFamily: 'Segoe UI',
        fontWeight: 400,
        fontSize: appIconFontSize,
    },
    moduleIcon1: {
        fontSize: appIconSize,
        padding: "10px",
        marginLeft: "40px",
        marginRight: "40px",
        backgroundColor: colors.T300,
        color: "white",
        borderRadius: "18px",
        // border: "1px solid #2E474E",
        // boxShadow: "2px 2px 3px #888888"
    },
    moduleIcon2: {
        fontSize: appIconSize,
        padding: "10px",
        marginLeft: "40px",
        marginRight: "40px",
        backgroundColor: colors.G300,
        color: "white",
        borderRadius: "18px",
        // border: "1px solid #2E474E",
        // boxShadow: "2px 2px 3px #888888"
    },
    moduleIcon3: {
        fontSize: appIconSize,
        padding: "10px",
        marginLeft: "40px",
        marginRight: "40px",
        backgroundColor: colors.R300,
        color: "white",
        borderRadius: "18px",
        // border: "1px solid #2E474E",
        // boxShadow: "2px 2px 3px #888888"
    },
    moduleIcon4: {
        fontSize: appIconSize,
        padding: "10px",
        marginLeft: "40px",
        marginRight: "40px",
        backgroundColor: colors.P300,
        color: "white",
        borderRadius: "18px",
        // border: "1px solid #2E474E",
        // boxShadow: "2px 2px 3px #888888"
    },
    moduleIcon5: {
        fontSize: appIconSize,
        padding: "10px",
        marginLeft: "40px",
        marginRight: "40px",
        backgroundColor: 'white',
        border: `1px solid ${colors.Y300}`,
        color: colors.Y300,
        borderRadius: "18px",
        // border: "1px solid #2E474E",
        // boxShadow: "2px 2px 3px #888888"
    },
    Modules: {
        // display: "flex", 
        // flex: 1,
        // width: '100%', 
    },
    moduleContainer: {
    },
    link: {
        textDecoration: "none",
        cursor: "pointer",
        margin: 12,
    },
    title: {
        fontWeight: 550,
        fontFamily: 'Segoe UI',
        marginLeft: '4px'
    },
    divider: {
        marginLeft: theme.spacing(2),
        height: '32px',
        marginTop: '8px',
    },
    root: {
        display: 'flex',
        justifyContent: 'flex-end',
        paddingTop: theme.spacing(0.2),
        paddingRight: theme.spacing(2),
        // marginTop: theme.spacing(-2),
        // marginRight: theme.spacing(-1.5),
        // width: 500,
    },
    root2: {
        width: '100%', 
        // display: 'flex', 
        // paddingTop: theme.spacing(0),
        // minWidth: '300px',
        // maxWidth: '500px',
        // flex: 1
        // paddingRight: theme.spacing(2)
    },
    root3: {
        display: 'flex', 
        paddingTop: theme.spacing(0),
        paddingLeft: theme.spacing(2),
    },
    appContent: {
        width: '275px', 
        height: '325px',
        padding: theme.spacing(2),
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center',  
        alignContent: 'center', 
        justifyContent: 'center',
        justifyItems: 'center', 
    },
    typography: {
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center',  
        width: '325px',
        padding: theme.spacing(2),
    },
    Modules: {
        display: 'flex',
        flexWrap: 'wrap',
        width: '350px', 
        height: '250px',
        padding: theme.spacing(2),   
        flexDirection: 'column',  
        justifyContent: 'flex-start',
        textAlign: 'center',
    }
}));

function CardWrapper({children, style, className}) {
    return (
      <div className={className} style={Object.assign({
        // border: '1px solid #DFE1E6',
        // borderRadius: '4px',
        // backgroundColor: "white",
        }, style)}>
          {children}
      </div>
    )
  }

function Image({src, alt, size, width, height}) {
    return (
      <img src={src} alt={alt} width={size || width} height={size || height}/>
    )
}

function ProductHomeTitle({hideDivider, onClick}) {
    const classes = useStyles(); 
    return (
      <div 
        style={{
            display: 'flex', 
            alignItems: 'center', 
            width: '185px'}}>
        <IconButton  
          size="small"
          edge="start" 
          onClick={onClick}
          className={classes.menuButton2} 
          color="inherit" 
          aria-label="menu">
          <Image size='38px' src='/compass.png' alt='logo'/>
        </IconButton> 
        <Typography variant="subtitle1" className={classes.title}>
          Evolution Portal
        </Typography>
        {!hideDivider && <Divider 
          className={classes.divider} 
          flexItem  
          orientation='vertical'/> }
      </div>
    );
} 

export default function UserProfilePopper() {   
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [open, setOpen] = React.useState(false);
    const [open2, setOpen2] = React.useState(false);
    const [placement, setPlacement] = React.useState('bottom-end');
    const applicationState = useContext(AppContextState)
    const [persona, setPersona] = useState({}); 
    const classes = useStyles();

    useEffect(() => {
        function extractInitials(name) {
            var initials = '?';
            if (name) {
                var init = name.split(' ');
                if (init.length > 0) initials = init[0][0];
                if (init.length > 1) initials += init[init.length - 1][0];
                initials = initials.toUpperCase();
            }
            return initials;
        } 
    
        function convertStatusToPresence(availability) {
            switch (availability) {
                case "Available": return PersonaPresence.online;
                case "Offline": return PersonaPresence.offline;
                case "Busy": return PersonaPresence.busy;
                case "InAMeeting" : return PersonaPresence.dnd;
                case "InACall": return PersonaPresence.dnd;
                case 'Away': return PersonaPresence.away;
                case "PresenceUnknown": return PersonaPresence.blocked;
                default: 
                    return PersonaPresence.none;
            }
        }

        function formatPersona(profile) { 
            var secondary = (profile.presence) ? profile.presence.activity : undefined;
            if (secondary === 'PresenceUnknown') secondary = 'Not available';
            else if (secondary === 'InAMeeting') secondary = 'In a meeting';
            else if (secondary === 'InACall') secondary = 'On a call';
            var presTitle = (profile.presence) ? profile.presence.availability : undefined;
            if (presTitle === 'PresenceUnknown') presTitle = 'Not available';
            else if (presTitle === 'InAMeeting') presTitle = 'In a meeting';
            else if (presTitle === 'InACall') presTitle = 'On a call'; 
            const presenceText = convertStatusToPresence((profile.presence) ? profile.presence.availability : undefined);
            const r = {
                presence: presenceText,
                secondaryText: profile.jobTitle || ((profile.presence) ? profile.presence.availability : undefined), 
                presenceTitle: presTitle,
                id: profile.userId,
                imageUrl: profile.imageUrl,
                text: profile.displayName, 
                imageInitials: extractInitials(profile.displayName),
            }; 
            return r;
        }

        async function loadPersona() {
            // console.log(applicationState.user)
            const _persona = {
                id: applicationState.user.id,
                displayName: applicationState.user.displayName, 
                jobTitle: (applicationState.user) ? applicationState.user.jobTitle : undefined,
            };
            await userAllPresences(applicationState.user.accessToken, [_persona.id])
            .then((presence) => {
                if (presence && presence.value && presence.value.length > 0) presence = presence.value[0];
                else presence = {}; 
                _persona.presence = presence;
            })
            .catch((error) => console.log(error));
            await userProfilePhoto(applicationState.user.accessToken, _persona.id)
            .then((pp) => {
                if (pp) { 
                    const url = window.URL || window.webkitURL;
                    const blobUrl = url.createObjectURL(pp);
                    pp = blobUrl;
                } 
                _persona.imageUrl = pp;  
            })
            .catch((error) => console.log(error));
            return formatPersona(_persona);
        }
        
        loadPersona()
        .then(result => setPersona(result))
        .catch(error => console.log(error));
    }, [applicationState.user]); 

    function openInNewWindow(url) {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    }

    return ( 
        <div style={{display: 'flex', justifyContent: 'space-between'}}>
            <div className={classes.root3}>
                <ProductHomeTitle onClick={() => {window.location.href = '/'}}/>
            </div>
            <div className={classes.root2}>
                <SearchBar/>
            </div>
            <div className={classes.root}>
                <CommandButton onClick={() => {openInNewWindow('https://outlook.office.com/')}}>Mail</CommandButton>
                <div style={{width:'8px'}}/>
                <CommandButton style={{ width: '138px' }} onClick={() => {openInNewWindow('https://evoi.sharepoint.com/sites/info_ctr/default.aspx')}}>Information Center</CommandButton>
                <div style={{width:'8px'}}/> 
                <CommandButton onClick={() => {openInNewWindow('https://evolutioneng.atlassian.net/servicedesk/customer/portal/28/group/56/create/230')}}>Support</CommandButton>
                <div style={{width:'8px'}}/>
                <CommandButton style={{ width: '100px' }} onClick={() => {openInNewWindow('https://apps.powerapps.com/play/55c053c3-324f-485c-89c9-5ff3850a871e?source=portal&screenColor=rgba(0%2C%2099%2C%20177%2C%201)')}}>ERP Check-In</CommandButton>
                <div style={{width:'8px'}}/> 
                <IconButton style={{width: '40px', height: '40px'}}  size="small" >
                    <NotificationsIcon/>
                </IconButton>
                <div style={{width:'8px'}}/>
                <ClickAwayListener onClickAway={() => {setOpen2(false)}}>
                    <div>
                        <Popper style={{zIndex: 100,}} open={open2} anchorEl={anchorEl} placement={placement} transition>
                            {({ TransitionProps }) => (
                            <Fade {...TransitionProps} timeout={150}>
                                <Paper elevation={1} style={{marginTop: '8px'}}>
                                    <AppContent/>
                                </Paper>
                            </Fade>
                            )}
                        </Popper> 
                        {/* <CustomCoachMark title="Apps have moved!" description="Just wanted to let you know that apps have moved to the app drawer."> */}
                            <IconButton 
                                style={{width: '40px', height: '40px'}} 
                                size="small" 
                                onClick={(event) => {
                                    setAnchorEl(event.currentTarget);
                                    setOpen2(true); 
                                }}>
                                <AppsIcon/>
                            </IconButton>
                        {/* </CustomCoachMark> */}
                    </div>
                </ClickAwayListener> 
                <div style={{width:'8px'}}/>
                <ClickAwayListener onClickAway={() => {setOpen(false)}}>
                    <div>
                        <Popper style={{zIndex: 100,}} open={open} anchorEl={anchorEl} placement={placement} transition>
                        {({ TransitionProps }) => (
                        <Fade {...TransitionProps} timeout={150}>
                                <Paper elevation={1} style={{marginTop: '8px'}}>
                                    <PopperContent persona={persona}/>
                                </Paper>
                        </Fade>
                        )}
                        </Popper> 
                        <IconButton 
                            style={{width: '40px', height: '40px'}} 
                            size="small" 
                            onClick={(event) => {
                                setAnchorEl(event.currentTarget);
                                setOpen(true); 
                            }}>
                            <Persona size={PersonaSize.size32} {...persona}/>
                        </IconButton> 
                    </div>
                </ClickAwayListener> 
            </div>
        </div> 
    );
}


function FocusItem(props) {
    const { children, enabled, onHoverChanged, disableFocus, scaleHover, scaleTap, shadowHover, shadowTap, style } = props;
    if (disableFocus) {
        return (
          <CardWrapper>
            {children}
          </CardWrapper>
        )
    }
    return (
        <motion.div 
            style={Object.assign({
                zIndex: 0, 
            }, style)}
            onHoverStart={() => {if (onHoverChanged) onHoverChanged(true);}}
            onHoverEnd={() => {if (onHoverChanged) onHoverChanged(false);}}
            whileHover={{ 
                zIndex: 2, 
                scale: (enabled) ? (scaleHover || 1.05) : (1.0),  
            }}
            whileTap={{
                zIndex: 2,
                scale: (enabled) ? (scaleTap || 1.1) : (1.0),  
            }}> 
            {children}
        </motion.div>
    )
}
 
function AppContent({}) {
    const classes = useStyles();
    const [hoverIndex, setHoverIndex] = React.useState(null);
 
    function getAppCardStyle(color, hover) {
        // console.log(hover);
        
        // backgroundColor: 'white',
        // border: `1px solid ${colors.Y300}`,
        // color: colors.Y300,

        const st = {
            fontSize: appIconSize,
            // padding: "10px",
            // margin: "10px",
            // marginRight: "40px",
            // backgroundColor: 'white',
            // border: `1px solid ${color}`, 
            color: color,
            // borderRadius: "8px",
            // boxShadow: (hover) ? `0 0px 5px 1px ${colors.N300}DD` : `0 0px 3px 2px ${colors.N300}66`,
            transition: 'box-shadow 0.15s ease-in-out'
        }
        return st;
    }

    function getAppCardIcon(app, hover) {
        switch (app) {
            case 'My Workspace': return (<WorkIcon style={getAppCardStyle(colors.N300, hover)}/>);
            case 'AMO Center': return (<BuildIcon style={getAppCardStyle(colors.T300, hover)}/>);
            case 'Health & Safety': return (<FavoriteIcon style={getAppCardStyle(colors.G300, hover)}/>);
            case 'RMA Center': return (<ControlCameraIcon style={getAppCardStyle(colors.R300, hover)}/>);
            case 'Logistics': return (<LocalShippingIcon style={getAppCardStyle(colors.P300, hover)}/>);
            case 'Solution Center': return (<EmojiObjectsIcon style={getAppCardStyle(colors.Y300, hover)}/>);
            case 'Products': return (<AccountTreeIcon style={getAppCardStyle(colors.N300, hover)}/>);
        }
    }

    function navigateToLink(url) {
        // window.location.href = link;
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
        if (newWindow) newWindow.opener = null
    } 

    const [appTable, setAppTable] = React.useState([
        // { name: 'My Workspace', eventCount: 0,  eventType:'Task', link: '/apps/dashboard', icon: (<LocalShippingIcon style={getAppCardStyle(colors.P300)}/>) },
        { name: 'AMO Center', eventCount: 0, external: () => navigateToLink('https://evolutioneng.atlassian.net/browse/AMO'), icon: (<BuildIcon style={getAppCardStyle(colors.T300)}/>) },
        { name: 'Health & Safety', eventCount: 0, external: () => navigateToLink('https://evolutioneng.atlassian.net/servicedesk/customer/portal/30'), icon: (<FavoriteIcon style={getAppCardStyle(colors.G300)}/>) },
        { name: 'RMA Center', eventCount: 0, external: () => navigateToLink('https://evolutioneng.atlassian.net/browse/RMA'), icon: (<ControlCameraIcon style={getAppCardStyle(colors.R300)}/>) },
        { name: 'Logistics', eventCount: 0,  eventType:'Shipment', link: '/browse/SHIP', icon: (<LocalShippingIcon style={getAppCardStyle(colors.P300)}/>) },
        { name: 'Solution Center', eventCount: 0, external: () => navigateToLink('https://evolutioneng.atlassian.net/browse/TSDI'), icon: (<EmojiObjectsIcon style={getAppCardStyle(colors.Y300)}/>) },
        { name: 'Products', eventCount: 0,  eventType:'Products', link: '/browse/products', icon: (<AccountTreeIcon style={getAppCardStyle(colors.N300)}/>) },
    ]);

    function handleHoverChanged(hover, index) {
        if (hover) {
            setHoverIndex(index);
        } else {
            setHoverIndex(null);
        }
    }

    return (
        <Container className={classes.Modules}>
        {
            appTable.map((x, i) => {
                if (x.external) {
                    return (
                        <div onClick={() => x.external()} className={classes.link}> 
                            <FocusItem enabled onHoverChanged={(v) => handleHoverChanged(v, i)}>
                                {x.icon}
                                <Typography variant="h6" className={classes.moduleName}>{x.name}</Typography>
                            </FocusItem>
                        </div> 
                    )
                } else {
                    return (
                        <Link to={x.link} className={classes.link}> 
                            <FocusItem enabled onHoverChanged={(v) => handleHoverChanged(v, i)}>
                                <StyledBadge badgeContent={x.eventCount} color="error">  
                                    <div style={{}}>
                                        {getAppCardIcon(x.name, hoverIndex===i)} 
                                    </div> 
                                </StyledBadge>
                                <Typography variant="h6" className={classes.moduleName}>{x.name}</Typography>
                            </FocusItem>
                        </Link> 
                    )
                }
            })
        } 
        </Container>

        // <div className={classes.appContent}>
        //     <img src='/queue-empty.svg'/>
        //     <Typography style={{marginTop: '32px', fontFamily: 'Segoe UI'}}>Apps, apps, and more apps!</Typography>
        // </div>
    )
}

function PopperContent({persona}) { 
    const applicationState = useContext(AppContextState); 
    const applicationStateDispatcher = useContext(AppContext); 
    // const [local, setLocal] = useLocalStorage('user', {});  
    const classes = useStyles();

    return (
        <div className={classes.typography}>
            <Persona size={PersonaSize.size72} {...persona}/> 
            <Divider style={{width: '100%', margin: '16px 0px'}}/> 
            <div style={{display: 'flex', justifyContent: 'flex-end', width: '100%'}}>
                <DefaultButton 
                    onClick={async () => {  
                        if (applicationState.user && applicationState.user.id && applicationState.user.id.length > 0) {
                            applicationState.pca.logout();
                        } else { 
                            
                        }
                        applicationStateDispatcher({type: 'user/data', payload: {}});
                    }}>
                    {(applicationState.user && applicationState.user.id && applicationState.user.id.length > 0) ? "Sign out" : "Sign in"}
                </DefaultButton>
            {/* <Typography className={classes.typography}>The content of the Popper.</Typography> */}
            </div>
        </div> 
    )
}