import React from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import AppSwitcherIcon from '@atlaskit/icon/glyph/app-switcher';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import BuildIcon from '@material-ui/icons/Build';
import AlbumIcon from '@material-ui/icons/Album';
import GpsFixedIcon from '@material-ui/icons/GpsFixed';
import WorkIcon from '@material-ui/icons/Work'; 
import PollIcon from '@material-ui/icons/Poll';
import SettingsInputCompositeIcon from '@material-ui/icons/SettingsInputComposite';
import PageIcon from '@atlaskit/icon/glyph/page';
import EmojiSymbolsIcon from '@atlaskit/icon/glyph/emoji/symbols';
import MarketplaceIcon from '@atlaskit/icon/glyph/marketplace';
import EmojiObjectsIcon from '@material-ui/icons/EmojiObjects';
import NotificationsIcon from '@material-ui/icons/Notifications';
import HelpIcon from '@material-ui/icons/Help';
import SettingsIcon from '@material-ui/icons/Settings'; 
import Avatar from '@material-ui/core/Avatar';
import { colors } from '@atlaskit/theme'

const drawerWidth = 260;
const darkBackgroundColor = '#282C33';
const standardAppBarHeight = '56px';
const innerShadowProfile = 'inset -5px 0px 25px -10px #11111177';

function ListItemLink(props) {
  const linkRef = React.useRef(null);
  return ( 
    <Link style={{ textDecoration: 'none', color: 'inherit' }} ref={linkRef} to={props.href}>
      <ListItem dense button {...props} /> 
    </Link>
  );
}  

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    height: standardAppBarHeight, 
    backgroundColor: darkBackgroundColor,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    height: standardAppBarHeight, 
    backgroundColor: darkBackgroundColor,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36, 
    marginTop: '-7px',
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    // backgroundColor: 'white',
  },
  drawerOpen: {
    width: drawerWidth,
    boxShadow: innerShadowProfile,
    // backgroundColor: 'white',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    boxShadow: innerShadowProfile,
    // backgroundColor: 'white',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },

  drawer2: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen2: {
    width: drawerWidth, 
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose2: { 
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },


  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',  
    padding: theme.spacing(0, 1),
    height: standardAppBarHeight,
    // backgroundColor: darkBackgroundColor,
    // necessary for content to be below app bar
    // ...theme.mixins.toolbar,
  },
  contentUnderToolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end', 
    height: theme.spacing(5), 
  },
  content: {
    flexGrow: 1,
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginBottom: theme.spacing(2)
    // backgroundColor: 'white'
  },
  divider: {
      height: '100%', 
  }, 
  title: {
      fontWeight: 550,
      fontFamily: 'Segoe UI',
      marginLeft: '4px'
  }
}));

function Image({src, alt, size, width, height}) {
    return (
      <img src={src} alt={alt} width={size || width} height={size || height}/>
    )
}

function ProductHomeTitle({hideDivider, onClick}) {
    const classes = useStyles(); 
    return (
      <div style={{display: 'flex', alignItems: 'center'}}>
        <IconButton  
          size="small"
          edge="start" 
          onClick={onClick}
          className={classes.menuButton2} 
          color="inherit" 
          aria-label="menu">
          <Image size='38px' src='/compass.png' alt='logo'/>
        </IconButton> 
        <Typography variant="subtitle1" className={classes.title}>
          Evolution Portal
        </Typography>
        {!hideDivider && <Divider 
          className={classes.divider} 
          flexItem  
          orientation='vertical'/> }
      </div>
    );
} 

export default function AppHeader({children, appSelected, hide}) {
    const classes = useStyles();
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);
    const [selected, setSelected] = React.useState(null);
    const handleDrawerOpen = () => {
        setOpen(true);
    }; 
    const handleDrawerClose = () => {
        setOpen(false);
    };
    React.useEffect(() => {
        if (selected !== null) {
            window.location.href = selected;
            setSelected(null);
        }
    }, [selected])
    if (hide) {
      return (
        <>
          {children}
        </>
      )
    }
    return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={(open) ? handleDrawerClose : handleDrawerOpen}
            edge="start"
            className={classes.menuButton}>
            {(open) ? <ChevronLeftIcon/> : <MenuIcon />}
          </IconButton>
          
          <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '-7px', marginLeft:'-20px', width: '100%', height: '100%'}}>
            <ProductHomeTitle onClick={() => {setSelected('/')}}/>
            <div style={{display: 'flex'}}>
              {/* <Searchbar/> */}
              <IconButton style={{height: '48px', width: '48px', marginLeft: '8px', marginTop: '-4px'}} color="inherit">
                <NotificationsIcon/>
              </IconButton> 
              <IconButton style={{height: '48px', width: '48px', marginLeft: '8px', marginTop: '-4px'}} color="inherit">
                <HelpIcon/>
              </IconButton> 
              <IconButton style={{height: '48px', width: '48px', marginLeft: '8px', marginTop: '-4px'}} color="inherit">
                <SettingsIcon/>
              </IconButton> 
              <IconButton style={{height: '48px', width: '48px', marginLeft: '8px', marginTop: '-4px'}} color="inherit">
                <Avatar/>
              </IconButton>  
            </div>
          </div> 
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer2, {
          [classes.drawerOpen2]: open,
          [classes.drawerClose2]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
            {/* <IconButton onClick={handleDrawerClose}>
                {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
            </IconButton> */}
        </div>
        <Divider />
        <List>
          {apps.map((module) => {
            if (module.hidden===true) {
              return null;
            } else {
              return (
                <ListItemLink href={`${module.link}`} disabled={module.disabled} selected={(appSelected && appSelected.toLowerCase() === module.name.toLowerCase())} key={module.name}>
                    <ListItemIcon style={{color: (appSelected && appSelected.toLowerCase() === module.name.toLowerCase()) ? colors.B300 : null}}>{module.icon}</ListItemIcon>
                    <ListItemText style={{color: (appSelected && appSelected.toLowerCase() === module.name.toLowerCase()) ? colors.B300 : null}} primary={module.name} secondary={module.details} />
                </ListItemLink>
              );
            }
          })}
        </List> 
      </Drawer>
      <main className={classes.content}>
        <div className={classes.contentUnderToolbar} />
        {children}
      </main>
    </div>
  );
}

const apps = [
    { name: 'Dashboard', details: 'Your work', icon: (<AppSwitcherIcon />), link: '/apps/dashboard' },
    // { name: 'Products', icon: (<AlbumIcon />), },
    { name: 'Logistics', details: 'Shipping & Receiving', icon: (<LocalShippingIcon />), link: '/browse/SHIP' },
    { name: 'Maintenance', details: '↳ AMO Center (Jira)', icon: (<BuildIcon />), link: 'https://evolutioneng.atlassian.net/servicedesk/customer/portal/9' },
    { name: 'Repairs', details: '↳ RMA Center (Jira)', icon: (<GpsFixedIcon />), link: 'https://evolutioneng.atlassian.net/servicedesk/customer/portal/3' },
    { name: 'Operations', hidden: true, disabled: true, details: 'Job Tracking & Field Data', icon: (<WorkIcon />), link: '/browse/JOB' },
    { name: 'Incidents', details: '↳ Solution Center (Jira)', icon: (<EmojiObjectsIcon />), link: 'https://evolutioneng.atlassian.net/servicedesk/customer/portal/1' },
    // { name: 'Reliability', icon: (<PollIcon />), link: 'https://evolutioneng.atlassian.net/browse/RLBT' },
    // { name: 'Manufacturing', icon: (<SettingsInputCompositeIcon />), link: 'https://evolutioneng.atlassian.net/browse/AMO' },
    // { name: 'App Store', icon: (<MarketplaceIcon />), },
    // { name: 'Knowledge Base', icon: (<PageIcon />), },
    { name: 'Health & Safety', details: '↳ HSE Center (Jira)', icon: (<EmojiSymbolsIcon />), link: 'https://evolutioneng.atlassian.net/servicedesk/customer/portal/30' } 
]