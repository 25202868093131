import React, { useState, useEffect } from 'react';  
import Typography from '@material-ui/core/Typography';  
import {CustomAppBar} from '../components/UserProfilePopper';
import MSTable from '../components/Tables/msTable';
import { useId } from '@fluentui/react-hooks';
import CreateShipment from './CreateShipment';
import { AppConfig } from '../utils/config.utils';
import useLocalStorage from '../utils/auth/useLocalStorage.hook';

import XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import moment from 'moment'; 
import { makeStyles } from '@material-ui/core/styles'; 
import Backdrop from '@material-ui/core/Backdrop';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction'; 
import NoteAddIcon from '@material-ui/icons/NoteAdd'; 
import GetAppIcon from '@material-ui/icons/GetApp';
import ImportContactsIcon from '@material-ui/icons/ImportContacts'; 

import { 
  CustomTextField,
  CustomPickerField
} from '../components/CustomFields';

import { 
  SearchBox,
  Stack,
  getTheme,
  mergeStyleSets,
  FontWeights, 
  Modal, 
  DefaultButton, 
  Label,
  IconButton,
  ScrollablePane,
  PrimaryButton,  
} from '@fluentui/react';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem'; 
import ListItemText from '@material-ui/core/ListItemText';
import MenuIcon from '@material-ui/icons/Menu';

import {
  AppContext, 
  AppContextState 
} from '../contexts/AppContext';
import { Divider } from '@material-ui/core';

const axios = require('axios');
const axiosInstance = axios.create({
    baseURL: `${AppConfig.PUBLIC_API_URL}/api/latest/`, 
    headers: { 
      'Content-Type': 'application/json', 
    } 
}); 

async function getAddressBook() { 
  return new Promise((resolve, reject) => { 
    axiosInstance.get(`/addressbook?code=${AppConfig.PUBLIC_API_CODE}`, {})
    .then(function (response) {
      // console.log(response);  
      resolve(response.data);
    })
    .catch(function (error) {
      console.log(error);
      reject(error);
    });
  });
}

async function updateAddressBook(entry) {
  return new Promise((resolve, reject) => { 
    axiosInstance.put(`/addressbook?code=${AppConfig.PUBLIC_API_CODE}`, entry)
    .then(function (response) {
      // console.log(response);  
      resolve(response.data);
    })
    .catch(function (error) {
      console.log(error);
      reject(error);
    });
  });
}

async function createAddressBookEntry(entry) {
  return new Promise((resolve, reject) => { 
    axiosInstance.post(`/addressbook?code=${AppConfig.PUBLIC_API_CODE}`, entry)
    .then(function (response) {
      // console.log(response);  
      resolve(response.data);
    })
    .catch(function (error) {
      console.log(error);
      reject(error);
    });
  });
}


const useDialerStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    
    position: 'fixed',  
    width: '100vw',
    zIndex: 10, 
    flexGrow: 1,
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  speedDial: {
    position: 'absolute', 
    bottom: theme.spacing(2),
    right: theme.spacing(2), 
  },
  backdrop: {
    position: 'fixed',
    zIndex: 9,
    top: 0, 
    left: 0, 
  }
}));

function SpeedDialTooltipOpen({onExportManifest, onCreateManifest, onShowAddressBook}) {
  const classes = useDialerStyles();
  const [open, setOpen] = React.useState(false); 
 
  const actions = [
    { icon: <ImportContactsIcon />, name: 'Address book', onClick: onShowAddressBook },
    { icon: <NoteAddIcon />, name: 'Create a new manifest', onClick: onCreateManifest }, 
    { icon: <GetAppIcon />, name: 'Export manifests', onClick: onExportManifest },
  ]; 

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return ( 
    <>
    <Backdrop onClick={handleClose} className={classes.backdrop} open={open}/>
    <div className={classes.root}>
      <SpeedDial
        ariaLabel="SpeedDial"
        className={classes.speedDial} 
        icon={<SpeedDialIcon />}
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
        FabProps={{
          style: { backgroundColor: '#79BF43'}
        }}
        direction="up" 
      >
        {actions.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name} 
            onClick={() => {
              handleClose();
              if (action.onClick) action.onClick();
            }}
            tooltipPlacement="left"
          />
        ))}
      </SpeedDial>  
    </div>
    </>
  );
}


function AllEmptyPage() {  
    function getRandomInt(max) {
        return Math.floor(Math.random() * Math.floor(max));
    }
    function getRandomOption() {
        var o = getRandomInt(randomTextTable.length);
        return randomTextTable[o];
    }
    const randomTextTable = [
        {title: 'You’ve cleaned up here perfectly', text: 'The only thing left to clean is your computer screen'},
        {title: 'Your queue is empty but what is empty?', text: 'Time to ponder the mysteries of the Universe'},
        {title: 'Your queue is empty', text: 'Cupcakes for all!'},
        {title: "Queue's empty! Dance party!", text: 'Congratulate yourself on a job well done'},
        {title: 'An empty queue deserves a quote of the day:', text: `"I'm not superstitious, but I am a little stitious." - Michael Scott`},
        {title: 'An empty queue deserves a quote of the day:', text: '"You miss 100% of the shots you don’t take. - Wayne Gretzky" - Michael Scott'},
        {title: 'An empty queue deserves a quote of the day:', text: '"Wikipedia is the best thing ever. Anyone in the world can write anything they want about any subject so you know you are getting the best possible information." - Michael Scott'},
        {title: 'An empty queue deserves a quote of the day:', text: '"I learned a while back that if I do not text 911, people do not return my calls. Um, but people always return my calls because they think that something horrible has happened." - Michael Scott'},
        {title: 'An empty queue deserves a quote of the day:', text: '"And I knew exactly what to do. But in a much more real sense, I had no idea what to do." - Michael Scott'},
        {title: 'An empty queue deserves a quote of the day:', text: '"I love inside jokes. I hope to be a part of one someday." - Michael Scott'},
        {title: 'An empty queue deserves a quote of the day:', text: `"Sometimes I'll start a sentence and I don't even know where it's going. I just hope I find it along the way." - Michael Scott`},
        {title: 'An empty queue deserves a quote of the day:', text: '"Would I rather be feared or loved? Easy. Both. I want people to be afraid of how much they love me." - Michael Scott'},
        {title: 'An empty queue deserves a quote of the day:', text: '"Webster’s Dictionary defines a wedding as: The fusing of two metals with a hot torch." - Michael Scott'},
        {title: 'An empty queue deserves a quote of the day:', text: '"When the son of the deposed king of Nigeria emails you directly, asking for help, you help! His father ran the freaking country! OK?" - Michael Scott'},
        {title: 'An empty queue deserves a quote of the day:', text: '"I saved a life. My own. Am I a hero?… I really can’t say, but yes!" - Michael Scott'},
        {title: 'An empty queue deserves a quote of the day:', text: '"There’s no such thing as an appropriate joke. That’s why it’s called a joke." - Michael Scott'},
        {title: 'An empty queue deserves a quote of the day:', text: '"People will never be replaced by machines. In the end, life and business are about human connections. And computers are about trying to murder you in a lake. And to me the choice is easy." - Michael Scott'},
        {title: 'Amazing! Nothing to do here but lament on a fact of the day:', text: 'Most car horns are in the key of F. Better hold it for a minute just to be sure.'},
        {title: 'Amazing! Nothing to do here but lament on a fact of the day:', text: 'Despite his prolific musical talents, Wolfgang Amadeus Mozart has been decomposing almost continuously since 1791.'},
        {title: 'Amazing! Nothing to do here but lament on a fact of the day:', text: 'About 11,000 Americans injure themselves while trying out weird positions per year.'},
        {title: 'Amazing! Nothing to do here but lament on a fact of the day:', text: 'Alaska is the only state whose name is on one row on a keyboard.'},
        {title: 'Amazing! Nothing to do here but lament on a fact of the day:', text: "They say people in glass houses shouldn't throw stones. What's worse - they always have to answer the door."},
        {title: 'Amazing! Nothing to do here but lament on a fact of the day:', text: 'Selfies now cause more deaths than shark attacks. Remember to bring a selfie stick with you to ward off attackers!'},
        {title: 'Amazing! Nothing to do here but lament on a fact of the day:', text: '"It’s impossible to hum while holding your nose." - Not with that attitude, I say!'},
        {title: 'Amazing! Nothing to do here but lament on a fact of the day:', text: "People used to say “prunes” instead of “cheese” when having their pictures taken. Now we say...Oh, someone's calling!"},
        {title: 'Amazing! Nothing to do here but lament on a fact of the day:', text: 'It snowed in the Sahara desert for 30 minutes on February 18, 1979.'},
        {title: 'Amazing! Nothing to do here but lament on a fact of the day:', text: 'A cubic inch of human bone can bear the weight of five standard pickup trucks. And yet, it still hurts to stub your toe on a wooden dining chair.'},
        {title: 'Amazing! Nothing to do here but lament on a fact of the day:', text: "A dragonfly has a lifespan of only one day. Make yours a good one!"},
        {title: 'Amazing! Nothing to do here but lament on a fact of the day:', text: "The average person falls asleep in seven minutes. Not something you want to be above average at."},
        {title: 'Amazing! Nothing to do here but lament on a fact of the day:', text: 'Rhubarb can spring up so fast that you can actually hear it grow.'}, 
    ];

    const [contentText, setContentText] = useState(getRandomOption())
  
    return (
      <div style={{marginTop:'50px', display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
        <img height='128px' src='/queue-empty.svg'/>
        <Typography style={{fontWeight:600, fontFamily: 'Segoe UI', marginTop: '16px'}} variant='h6'>{contentText.title}</Typography>
        <Typography style={{marginTop: '16px', fontFamily: 'Segoe UI'}} variant='body2'>{contentText.text}</Typography>
      </div>
    )
  }  

async function updateDocument(doc) {
  return new Promise((resolve, reject) => { 
    axiosInstance.put(`/document?code=${AppConfig.PUBLIC_API_CODE}`, doc)
    .then(function (response) {
      console.log(response); 
      resolve();
    })
    .catch(function (error) {
      console.log(error);
      reject(error);
    });
  });
} 
          
async function createDocument(doc) { 
  return new Promise((resolve, reject) => {
    axiosInstance.post(`/document?code=${AppConfig.PUBLIC_API_CODE}`, doc)
    .then(function (response) {
      console.log(response); 
      resolve(response.data); 
    })
    .catch(function (error) {
      console.log(error);
      reject(error);
    });
  }); 
}

  
const rootTemplate = {
  id: '',
  productIds: [],
  eventType: 'Shipment', 
  eventTime: '',
  createdBy: {},
  history: [],
  attachments: [],
  eventData: {
    'key': '',
    'status': {
      'key': 'staging',
      'text': 'Staging',
      'category': 'unresolved',
    },
    'Date Shipped': '',
    'Special Instructions': '', 
    'Shipment Notes': '',  
    'From': {
      'Company': '',
      'Contact': '',
      'Phone': '', 
      'Address': '', 
      'City, State, Zip': '', 
      'Country': '',  
      'Rig': '',
      'Operator': '',
    },
    'To': {
      'Company': '',
      'Contact': '',
      'Phone': '', 
      'Address': '', 
      'City, State, Zip': '', 
      'Country': '', 
      'Rig': '',
      'Operator': '',
    }
  }
};

function createManifestTemplate() {
  return Object.assign({}, rootTemplate);
}

const AllManifests = ({hideFrame}) => {
  const applicationState = React.useContext(AppContextState); 
  const applicationStateDispatcher = React.useContext(AppContext); 
  const [local, setLocal] = useLocalStorage('user', {});
  const [manifests, setManifests] = useState([]);
  const [altmanifests, setAltManifests] = useState([]); 
  const [displayCount, setDisplayCount] = useState(25);
  const [filters, setFilters] = useState({ 
      eql: 'data.eventData.complete=false and data.eventType="Shipment"',
  }); 
  const [altfilters, setAltFilters] = useState({ 
      eql: 'data.eventData.complete=true and data.eventType="Shipment"',
  }); 
  const [activeFilters, setActiveFilters] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [fileSearchValue, setFileSearchValue] = useState('');
  const [document, setDocument] = useState(createManifestTemplate()); 
  const [overrideSave, setOverrideSave] = useState(false);
  const [downloadUrl, setDownloadUrl] = useState(null); 
  const [modalABOpen, setModalABOpen] = useState(false);

   useEffect(() => {
        //generate the fetch url based on active filters and their keys
        const generateURL = (filters) => {
            let url = `${AppConfig.PUBLIC_API_URL}/api/latest/ship/queue`;
            const keys = Object.keys(filters);
            keys.forEach((key, idx) => {
                if (idx === 0) {
                    url = `${url}?${key}=${filters[key]}`;
                } else {
                    url = `${url}&${key}=${filters[key]}`;
                }
            });
            url += `&count=all`;
            url += `&code=${AppConfig.PUBLIC_API_CODE}`;
            return url;
        };

        const urlToFetch = generateURL(filters);
        fetch(urlToFetch)
            .then(response => {
                if (response.status < 300) {
                    // console.log(response);
                    return response.json();
                } else {
                    return { data: [], count: [{ count: 0 }] };
                }
            })
            .then(json => {
                // console.log(json.length, json);
                setManifests(json);
                // setManifestCount(json.length);
            });
    }, [filters]);

    useEffect(() => {
        //generate the fetch url based on active filters and their keys
        const generateURL = (altfilters) => {
            let url = `${AppConfig.PUBLIC_API_URL}/api/latest/ship/queue`;
            const keys = Object.keys(altfilters);
            keys.forEach((key, idx) => {
                if (idx === 0) {
                    url = `${url}?${key}=${altfilters[key]}`;
                } else {
                    url = `${url}&${key}=${altfilters[key]}`;
                }
            });
            url += `&count=${displayCount}`;
            url += `&code=${AppConfig.PUBLIC_API_CODE}`;
            return url;
        };

        const urlToFetch = generateURL(altfilters);
        fetch(urlToFetch)
            .then(response => {
                if (response.status < 300) {
                    // console.log(response);
                    return response.json();
                } else {
                    return { data: [], count: [{ count: 0 }] };
                }
            })
            .then(json => {
                // console.log(json.length, json);
                setAltManifests(json);
                // setAltManifestCount(json.length);
            });
    }, [altfilters, displayCount]);


    useEffect(() => {
        setFilters(s => ({ ...s, page: 0 }));
    }, [activeFilters])

    useEffect(() => {
        if (downloadUrl) { 
          window.location.href = downloadUrl;
          setDownloadUrl(null);
        } 
    }, [downloadUrl]);
 
    function handleDocumentCreate(created) {
        if (!created) {
            console.log('Canceled creation');
            setModalOpen(false);
            return;
        }
        setOverrideSave(true);   
        console.log(local);
        createDocument({...document, createdBy: local}) 
        .then((resp) => {
          console.log(resp);
          if (resp.result === 'SUCCESS') {
            setDocument(resp.detail);
            window.location.href = '/browse/' + resp.detail.id;
          }
          setModalOpen(false);
        })
        .catch((error) => {
          console.log(error);
          setModalOpen(false);
        });
    } 

    function s2ab(s) { 
      var buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
      var view = new Uint8Array(buf);  //create uint8array as viewer
      for (var i=0; i<s.length; i++) view[i] = s.charCodeAt(i) & 0xFF; //convert to octet
      return buf;    
    }

    const handleExport = () => {
      var wb = XLSX.utils.book_new();
      wb.Props = {
          Title: "Logistics Summary",
          Subject: "Completed Shipments",
          Author: "Evolution Portal",
          CreatedDate: moment().format()
      };
      wb.SheetNames.push("Shipment History");
      
      var ws_data = [['Key' , 'Date Shipped', 'From', 'To']];  //a row with 2 columns
      altmanifests.forEach((m, idx) => { 
        ws_data.push([ 
          m.id,
          (m.eventData.status.date) ? moment(m.eventData.status.date).format('YYYY-MM-DD') : moment(m.eventData['Date Shipped']).format('YYYY-MM-DD'),
          m.eventData.From.Location || m.eventData.From.Company, 
          m.eventData.To.Location || m.eventData.To.Company, 
        ]);  
      });
      var ws = XLSX.utils.aoa_to_sheet(ws_data);
      wb.Sheets["Shipment History"] = ws;
      var wbout = XLSX.write(wb, {bookType:'xlsx',  type: 'binary'});
      saveAs(new Blob([s2ab(wbout)],{type:"application/octet-stream"}), `logistics-history-${moment().format('YYYY-MM-DD-HH-MM-SS')}.xlsx`);
    }
  
    const handleCloseAB = () => {
      setModalABOpen(false);
    }

    return (
      <>
      <SpeedDialTooltipOpen
          onExportManifest={() => {handleExport()}}
          onCreateManifest={() => {setModalOpen(true)}}
          onShowAddressBook={() => {setModalABOpen(true)}}
          />
        <CustomAppBar>
            <AddressBookModal title="Address book" open={modalABOpen} onClose={handleCloseAB}/>
            <ModalBasicExample title="New shipment" open={modalOpen} onClose={handleDocumentCreate}>
                <CreateShipment 
                  overrideSave={overrideSave}
                  isModal={true} 
                  document={document} 
                  setDocument={setDocument} 
                  saveDocument={() => {
                    // console.log('Saving...', document);
                  }}/>
            </ModalBasicExample>
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', flexBasis: '67%'}}> 
                <Stack horizontal styles={{ root: { height: 44, width: '845px', justifyContent: 'space-between' } }}>
                  <Label style={{fontSize: '28px', textAlign: 'left'}}>Logistics</Label> 
                  <SearchBox 
                      styles={{ root: { width: 200, marginTop: '10px' } }}
                      placeholder="Filter" 
                      iconProps={{ iconName: 'Filter' }}
                      onChange={(ev, newValue) => {setFileSearchValue(newValue.toLowerCase());}} 
                      onSearch={newValue => {setFileSearchValue(newValue.toLowerCase());}} /> 
                </Stack> 
                <div style={{height: '16px'}}/>
                {manifests.length === 0 && <AllEmptyPage/>}
                {manifests.length > 0 && <MSTable hideDisplayOptions filter={fileSearchValue} label={'Staging'} inputItems={manifests} setInputItems={setManifests}/>} 
                <div style={{marginTop: '20px'}}/>
                <MSTable setDisplayCount={setDisplayCount} filter={fileSearchValue} divider label={'Shipped'} inputItems={altmanifests} setInputItems={setAltManifests}/>
            </div> 
         </CustomAppBar>
         </>
    );
}

export default AllManifests;
 
const cancelIcon = { iconName: 'Cancel' };
  
const ModalBasicExample = ({title, children, id, open, onClose}) => { 
    const titleId = useId(id || 'title'); 
    return (
      <div> 
        <Modal
          titleAriaId={titleId}
          isOpen={open}
          onDismiss={() => onClose(false)}
          isBlocking={false}
          containerClassName={contentStyles.container} 
        >
          <div className={contentStyles.header}>
            <span id={titleId}>{title}</span>
            <IconButton
              styles={iconButtonStyles}
              iconProps={cancelIcon}
              ariaLabel="Close popup modal"
              onClick={() => onClose(false)}
            />
          </div>
          <div className={contentStyles.body}>
            {children}
          </div>
          <div style={{backgroundColor: '#F9F9F9', height: '56px', paddingTop: '12px', paddingRight: '12px', width: '100%', position: 'absolute', bottom: 0, right: 0}}>
            <Stack horizontal tokens={{ childrenGap: 5 }} styles={{ root: { height: 44, display: 'flex', justifyContent: 'flex-end' } }}>
              <DefaultButton onClick={() => onClose(false)} text="Cancel" /> 
              <DefaultButton primary onClick={() => onClose(true)} text="Create" /> 
            </Stack>
          </div>
        </Modal>
      </div>
    );
  };

  const AddressBookModal = ({title, children, id, open, onClose}) => { 
    const titleId = useId(id || 'title'); 

    const applicationState = React.useContext(AppContextState); 
    const applicationStateDispatcher = React.useContext(AppContext); 

    const labelSpacing='96px';

    const [selectedAddress, setSelectedAddress] = useState(undefined);
    const [addressData, setAddressData] = useState(undefined);
    const [addressBook, setAddressBook] = useState([]);
    const [isNewAddress, setIsNewAddress] = useState(false);

    useEffect(() => { 
      getAddressBook() 
      .then(result => setAddressBook(result)) 
      .catch(console.log);
    }, []);

    useEffect(() => {
      if (!selectedAddress) {
        return;
      }
      var i = addressBook.findIndex(x => x.label === selectedAddress);
      if (i >= 0) {
        setAddressData({...addressBook[i]});
      }
    }, [selectedAddress]);

    function handlePropertyChange(id, value, field) {  
      if (isNewAddress) {
        setAddressData(s => {
          s[field] = value; 
          return {...s};
        }); 
      } else {
        var idx = addressBook.findIndex(x => x.id === id);
        setAddressBook(s => {  
          if (idx >= 0) {  
            s[idx][field] = value;   
            return [...s];
          } else { 
            return s;
          }
        });  
        setAddressData(s => {
          s[field] = value;
          updateAddressBook({...s}) 
          .catch(console.log);
          return {...s};
        }); 
      }
    }

    const handleAddLocation = () => {
      setIsNewAddress(true);
      setSelectedAddress(undefined);
      setAddressData({});
    }
    

    const handleAddAddress = () => {
      createAddressBookEntry({...addressData})
      .then((result) => { 
        console.log(result);
        if (result.result === 'SUCCESS') {
          getAddressBook() 
          .then(result => setAddressBook(result)) 
          .catch(console.log);
        } else {
          console.log("Couldn't update the address book!");
        }
      })
      .catch(console.log); 
      setAddressData(undefined);
      setSelectedAddress(undefined);
      setIsNewAddress(false);
    }

    const handleDiscardAddress = () => {
      setAddressData(undefined);
      setSelectedAddress(undefined);
      setIsNewAddress(false);
    }

    return (
      <div> 
        <Modal
          titleAriaId={titleId}
          isOpen={open}
          onDismiss={() => onClose(false)}
          isBlocking={false}
          containerClassName={contentStyles.container} 
        >
          <div className={contentStyles.header}>
            <span id={titleId}>{title}</span>
            <IconButton
              styles={iconButtonStyles}
              iconProps={cancelIcon}
              ariaLabel="Close popup modal"
              onClick={() => onClose(false)}
            />
          </div>
          <div className={contentStyles.body}> 
            <div style={{display: 'flex', width: '100%', flexGrow: 1}}>
              <div className={classNames.wrapper}>
                <ScrollablePane
                  styles={{ root: classNames.pane }}
                  scrollContainerFocus={true}
                  scrollContainerAriaLabel="Scrollviewaddrbook"> 
                  <List dense component="nav" aria-label="mainfolders">
                    <ListItem onClick={handleAddLocation} button>
                      <Label>Add a new location</Label>
                    </ListItem>
                    <Divider/>
                    {addressBook.map(x => {
                      return (
                        <ListItem selected={x.label===selectedAddress} button onClick={() => {setIsNewAddress(false); setSelectedAddress(x.label)}}>
                          <Label>{x.label || 'New address'}</Label> 
                        </ListItem>
                      )
                    })} 
                  </List> 
                </ScrollablePane>
              </div>
              <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', padding: '16px', margin: '0px 8px', minWidth: '400px', border: '1px solid #E0E0E0', borderRadius: '8px'}}>
                {addressData !== undefined && 
                  <div>
                    <CustomTextField labelSpacing={labelSpacing} label="Location Tag" placeholder="Enter a value" value={addressData.label} setValue={(value) => handlePropertyChange(addressData.id, value, 'label')}/> 
                    <CustomTextField labelSpacing={labelSpacing} label="Type" placeholder="Enter a value" value={addressData.locationType} setValue={(value) => handlePropertyChange(addressData.id, value, 'locationType')}/> 
                    <CustomTextField labelSpacing={labelSpacing} label="Company" placeholder="Enter a value" value={addressData.companyName} setValue={(value) => handlePropertyChange(addressData.id, value, 'companyName')}/> 
                    <CustomTextField labelSpacing={labelSpacing} label="Address" placeholder="Enter a value" value={addressData.address} setValue={(value) => handlePropertyChange(addressData.id, value, 'address')}/> 
                    <CustomTextField labelSpacing={labelSpacing} label="City/State/Zip" placeholder="Enter a value" value={addressData.cityStateZip} setValue={(value) => handlePropertyChange(addressData.id, value, 'cityStateZip')}/> 
                    <CustomTextField labelSpacing={labelSpacing} label="Country" placeholder="Enter a value" value={addressData.country} setValue={(value) => handlePropertyChange(addressData.id, value, 'country')}/> 
                    <CustomTextField labelSpacing={labelSpacing} label="Contact #" placeholder="Enter a value" value={addressData.phone} setValue={(value) => handlePropertyChange(addressData.id, value, 'phone')}/>
                    <CustomTextField labelSpacing={labelSpacing} label="Contact Name" placeholder="Enter a value" value={addressData.contact} setValue={(value) => handlePropertyChange(addressData.id, value, 'contact')}/>
                  </div>
                } 
                {!addressData &&
                  <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '100%', flexGrow: 1, }}>
                    <img width='350px' src="/undraw_right_direction_tge8.png" alt="Select an address!"/>
                    <Label style={{fontWeight: 500, fontSize: '16px'}}>Choose an entry from the list!</Label>
                  </div>
                }
                { isNewAddress &&  
                  <div style={{display: 'flex', width: '100%', justifyContent: 'flex-end'}}>
                    <PrimaryButton onClick={handleAddAddress}>Add</PrimaryButton>
                    <div style={{marginRight: '8px'}}/>
                    <DefaultButton onClick={handleDiscardAddress}>Discard</DefaultButton>
                  </div> 
                }
              </div>
            </div>
          </div> 
        </Modal>
      </div>
    );
  };
  
  const theme = getTheme();
  const classNames = mergeStyleSets({
    wrapper: {
      height: '40vh',
      width: '200px',
      position: 'relative',
      maxHeight: 'inherit',
    },
    pane: {
      flexGrow: 1,
      maxWidth: 200,
      borderRadius: '8px',
      border: '1px solid ' + theme.palette.neutralLight,
    },
    sticky: {
      color: theme.palette.neutralDark,
      padding: '5px 20px 5px 10px',
      fontSize: '13px',
      borderTop: '1px solid ' + theme.palette.black,
      borderBottom: '1px solid ' + theme.palette.black,
    },
    textContent: {
      padding: '15px 10px',
    },
  });
  const contentStyles = mergeStyleSets({
    container: {
      display: 'flex',
      flexFlow: 'column nowrap',
      alignItems: 'stretch',
    },
    header: [ 
      theme.fonts.xLargePlus,
      {
        flex: '1 1 auto',
        borderTop: `4px solid ${theme.palette.themePrimary}`,
        color: theme.palette.neutralPrimary,
        display: 'flex',
        alignItems: 'center',
        fontWeight: FontWeights.semibold,
        padding: '12px 12px 14px 24px',
      },
    ],
    body: {
      // display: 'flex',
      // flex: '4 4 auto',
      padding: '0 24px 24px 24px',
      overflowY: 'hidden',
      selectors: {
        p: { margin: '14px 0' },
        'p:first-child': { marginTop: 0 },
        'p:last-child': { marginBottom: 0 },
      },
    },
  }); 
  const iconButtonStyles = {
    root: {
      color: theme.palette.neutralPrimary,
      marginLeft: 'auto',
      marginTop: '4px',
      marginRight: '2px',
    },
    rootHovered: {
      color: theme.palette.neutralDark,
    },
  };
  