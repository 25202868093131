import React, { useEffect, useState } from 'react'; 
import Typography from '@material-ui/core/Typography';  
import CreateShipment from './CreateShipment';
import CreateJob from './CreateJob';
import AppHeader from '../components/AppHeader'; 
import { AppConfig } from '../utils/config.utils';
import useLocalStorage from '../utils/auth/useLocalStorage.hook';
import AllManifests from '../pages/AllManifests';
import AllJobs from '../pages/AllJobs';
import {
    Breadcrumb
} from '@fluentui/react';
import DashboardApp from './DashboardApp';

const API_CODE = AppConfig.PUBLIC_API_CODE;
const API_URL = AppConfig.PUBLIC_API_URL;
const axios = require('axios');
const axiosInstance = axios.create({
  baseURL: `${API_URL}/api/latest`, 
  headers: {'Content-Type': 'application/json'}
});
 
async function updateDocument(doc) {
  return new Promise((resolve, reject) => { 
    axiosInstance.put(`/document?code=${AppConfig.PUBLIC_API_CODE}`, doc)
    .then(function (response) { 
      resolve();
    })
    .catch(function (error) {
      console.log(error);
      reject(error);
    });
  });
} 

async function getDocument(appId, data) {
  return new Promise((resolve, reject) => { 
    axiosInstance.get(`/document?key=${data.key}&code=${API_CODE}`, {})
    .then(function (response) {
      console.log(response);  
      resolve(response.data);
    })
    .catch(function (error) {
      console.log(error);
      reject(error);
    });
  });
}

async function deleteDocument(data) {
  return new Promise((resolve, reject) => { 
    axiosInstance.delete(`/document?key=${data.key}&code=${API_CODE}`, {})
    .then(function (response) {
      console.log(response);  
      resolve(response.data);
    })
    .catch(function (error) {
      console.log(error);
      reject(error);
    });
  });
}

const AppBrowser = (props) => {
    const { app } = props.match.params; 
    const [validKeys, setValidKeys] = useState(['dashboard', 'logistics', 'operations'])
    const [document, setDocument] = useState({}); 
    const [loading, setLoading] = useState(true);
    const [valid, setValid] = useState('UNKNOWN'); 
    const [local, setLocal] = useLocalStorage('user', {});  
    
    useEffect(() => {  
        if (validKeys.includes(app)) {
            setValid('DOC_ACCEPTED_APP_ID');
        } else {
            setValid('DOC_REJECTED');
        }
        setLoading(false); 
    }, [app]);

    return (
        <AppHeader appSelected={app}>
            <>
                <BreadcrumbBasicExample document={{...(document || {}), appId: app}}/>
                {loading && <div>Loading...</div>}
                {!loading && (
                    <>
                    {/* {(valid === 'BROWSE_ACCEPTED') && <Typography variant="h1">Display the general browser!</Typography>} */}
                    {/* {(valid === 'BROWSE_ACCEPTED_APP_ID') && <Typography variant="h1">Display the app browser!</Typography>} */}
                    {/* {(valid === 'BROWSE_REJECTED_APP_ID') && <PermissionErrorPage title={"You don't have access to this app"} description={"This app requires elevated permissions or the app doesn't exist. If you need access, contact the Portal Administrator for support."}/>} */}
                    {(valid === 'DOC_ACCEPTED_APP_ID') && (
                        <RenderDocument document={{...(document || {}), appId: app}} setDocument={setDocument}/>
                    )}
                    {(valid === 'DOC_REJECTED') && <PermissionErrorPage title={"You don't have access to this app"} description={"This app requires elevated permissions or the app doesn't exist. If you need access, contact the Portal Administrator for support."}/>}
                    </>
                )}
            </>
        </AppHeader>
    ); 
};


function _onBreadcrumbItemClicked(ev, item) {
    switch (item.key) {
      case 'document':   
        window.location.href = item.to;
        break;
      case 'browse': 
        window.location.href = item.to;
        break;
      case 'app':
        window.location.href = item.to;
        break;
      case 'home':
        window.location.href = item.to;
        break;
      default: 
        break;
    }
    console.log(item); 
} 
  
function convertAppIdToAppName(appId) {
    switch (appId.toLowerCase()) {
        case 'dashboard': return 'Dashboard';
        case 'logistics': return 'Logistics';
        case 'operations': return 'Operations';
        default: return '...';
    }
} 

const BreadcrumbBasicExample = ({document}) => { 
    const [bcItems, setBCItems] = useState([]);  

    useEffect(() => { 
        if (document) {
            // console.log(document);
            setBCItems(b => { 
                return [ 
                    { to: '/', text: 'Home', key: 'home', onClick: _onBreadcrumbItemClicked }, 
                    { to: `/apps/${document.appId}`, text: convertAppIdToAppName(document.appId), key: 'browse', onClick: _onBreadcrumbItemClicked },
                    // { to: `/apps/${document.id}`, documentId: document.id, text: document.id, key: 'document', onClick: _onBreadcrumbItemClicked, isCurrentItem: true },
                ];
            });
        } else { 
            setBCItems(b => {  
                return [
                    { 
                        text: 'Home', 
                        key: 'home', 
                        onClick: _onBreadcrumbItemClicked,
                        isCurrentItem: true
                    }
                ];
            });
        } 
    }, [document])
    return (
      <div style={{marginBottom: '16px'}}> 
        <Breadcrumb
          items={bcItems}
          maxDisplayedItems={4}
          ariaLabel="Breadcrumb"
          overflowAriaLabel="More links"
        /> 
      </div>
    );
}; 

function RenderDocument({ document, setDocument }) {
    async function saveDocument() {
        await updateDocument(document);
    }
    switch (document.appId.toLowerCase()) {
        case 'logistics': 
            return (
                <AllManifests hideFrame/>
            );
        case 'operations':
            return (
                <AllJobs hideFrame/>
            ); 
        case 'dashboard':
            return (
                <DashboardApp/>
            );
        default: 
            return (
                <div>Coming soon...</div>
            );
                
    } 
}

function PermissionErrorPage({title, description}) {
    return (
      <div style={{marginTop:'50px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
        <img height='128px' src='/permission-error.svg'/>
        <Typography style={{fontWeight:600, fontSize: '24px', marginTop: '20px', fontFamily: 'Segoe UI'}} variant='h6'>{title}</Typography>
        <Typography style={{marginTop: '10px', fontSize: '16px', fontFamily: 'Segoe UI', maxWidth: '700px'}} variant='body2'>{description}</Typography>
      </div>
    )
  } 

export default AppBrowser;