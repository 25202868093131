import React, { useEffect, useState, useCallback, useContext } from 'react'; 
import { 
    // Checkbox, 
    NormalPeoplePicker, 
    ValidationState,
    PersonaPresence, 
    Label, 
} from '@fluentui/react';  
import { AppContext, AppContextState } from '../contexts/AppContext'; 
 
import {
  userAllPresences, 
  userProfilePhoto,
  searchUsers,
} from '../utils/graph';

const suggestionProps = {
    suggestionsHeaderText: 'Suggested People',
    mostRecentlyUsedHeaderText: 'Suggested Contacts',
    noResultsFoundText: 'No results found',
    loadingText: 'Loading',
    showRemoveButtons: false,
    suggestionsAvailableAlertText: 'People Picker Suggestions available',
    suggestionsContainerAriaLabel: 'Suggested contacts',
  };
  
  
//   const checkboxStyles = {
//     root: {
//       marginTop: 10,
//     },
//   };
  
  const GraphPeoplePicker = ({users, updateUsers, variant, label}) => {
    const [delayResults, setDelayResults] = React.useState(false);
    const [isPickerDisabled, setIsPickerDisabled] = React.useState(false);
    const [mostRecentlyUsed, setMostRecentlyUsed] = React.useState([]);
    const [peopleList, setPeopleList] = React.useState([]);
    const [loaded, setLoaded] = useState(false);
    const applicationState = React.useContext(AppContextState);
  
    const picker = React.useRef(null);
    
    useEffect(() => {
      if (!picker || !picker.current || !picker.current.addItem) {
        return;
      }
      if (picker && picker.current && !loaded) { 
          if (users && users.length > 0) {
              for (let i = 0; i < users.length; i++) {
                  const v = users[i];
                  
                  userAllPresences(applicationState.user.accessToken, [v.userId])
                  .then((presence) => {
                      if (presence && presence.value && presence.value.length > 0) presence = presence.value[0];
                      else presence = {}; 
                      userProfilePhoto(applicationState.user.accessToken, v.userId)
                      .then((pp) => {
                          if (pp) { 
                              const url = window.URL || window.webkitURL;
                              const blobUrl = url.createObjectURL(pp);
                              pp = blobUrl;
                          } 
                          picker.current.addItem(
                              formatPersona({
                                  displayName: v.displayName, 
                                  id: v.id || v.userId
                              }, 
                              presence, 
                              pp)
                          ); 
                      })
                      .catch((error) => console.log(error)); 
                  })
                  .catch((error) => console.log(error));
                  // TODO: load presence and profile photo and call updateUsers  
              }
          }
          setLoaded(true);
        } 
      }, [picker, loaded])

    function extractInitials(name) {
        var initials = '?';
        if (name) {
            var init = name.split(' ');
            if (init.length > 0) initials = init[0][0];
            if (init.length > 1) initials += init[init.length - 1][0];
            initials = initials.toUpperCase();
        }
        return initials;
    } 

    function convertStatusToPresence(availability) {
        switch (availability) {
            case "Available": return PersonaPresence.online;
            case "Offline": return PersonaPresence.offline;
            case "Busy": return PersonaPresence.busy;
            case "InAMeeting" : return PersonaPresence.dnd;
            case "InACall": return PersonaPresence.dnd;
            case "DoNotDisturb": return PersonaPresence.dnd;
            case 'Away': return PersonaPresence.away;
            case "PresenceUnknown": return PersonaPresence.blocked;
            default: 
                return PersonaPresence.none;
        }
    } 

    function formatPersona(profile, presence, avatarUrl) { 
        var secondary = presence.activity;
        if (secondary === 'PresenceUnknown') secondary = 'Not available';
        else if (secondary === 'InAMeeting') secondary = 'In a meeting';
        else if (secondary === 'InACall') secondary = 'On a call';
        else if (secondary === 'DoNotDisturb') secondary = 'Do not disturb';
        var presTitle = presence.availability;
        if (presTitle === 'PresenceUnknown') presTitle = 'Not available';
        else if (presTitle === 'InAMeeting') presTitle = 'In a meeting';
        else if (presTitle === 'InACall') presTitle = 'On a call'; 
        else if (presTitle === 'DoNotDisturb') presTitle = 'Do not disturb';
        const r = {
            presence: convertStatusToPresence(presence.availability),
            secondaryText: secondary, 
            presenceTitle: presTitle,
            id: profile.id,
            imageUrl: avatarUrl,
            text: profile.displayName, 
            imageInitials: extractInitials(profile.displayName),
        }; 
        return r;
    }

    const onFilterChanged = async (filterText, currentPersonas, limitResults) => {
      if (filterText) {
        return new Promise((resolve, reject) => {
            searchUsers(applicationState.user.accessToken, filterText)
            .then(async (results) => {  
                if (results.value) { 
                    //console.log(results);
                    var pres = [];
                    if (results.value && results.value.length > 0) {
                        var pres = await userAllPresences(applicationState.user.accessToken, results.value.map(x => {return x.id}));
                      //  console.log(pres);
                        for (let k = 0; k < results.value.length; k++) { 
                            var pp = await userProfilePhoto(applicationState.user.accessToken, results.value[k].id);
                            if (pp) { 
                                const url = window.URL || window.webkitURL;
                                const blobUrl = url.createObjectURL(pp);
                                results.value[k].imageUrl = blobUrl;
                            }
                        }

                    }

                    var fp = removeDuplicates(results.value, currentPersonas);
                    fp = limitResults ? fp.slice(0, limitResults) : fp;
                    resolve(fp.map(x => { 
                        var userPresence = {}; 
                        userPresence = pres.value.filter(y => y.id === x.id);
                        if (userPresence.length > 0) userPresence = userPresence[0];
                        var secondary = userPresence.activity;
                        if (secondary === 'PresenceUnknown') secondary = 'Not available';
                        else if (secondary === 'InAMeeting') secondary = 'In a meeting';
                        else if (secondary === 'InACall') secondary = 'On a call';
                        else if (secondary === 'DoNotDisturb') secondary = 'Do not disturb';
                        var presTitle = userPresence.availability;
                        if (presTitle === 'PresenceUnknown') presTitle = 'Not available';
                        else if (presTitle === 'InAMeeting') presTitle = 'In a meeting';
                        else if (presTitle === 'InACall') presTitle = 'On a call'; 
                        else if (presTitle === 'DoNotDisturb') presTitle = 'Do not disturb';
                        const r = {
                            presence: convertStatusToPresence(userPresence.availability),
                            secondaryText: secondary, 
                            presenceTitle: presTitle,
                            id: x.id,
                            imageUrl: x.imageUrl,
                            text: x.displayName, 
                            imageInitials: extractInitials(x.displayName),
                        }; 
                        return r;
                    }));
                } else {
                    let filteredPersonas = filterPersonasByText(filterText); 
                    filteredPersonas = removeDuplicates(filteredPersonas, currentPersonas);
                    filteredPersonas = limitResults ? filteredPersonas.slice(0, limitResults) : filteredPersonas;
                    resolve(filterPromise(filteredPersonas));
                }
            })
            .catch(reject);
        });
      } else {
        return [];
      }
    };
  
    const filterPersonasByText = (filterText) => {
      return peopleList.filter(item => doesTextStartWith(item.text, filterText));
    };
  
    const filterPromise = (personasToReturn) => {
      if (delayResults) {
        return convertResultsToPromise(personasToReturn);
      } else {
        return personasToReturn;
      }
    };
  
    const returnMostRecentlyUsed = (currentPersonas) => {
      return filterPromise(removeDuplicates(mostRecentlyUsed, currentPersonas));
    };
  
    const onRemoveSuggestion = (item) => {
      const indexPeopleList = peopleList.indexOf(item);
      const indexMostRecentlyUsed = mostRecentlyUsed.indexOf(item);
  
      if (indexPeopleList >= 0) {
        const newPeople = peopleList
          .slice(0, indexPeopleList)
          .concat(peopleList.slice(indexPeopleList + 1));
        setPeopleList(newPeople);
      }
  
      if (indexMostRecentlyUsed >= 0) {
        const newSuggestedPeople = mostRecentlyUsed
          .slice(0, indexMostRecentlyUsed)
          .concat(mostRecentlyUsed.slice(indexMostRecentlyUsed + 1));
        setMostRecentlyUsed(newSuggestedPeople);
      }
    };
  
    // const onDisabledButtonClick = () => {
    //   setIsPickerDisabled(!isPickerDisabled);
    // };
  
    // const onToggleDelayResultsChange = () => {
    //   setDelayResults(!delayResults);
    // };
  
    return (
      <div>
        <Label>{label || 'User Picker Field'}</Label>
        <NormalPeoplePicker
            borderless
            styles={{text: {border: 0, width:260 }}}
            // eslint-disable-next-line react/jsx-no-bind
            onResolveSuggestions={onFilterChanged}
            // eslint-disable-next-line react/jsx-no-bind
            onEmptyInputFocus={returnMostRecentlyUsed}
            getTextFromItem={getTextFromItem}
            pickerSuggestionsProps={suggestionProps}
            className={'ms-PeoplePicker'}
            key={'normal'}
            itemLimit={(variant === 'multiple') ? undefined : 1}
            // eslint-disable-next-line react/jsx-no-bind
            onRemoveSuggestion={onRemoveSuggestion}
            onChange={(value) => {if (updateUsers && loaded) {updateUsers(value);}}}
            onValidateInput={validateInput}
            removeButtonAriaLabel={'Remove'}
            inputProps={{ 
                onBlur: (ev) => {},
                onFocus: (ev) => {},
                'aria-label': 'People Picker',
            }}
            componentRef={picker}
            onInputChange={onInputChange}
            resolveDelay={300}
            disabled={isPickerDisabled}
        /> 
      </div>
    );
  };
  
  function doesTextStartWith(text, filterText) {
    return text.toLowerCase().indexOf(filterText.toLowerCase()) === 0;
  }
  
  function removeDuplicates(personas, possibleDupes) {
    return personas.filter(persona => !listContainsPersona(persona, possibleDupes));
  }
  
  function listContainsPersona(persona, personas) {
    if (!personas || !personas.length || personas.length === 0) {
      return false;
    }
    return personas.filter(item => item.text === persona.text).length > 0;
  }
  
  function convertResultsToPromise(results) {
    return new ((resolve, reject) => setTimeout(() => resolve(results), 2000));
  }
  
  function getTextFromItem(persona) {
    return persona.text;
  }
  
  function validateInput(input) { 
    if (input.indexOf('@') !== -1) {
      return ValidationState.valid;
    } else if (input.length > 1) {
      return ValidationState.warning;
    } else {
      return ValidationState.invalid;
    }
  }
  
  /**
   * Takes in the picker input and modifies it in whichever way
   * the caller wants, i.e. parsing entries copied from Outlook (sample
   * input: "Aaron Reid <aaron>").
   *
   * @param input The text entered into the picker.
   */
  function onInputChange(input) {
    const outlookRegEx = /<.*>/g;
    const emailAddress = outlookRegEx.exec(input);
  
    if (emailAddress && emailAddress[0]) {
      return emailAddress[0].substring(1, emailAddress[0].length - 1);
    }
  
    return input;
  }
  
  
export default GraphPeoplePicker;