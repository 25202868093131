import React, { useEffect, useState } from 'react';
import { CustomAppBar } from '../components/UserProfilePopper';
import { AppConfig } from '../utils/config.utils';
import ReactJson from 'react-json-view';
import {
    Stack,
    CommandBarButton,
    Label,
} from '@fluentui/react';
import { 
    Chip, 
    Link,
    Divider 
} from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { 
    makeStyles,
} from '@material-ui/core/styles'; 
import FaceIcon from '@material-ui/icons/Face';

const axios = require('axios');
const axiosInstance = axios.create({
    baseURL: `${AppConfig.PUBLIC_API_URL}/api/latest`, 
    headers: {'Content-Type': 'application/json'}
});

const queryString = require('query-string');

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: '#FFFFFF',
        border: 'solid 1px #E0E0E0',
        height: '100%',
        textAlign: 'left',
        borderRadius: '6px',
        paddingTop: theme.spacing(1),
        paddingLeft: theme.spacing(2), 
        paddingRight: theme.spacing(2), 
        paddingBottom: theme.spacing(2), 
    }, 
})); 


async function getDocument(key) {
    return new Promise((resolve, reject) => { 
        axiosInstance.get(`/issue?key=${key}&code=${AppConfig.PUBLIC_API_CODE}`, {})
        .then(function (response) { 
            resolve(response.data);
        })
        .catch(function (error) { 
            reject(error);
        });
    });
  }

const ServiceOrdersApp = (props) => {
    const classes = useStyles();

    const { 
        x_jjv,         // 'true' or 'false' to indicate whether we should render the JSON browser for the issue
        x_fcs,          // 'approvals', 'products', 'simple'
    } = queryString.parse(props.location.search);

    const { amo } = props.match.params; 

    const [data, setData] = useState({});
    // const [showJson, setShowJson] = useState(false);
    
    useEffect(() => {        
        if (amo && amo.length > 0 && amo.split('-').length === 2) {
            getDocument(amo) 
            .then((result) => {
                console.log(result);
                setData(result);
            })
            .catch(console.log);
        } else {
            console.log('Invalid key format')
        }
    }, [amo]);

    // function getStatusColor() {
    //     if (!data || !data.Status) {
    //         return undefined;
    //     }
    //     var resolvedStatuses = ['complete', 'abandoned', 'created', 'passed', 'failed', 'resolved', ]
    //     if (resolvedStatuses.includes(data.Status.name.toLowerCase())) {
    //         return '#36B37E';
    //     }
    //     return '#0052CC';
    // }

    function RenderApprovalInfo() {
        if (!data) {
            return;
        }
        if (data.Status && data.Status.name === 'Creating Service Order') {
        
            return (
                <Alert severity={'info'}>
                    <AlertTitle>Service Order Approval</AlertTitle>
                    <>The service order is currently being created for {data.id}. Once complete, it will be submitted for approval by Sales.</>
                </Alert>   
            );
        }
        if (!data['Approvals'] || data['Approvals'].length === 0) { 
            return;
        } 
        const soApprovals = data['Approvals'].filter(x => x.name === 'Reviewing Service Order');
        if (soApprovals.length === 0) { 
            return;
        }
        var appear = 'success', text = '';
        switch (soApprovals[0].finalDecision) {
            case 'pending':
                appear = 'warning';
                text = (<>The service order for {data.id} is <strong>pending</strong> approval by Sales.</>);
                break;
            case 'approved':
                appear = 'success';
                text = (<>The service order for {data.id} is <strong>approved</strong> for invoicing. {data.property['latestReportPackage'] && <>You can download the service order by clicking <Link style={{cursor: 'pointer'}} onClick={() => {window.location.href = AppConfig.PUBLIC_API_URL + '/api/latest/attachments?container=attachments&path=' + data.property['latestReportPackage'] + '&code=' + AppConfig.PUBLIC_API_CODE;}}>here</Link>.</>}</>);
                break;
            case 'declined': 
                appear = 'error';
                text = (<>The service order for {data.id} has been <strong>declined</strong> and is currently being updated.</>);
                break; 
        }
        return ( 
            <div>
                {soApprovals[0].approvers.length > 0 && 
                    <div style={{display: 'flex', marginBottom: '16px', marginTop: '16px'}}>
                        <Label>Approvers:</Label>
                        {soApprovals[0].approvers.map((ap,idx) => { 
                            return (<Chip clickable variant="outline" icon={<FaceIcon />} style={{marginLeft: '8px'}} label={ap.approver.displayName}/>)
                        })}
                    </div>
                }
                <Alert severity={appear}>
                    <AlertTitle>Service Order Approval</AlertTitle>
                    {text} 
                </Alert>   
            </div>
        )
    }

    return (
        <CustomAppBar>
            <div className={classes.root}>
                <div style={{display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center'}}>
                    <div style={{display: 'flex', flexDirection: 'row', }}>
                        <Stack horizontal>
                            <Label style={{fontSize: '18px', minWidth: '88px'}}>
                                {data.id}
                            </Label>
                            <div style={{display: 'flex', margin: '4px 8px'}}>
                                <Divider orientation="vertical" style={{backgroundColor: '#32313088'}}/>
                            </div>
                            <Label style={{fontSize: '18px', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap'}}>
                                {data['Summary']}
                            </Label>
                        </Stack>
                        {(data && data.Status) && <Chip color="primary" clickable style={{borderRadius: '4px', fontWeight: 500, fontFamily: 'Segoe UI', marginLeft: '16px'}} label={(data && data.Status) && data.Status.name}/>}
                    </div>
                    <Stack horizontal styles={{ root: { height: 44, marginBottom: '8px', minWidth: '300px' } }}>
                        <CommandBarButton
                            disabled={!data || !data.property || !data.property['latestReportPackage']}
                            iconProps={{ iconName: 'Download' }}
                            text="Download Service Order"
                            onClick={() => {
                                if (data && data.property && data.property['latestReportPackage']) {
                                    console.log(data.property['latestReportPackage']);
                                    window.location.href = AppConfig.PUBLIC_API_URL + '/api/latest/attachments?container=attachments&path=' + data.property['latestReportPackage'] + '&code=' + AppConfig.PUBLIC_API_CODE;
                                }
                            }}/>
                        <CommandBarButton 
                            disabled={!data || !data.id}
                            iconProps={{ iconName: 'PageArrowRight' }}
                            text="View in Jira" 
                            onClick={() => {
                                if (data && data.property && data.property['latestReportPackage']) {
                                    window.location.href = 'https://evolutioneng.atlassian.net/browse/' + data.id;
                                }
                            }}/> 
                    </Stack>
                </div>
              
                {(data && data['Client']) && 
                    <div style={{ width: '100%' }}>  
                        <div style={{ display: 'flex', flexDirection: 'row', width: '100%', }}>
                            <div style={{marginRight: '32px'}}>
                                <Label>Location:</Label>
                                <Label style={{fontWeight: 400}}>{data['Service Location'] || 'None'}</Label>
                            </div>
                            <div style={{marginRight: '32px'}}>
                                <Label>Customer PO #:</Label>
                                <Label style={{fontWeight: 400}}>{data['Client PO Number'] || "None"}</Label>
                            </div>
                            <div style={{marginRight: '32px'}}>
                                <Label>Reference #:</Label>
                                <Label style={{fontWeight: 400}}>{data['Client Reference Number'] || "None"}</Label>
                            </div>
                        </div>
                        <div style={{marginTop: '16px'}}>
                        <Label>Service Notes:</Label>
                        <Label style={{fontWeight: 400}}>{data['Service Notes'] || 'None'}</Label>
                        </div>
                        {RenderApprovalInfo()}
                    </div> 
                }
               
            </div>

            {x_jjv==='true' && 
                <div style={{
                        backgroundColor: '#002B36',
                        padding: '12px', 
                        textAlign: 'left', 
                        marginTop: '16px', 
                        borderRadius: '6px'
                    }}>
                    <ReactJson 
                        src={data}
                        theme="solarized"/>
                </div>
            }
        </CustomAppBar>
    )
}

export default ServiceOrdersApp;