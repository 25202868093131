
var graph = require('@microsoft/microsoft-graph-client');
require('isomorphic-fetch');

function getAuthenticatedClient(accessToken) {
    // Initialize Graph client
    const client = graph.Client.init({
      // Use the provided access token to authenticate
      // requests
      authProvider: (done) => {
        done(null, accessToken);
      }
    });
  
    return client;
  }
  
  export async function getUserDetails(accessToken) {
      const client = getAuthenticatedClient(accessToken); 
      const user = await client
        .api('/me') 
        .select('id,jobTitle,mail,displayName,userPrincipalName')
        .get();
      return user;
    }

    export async function userProfilePhoto(accessToken, id) {
      try {
        const client = getAuthenticatedClient(accessToken); 
        const user = await client
          .api(`/users/${id}/photo/$value`)
          .get();
        return user;
      } catch (error) {
    //    console.log(error.message);
        return null;
      }
    }

    export async function myProfilePhoto(accessToken) {
      const client = getAuthenticatedClient(accessToken); 
      const user = await client
        .api('/me/photo/$value') 
        .get();
      return user;
    }

    export async function myManager(accessToken) {
      const client = getAuthenticatedClient(accessToken); 
      const user = await client
        .api('/me/manager') 
        .get();
      return user;
    }

    export async function myDirectReports(accessToken) {
      const client = getAuthenticatedClient(accessToken); 
      const user = await client
        .api('/me/directReports') 
        .get();
      return user;
    }

    export async function userAllPresences(accessToken, ids) {
      const client = getAuthenticatedClient(accessToken); 
      const presences = await client
        .api('/communications/getPresencesByUserId')
        .post({
          ids: ids
        });
      // const user = await client
      //   .api(`/users/${id}/presence`) 
      //   .get();
      return presences;
    }

    export async function searchUsers(accessToken, param) {
      const client = getAuthenticatedClient(accessToken); 
      const user = await client
        .api('/users') 
        // .version('beta') 
        .filter(`startsWith(displayName,'${param}')`) 
        .count(true) 
        .top(10)
        .get();
        if (user.value && user.value.length > 0) {
          user.value = user.value.filter(x => x.mail && x.mail.includes('@evolutioneng.com'));
        }
      return user;
    } //https://graph.microsoft.com/v1.0/users?$count=true&$search="displayName:rob"&$filter=endsWith(mail,'evolutioneng.com')&$orderBy=displayName&$select=id,displayName,mail

