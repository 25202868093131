import React, { useEffect, useState, useCallback, useContext } from 'react'; 
import debounce from 'lodash/debounce'

import { fade, makeStyles } from '@material-ui/core/styles';
 
import {
    Search
} from '@material-ui/icons';

import SearchIcon from '@material-ui/icons/Search';

import AssetResult from './AssetResult'
import EventResult from './EventResult'
 
import {
    Popper,
    Popover, 
    Paper, 
    Grid, 
    Typography,
    Button,
    Fade, 
    InputBase,
    IconButton,
    Divider,
    List,
    ListItem,
    ListItemIcon,
    Chip,
    ListItemText,
    ListItemSecondaryAction,
} from '@material-ui/core'; 

import { 
    Label, Stack
} from '@fluentui/react';

import { AppConfig } from '../../utils/config.utils';
import { AppContext, AppContextState } from '../../contexts/AppContext'; 

const axios = require('axios');
const axiosInstance = axios.create({
  baseURL: `${AppConfig.PUBLIC_API_URL}/api/latest`, 
  headers: {'Content-Type': 'application/json'}
});

async function getIssues(text) {
    return new Promise((resolve, reject) => { 
      axiosInstance.get(`/issuePicker?key=${text}&code=${AppConfig.PUBLIC_API_CODE}`, {})
      .then(function (response) {
        // console.log(response);  
        resolve(response.data);
      })
      .catch(function (error) {
        console.log(error);
        reject(error);
      });
    });
}

const useStyles = makeStyles((theme) => ({
    root: { 
        maxWidth: '500px',  
        margin: theme.spacing(1, 1, 0, 1)
    },
    searchbar: {
        
        // width: '75%',
        // maxWidth: '500px',
        // marginTop: "30px"
    },
    popper: {
        width: '75%',
        marginLeft: '-5ch',
    },
    paper: {
        backgroundColor: "#FBFBFB",
        padding: "15px"
    },
    viewAllButton: {
        float: 'right',
        color: "#15ADFF",
        display: 'inline-block'
    },
    divider: {
        marginTop: "5px",
        marginBottom: "10px"
    }
}));

export default function Searchbar() {
    // const anchorEl = React.useRef(null);
    // const [searchOpen, setSearchOpen] = useState(false);
    // const applicationState = useContext(AppContextState);
    /* Delay API call on user input by 500ms */
    const debounceSearch = useCallback(debounce(event =>
        setState({
            ...state, 
            searchTerm: event.value, 
            anchor: event,
            viewWidth: event.clientWidth,
        })
        , 500), [])

    const classes = useStyles();

    const [state, setState] = useState({
        searchTerm: '',
        assetResult: [],
        eventResult: [],
        resultsOpen: false,
        anchor: null,
        eventsOpen: false,
    })

    /* Fuzzy search assets using API call */
    useEffect(() => {
        if (state.searchTerm && state.searchTerm.length > 0) {
            // var valid = false;
            // const lookup = ['AMO', 'ANA', 'DWR', 'ENG', 'FTR', 'HSES', 'EIP', 'TLA', 'RLBT', 'RMA', 'SHIP', 'TSDI'];
            // for (let i = 0; i < lookup.length; i++) {
            //     if (state.searchTerm.toUpperCase().includes(lookup[i])) {
            //         valid = true;
            //         break;
            //     }
            // }
            // if (valid) {
                getIssues(state.searchTerm) 
                .then((data) => {
                    if (data && data.length > 0 && data.map) {
                        // console.log(data);
                        setState(s => ({
                            ...s,
                            resultsOpen: true,
                            eventResult: data
                        }))
                    } else {
                        console.log('ARRAY_ERROR', data);
                    }
                })
                .catch(console.log);
            // } else {
            //     console.log('Invalid search term for issue browser', state.searchTerm);
            // }
        } else {
            console.log('No searches...')
        }
        // console.log(AppConfig.PUBLIC_API_URL);
        
        // const searchAssets = async (serial) => {
        //     const result = await fetch(`${AppConfig.PUBLIC_API_URL}/assets?search=${serial}&limit=3`);
        //     if (result.status < 300) {
        //         const json = await result.json();
        //         return json.data;
        //     }
        //     else {
        //         return [];
        //     }

        // };

        // const searchEvents = async (key) => {
        //     const result = await fetch(`${AppConfig.PUBLIC_API_URL}/events?search=${key}&limit=3`);
        //     if (result.status < 300) {
        //         const json = await result.json();
        //         return json.data;
        //     } else {
        //         return [];
        //     }
        // };

        // if (state.searchTerm) {
        //     searchAssets(state.searchTerm)
        //     .then(result => {
        //         setState(s => ({
        //             ...s,
        //             resultsOpen: true,
        //             assetResult: result
        //         }))
        //     })
        //     .catch((error) => {
        //         console.log(error);
        //     });

        //     searchEvents(state.searchTerm)
        //     .then(result => {
        //         setState(s => ({
        //             ...s,
        //             resultsOpen: true,
        //             eventResult: result
        //         }))
        //     })
        //     .catch((error) => {
        //         console.log(error);
        //     });

        // } else {
        //     setState(s => ({
        //         ...s,
        //         resultsOpen: false,
        //         assetResult: [],
        //         eventResult: []
        //     }))
        // }

    }, [state.searchTerm])

    const handleChange = (event, value) => {
        // console.log(event.target, value);  
        debounceSearch(event.target, value);
    }

    function handleOnSubmit(value) {  
        const apiKey = '0Es4TzSzdRm1cx133xxlGpVsx5X6yYvaIsaeuyczvA33fQFStPKxZA==';
        const restApi = `https://evolutiondeveloperfunctionapp.azurewebsites.net/api/1/search?text=${value}&code=${apiKey}`;
        window.location.href = restApi; 
    }

    function handleClose() {
        setState({
            ...state,  
            anchor: null, 
            resultsOpen: false,
        })
    }

    // useEffect(() => {
    //     console.log(state);
    // }, [state])

    return ( 
        <>
            <div className={classes.root}>
                <FormTextField placeholder='Search...' onChange={handleChange} onSubmit={handleOnSubmit}/> 
            </div>
            <Popover
                id={state.resultsOpen ? 'simple-popover' : undefined}
                onClose={handleClose} 
                open={state.resultsOpen} 
                anchorEl={state.anchor}
                anchorReference="anchorPosition"
                anchorPosition={{ top: 54, left: 209 }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}> 
                    <Paper elevation={2} style={{width: state.viewWidth+62}}>
                        {state.eventResult && state.eventResult.map && state.eventResult.length > 0 && 
                            <List dense>
                            {state.eventResult.map((ev, idx) => {
                                // console.log(ev)
                                return (
                                    <ListItem key={idx} onClick={() => {window.location.href=AppConfig.PUBLIC_URL + '#/browse/issues/' + ev.id}} id={idx} button alignItems="flex-start">
                                        <ListItemText 
                                            primary={
                                                <Stack horizontal>
                                                    <Label>
                                                        {ev.id}
                                                    </Label>
                                                    <div style={{width:'8px'}}/>
                                                    <Chip 
                                                        style={{marginTop:'4px', fontFamily:'Segoe UI'}}
                                                        variant="outlined" 
                                                        size="small" 
                                                        label={ev.Status.name}/>
                                                    {/* <div style={{padding: '0px 4px', border: 'solid 1px #8777D9', backgroundColor:'#C0B6F2', borderRadius:'4px'}}> */}
                                                        {/* <Label> */}
                                                            {/* {ev.Status.name} */}
                                                        {/* </Label> */}
                                                    {/* </div> */}
                                                </Stack>
                                            } 
                                            secondary={
                                                <>
                                                    <Typography style={{fontFamily:'Segoe UI', fontSize: '14px'}}>{ev.Summary}</Typography>
                                                </>
                                            } /> 
                                        {/* <ListItemSecondaryAction>
                                            {ev.Status.name}
                                        </ListItemSecondaryAction> */}
                                    </ListItem>
                                )
                            })}
                            </List>
                        }
                        
                        {/* <Typography className={classes.typography}>The content of the Popper.</Typography> */}
                    </Paper>
               
            </Popover >  

            {/* <SearchPopper width={state.viewWidth} open={state.resultsOpen} anchorEl={state.anchor}/> */}
        </>

        // <>
        //     <SearchPopper width={state.viewWidth} open={state.resultsOpen} anchorEl={state.anchor}/>
        //     {/* <FormTextField placeholder='Search' onChange={handleChange} onSubmit={handleOnSubmit}/> */}
       
        // </>
    )

    // return (
    //     <div>
    //         <Popper className={classes.popper} open={state.resultsOpen} anchorEl={state.anchor} placement='bottom' transition>

    //             {({ TransitionProps }) => (
    //                 <Fade {...TransitionProps} timeout={250}>
    //                     <Paper className={classes.paper}>
    //                         <Typography variant="h6" style={{ display: 'inline-block' }}>Search results</Typography>
    //                         <Link to={`/search/${state.searchTerm}`}>
    //                             <Typography className={classes.viewAllButton} variant="button" id="details-button">View All</Typography>
    //                         </Link>
    //                         <Typography variant="body1" align="left"><b>Products</b></Typography>
    //                         {
    //                             state.assetResult.length ?
    //                                 state.assetResult.map((item, idx) => (<AssetResult data={item} key={item.serial} divider={idx === (state.assetResult.length - 1) ? false : true} />))
    //                                 : <Typography variant="body1" align="center">No products found</Typography>
    //                         }
    //                         <Divider className={classes.divider} />
    //                         <Typography variant="body1" align="left"><b>Events</b></Typography>
    //                         {
    //                             state.eventResult.length ?
    //                                 state.eventResult.map(item => (<EventResult data={item} key={item.key} />))
    //                                 : <Typography variant="body1" align="center">No events found</Typography>
    //                         }
    //                     </Paper>
    //                 </Fade>
    //             )}

    //         </Popper>
            
    //         <FormControl className={classes.searchbar} variant="outlined" style={{ backgroundColor: "white" }}>
    //             <InputLabel htmlFor="searchbar">Search Jira for an issue key or product serial number</InputLabel>
    //             <OutlinedInput
    //                 id="searchbar"
    //                 type="text"
    //                 onChange={handleChange}
    //                 startAdornment={
    //                     <InputAdornment position="start"><Search /></InputAdornment>
    //                 }
    //                 labelWidth={250} />
    //         </FormControl>
    //     </div>
    // );
}

const useFormTextFieldStyles = makeStyles((theme) => ({
    root: {
      marginTop: '4px',
      display: 'flex',
      justifyContent: 'center',
      border: '1px solid #B3BAC5',
      alignItems: 'center',
      width: '100%', 
    //   maxWidth: 500,
      height: '38px',
    },
    input: {
      marginLeft: theme.spacing(1),
      flex: 1,
    },
    iconButton: {
      padding: 10,
    },
    divider: {
      height: 28,
      margin: 4,
    },
  }));

function FormTextField({placeholder, onSubmit, onChange}) {
    const classes = useFormTextFieldStyles();
    const [value, setValue] = useState('');

    function handleOnKeyPress(event) {
        if (event.key === 'Enter') {
            console.log('Submitted!')
            if (onSubmit && value.length > 0) onSubmit(event, value);
        }
    }
 
    return ( 
        <Paper elevation={0} className={classes.root}>
          {/* <IconButton className={classes.iconButton} aria-label="menu">
            <MenuIcon />
          </IconButton> */}
          <InputBase 
            onKeyPress={handleOnKeyPress}
            value={value} 
            onChange={(event) => { 
                setValue(event.target.value); if (onChange) onChange(event, event.target.value);
            }}
            className={classes.input}
            placeholder={placeholder}
            inputProps={{ 'aria-label': 'search' }}
          />
          <Divider className={classes.divider} orientation="vertical" />
          <IconButton onClick={() => onSubmit(value)} type="submit" className={classes.iconButton} aria-label="search">
            <Search />
          </IconButton>
          {/* <IconButton color="primary" className={classes.iconButton} aria-label="directions">
            <DirectionsIcon />
          </IconButton> */}
        </Paper> 
    );
}

const usePopperStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexGrow: 1,
        border: '1px solid #B3BAC5',  
        // width: '100%',
        height: '38px',
        cursor: 'text',
        // transition: theme.transitions.create('width', 'backgroundColor'),
        backgroundColor: theme.palette.common.white,
        //fade(theme.palette.common.white, 0.15),
        // '&:hover': {
        //   backgroundColor: fade(theme.palette.common.white, 1),
        // },
        [theme.breakpoints.up('sm')]: {
            width: '12ch',
            '&:focus': {
              width: '20ch',
            },
          },
    },
    typography: {
        padding: theme.spacing(2),
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius, 
        marginLeft: theme.spacing(1),
        // width: '100%',
        // width: 'auto', 
        // [theme.breakpoints.up('sm')]: {
        //   marginLeft: theme.spacing(1),
        //   width: 'auto',
        // },
    },
    searchIcon: {
        // padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit', 
        width: '100%',
        // paddingLeft: theme.spacing(1),
    },
    inputInput: {
        textAlign: 'left',
        paddingTop: '8px',
        paddingLeft: theme.spacing(4),
        fontFamily: 'Segoe UI',
        // padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        // paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        // transition: theme.transitions.create('width'),
        width: '100%',
        // [theme.breakpoints.up('sm')]: {
        //   width: '12ch',
        //   '&:focus': {
            // width: '20ch',
        //   },
        // },
    },
}));

function SearchPopper({open, anchorEl, width}) {
    // const [anchorEl, setAnchorEl] = React.useState(null);
    // const [open, setOpen] = React.useState(false);
    // const [placement, setPlacement] = React.useState('bottom');
    const classes = usePopperStyles();
    
    // const handleClick = (newPlacement) => (event) => {
    //     setAnchorEl(event.currentTarget);
    //     // setOpen((prev) => placement !== newPlacement || !prev);
    //     // setPlacement(newPlacement);
    // };

    function getPopperStyle() {
        // var root = classes.root;
        // if (width && width > 0) root.width = width;
        // console.log(root);
        return { 
            flexGrow: 1,
            // width: width || 500,
        };
    }

    return (
        <div className={classes.root}>
            <Popper open={open} anchorEl={anchorEl} placement={'bottom-start'} transition>
                {({ TransitionProps }) => (
                <Fade {...TransitionProps} timeout={350}>
                    <Paper style={{marginTop: '16px'}}>
                        <Typography className={classes.typography}>The content of the Popper.</Typography>
                    </Paper>
                </Fade>
                )}
            </Popper>  
            {/* <div className={classes.root}>  */}
                <div className={classes.search}>
                    <div className={classes.searchIcon}>
                        <SearchIcon />
                    </div>
                    <InputBase
                        placeholder="Search…"
                        classes={{
                            root: classes.inputRoot,
                            input: classes.inputInput,
                        }}
                        inputProps={{ 'aria-label': 'search' }}/>
                </div> 
            {/* </div> */}
        </div>
    )
}

