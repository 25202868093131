import React, { useState, useRef } from 'react'; 
import {
  // DetailsList,
  // DetailsListLayoutMode, 
  SpinButton,
  // SelectionMode, 
  // TooltipHost, 
  // Link,
  Stack,
  CommandBarButton,
  // Icon,
  Checkbox,
  TextField,
  // mergeStyleSets, 
  // Text,
} from '@fluentui/react';  
import { Typography } from '@material-ui/core';
// import GetAppIcon from '@material-ui/icons/GetApp';
// import moment from 'moment';
// import { AppConfig } from '../../utils/config.utils'; 
import { v4 as uuidv4 } from "uuid"; 
// import { getFileTypeIconProps, FileIconType } from '@uifabric/file-type-icons';
 import { DataGrid } from '@material-ui/data-grid';
// import Pagination from '@material-ui/lab/Pagination';
// import PaginationItem from '@material-ui/lab/PaginationItem';
import { GridOverlay } from '@material-ui/data-grid'; 
import { makeStyles } from '@material-ui/core/styles';
import {
  CustomTextField
} from '../CustomFields';

function customCheckbox(theme) {
  return {
    '& .MuiCheckbox-root svg': {
      width: 16,
      height: 16,
      backgroundColor: 'transparent',
      border: `1px solid ${
        theme.palette.type === 'light' ? '#d9d9d9' : 'rgb(67, 67, 67)'
      }`,
      borderRadius: 2,
    },
    '& .MuiCheckbox-root svg path': {
      display: 'none',
    },
    '& .MuiCheckbox-root.Mui-checked:not(.MuiCheckbox-indeterminate) svg': {
      backgroundColor: '#1890ff',
      borderColor: '#1890ff',
    },
    '& .MuiCheckbox-root.Mui-checked .MuiIconButton-label:after': {
      position: 'absolute',
      display: 'table',
      border: '2px solid #fff',
      borderTop: 0,
      borderLeft: 0,
      transform: 'rotate(45deg) translate(-50%,-50%)',
      opacity: 1,
      transition: 'all .2s cubic-bezier(.12,.4,.29,1.46) .1s',
      content: '""',
      top: '50%',
      left: '39%',
      width: 5.71428571,
      height: 9.14285714,
    },
    '& .MuiCheckbox-root.MuiCheckbox-indeterminate .MuiIconButton-label:after': {
      width: 8,
      height: 8,
      backgroundColor: '#1890ff',
      transform: 'none',
      top: '39%',
      border: 0,
    },
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexDirection: 'column', 
  },
  dg: { 
    width: '100%',
    borderBottom: 0,
    borderLeft: 0, 
    borderRight: 0,
    borderRadius: 0,
    color:  theme.palette.type === 'light' ? 'rgba(0,0,0,.85)' : 'rgba(255,255,255,0.85)',
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    WebkitFontSmoothing: 'auto',
    letterSpacing: 'normal',
    '& .MuiDataGrid-columnsContainer': {
      width: '100%', 
      backgroundColor: theme.palette.type === 'light' ? '#fafafa' : '#1d1d1d',
    },
    // '& .MuiDataGrid-iconSeparator': {
    //   width: '100%', 
    //   display: 'none',
    // },
    '& .MuiDataGrid-row': {
      '&:hover': {
        // width: '100%',
        backgroundColor: 'white'
      }
    },
    // '& .MuiDataGrid-colCell, .MuiDataGrid-cell': {
    //   width: '100%', 
    //   borderRight: `1px solid ${
    //     theme.palette.type === 'light' ? '#f0f0f0' : '#303030'
    //   }`,
    // },
    '& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell': {
      width: '100%', 
      borderBottom: `1px solid ${
        theme.palette.type === 'light' ? '#f0f0f0' : '#303030'
      }`,
    },
    // '& .MuiDataGrid-cell': {
    //   width: '100%', 
    //   color: theme.palette.type === 'light'
    //       ? 'rgba(0,0,0,.85)'
    //       : 'rgba(255,255,255,0.65)',
    // },
    '& .MuiPaginationItem-root': {
      borderRadius: 0,
    },
    ...customCheckbox(theme),
  },
  label: {
    marginTop: theme.spacing(1),
  },
}));

// function CustomPagination(props) {
//   const { state, api } = props;

//   return (
//     <Pagination
//       color="primary"
//       variant="outlined"
//       shape="rounded"
//       page={state.pagination.page}
//       count={state.pagination.pageCount}
//       // @ts-expect-error
//       renderItem={(props2) => <PaginationItem {...props2} disableRipple />}
//       onChange={(event, value) => api.current.setPage(value)}
//     />
//   );
// }

function CustomNoRowsOverlay() {
  const classes = useStyles(); 
  return (
    <GridOverlay className={classes.root}>
      <EmptyListPage title="Your manifest is empty!" body="Try adding something to your shipment."/>
    </GridOverlay>
  );
}


// function useRedirect() {
//   const [path, setPath] = useState(null); 
    
//     useEffect(() => {
//       if (path !== null) { 
//         window.location.href = path;
//         setPath(null);
//       }
//     }, [path]);

//     return [path, setPath];
// }
 
// const ProductT2able = ({label}) => { 
//   const mirroredItems = useRef([
//     {
//       id: uuidv4(), 
//       qty: 1,
//       icon: (<Icon {...getFileTypeIconProps({ extension: 'docx', size: 28, imqtyFileType: 'png' }) } />),
//       product: 'ELP-0276',
//       // premium: '',//'60.70 40% higher for BMI , 
//       description: 'Electronics Probe Assembly',
//       notes: 'some predefined notes'
//     }
//   ]);

//   const [path, setPath] = useRedirect();

//   const columns = [
//       {
//         key: 'column1',
//         name: 'Type',
//         className: msclassNames.fileIconCell,
//         iconClassName: msclassNames.fileIconHeaderIcon,
//         ariaLabel: 'Column operations for File type, Press to sort on File type',
//         iconName: 'Pqty',
//         isIconOnly: true,
//         fieldName: 'name',
//         minWidth: 28,
//         maxWidth: 28,
//         onColumnClick: (ev, c) => _onColumnClick(ev, c, columns, mirroredItems.current),
//         onRender: (item) => (
//           <TooltipHost content={`Assembly`}>
//             {item.icon}
//           </TooltipHost>
//         ),
//       },
//       {
//         key: 'column2',
//         name: 'qty',
//         fieldName: 'qty',
//         minWidth: 50,
//         maxWidth: 60,
//         isRowHeader: true,
//         isResizable: true,
//         isSorted: true,
//         isSortedDescending: false,
//         sortAscendingAriaLabel: 'Sorted ascending',
//         sortDescendingAriaLabel: 'Sorted descending',
//         onColumnClick: (ev, c) => _onColumnClick(ev, c, columns, mirroredItems.current),
//         onRender: (item) => (
//           <SpinButton  
//             onChange={(ev, value) => {   
//               _onItemChanged({...item, qty: value}); 
//             }}
//             defaultValue={item.qty}
//             min={1} 
//             step={1} 
//             incrementButtonAriaLabel={'Increase value by 1'}
//             decrementButtonAriaLabel={'Decrease value by 1'}/>
//         ),
//         data: 'number',
//         isPadded: true,
//       },
//       {
//         key: 'column3',
//         name: 'Product',
//         fieldName: 'product',
//         minWidth: 70,
//         maxWidth: 90,
//         isRowHeader: true,
//         isResizable: true,
//         isSorted: true,
//         isSortedDescending: false,
//         sortAscendingAriaLabel: 'Sorted A to Z',
//         sortDescendingAriaLabel: 'Sorted Z to A',
//         onColumnClick: (ev, c) => _onColumnClick(ev, c, columns, mirroredItems.current),
//         onRender: (item) => {
//           return ( 
//             <TextField value={item.product} onChange={(ev, value) => _onItemChanged({...item, product: value})}/> 
//           )
//         },
//         data: 'string',
//         isPadded: true,
//       },
//       {
//         key: 'column4',
//         name: 'Description',
//         fieldName: 'description',
//         minWidth: 150,
//         maxWidth: 230,
//         isRowHeader: true,
//         isResizable: true,
//         isSorted: true,
//         isSortedDescending: false,
//         sortAscendingAriaLabel: 'Sorted A to Z',
//         sortDescendingAriaLabel: 'Sorted Z to A',
//         onColumnClick: (ev, c) => _onColumnClick(ev, c, columns, mirroredItems.current),
//         data: 'string',
//         isPadded: true,
//         onRender: (item) => {
//             return (
//               <TooltipHost content={`Contains ${item.objectCount} items`}> 
//                   <Link key={item.eventKey} onClick={() => _onItemInvoked(item)}>
//                       {item.description}
//                   </Link>
//               </TooltipHost>
//             );
//         }
//       }, 
//       {
//         key: 'column5',
//         name: 'Notes',
//         fieldName: 'notes',
//         minWidth: 220,
//         maxWidth: 260,
//         isRowHeader: true,
//         isResizable: true,
//         isSorted: true,
//         isSortedDescending: false,
//         sortAscendingAriaLabel: 'Sorted A to Z',
//         sortDescendingAriaLabel: 'Sorted Z to A',
//         onColumnClick: (ev, c) => _onColumnClick(ev, c, columns, mirroredItems.current),
//         data: 'string',
//         isPadded: true,
//         onRender: (item) => {
//             return (
//               <TooltipHost content={`Contains ${item.objectCount} items`}>
//                   <TextField value={item.notes} onChange={(ev, value) => _onItemChanged({...item, notes: value})}/> 
//               </TooltipHost>
//             );
//         }
//       }, 
//   ];

//     function _onItemChanged(item) {
//       var idx = mirroredItems.current.findIndex(x => x.id === item.id); 
//       if (idx >= 0) mirroredItems.current[idx] = {...item}; 
//       // setItems([...mirroredItems.current]);
//       // console.log(mirroredItems.current);
//     }

//     function _onItemInvoked(item) {
//       setPath(`/browse/${item.eventKey}`); 
//     }

//     return (
//         <div style={{display: 'flex', width: '100%', justifyContent:'center',}}>
//             <div style={{display: 'flex', flexDirection: 'column', maxWidth: '942px'}}>
//                 <Typography style={{display: 'flex', fontWeight:550, fontSize:'18px', justifySelf: 'flex-start'}} variant="overline">{label}</Typography>
//                 <Divider style={{margin: '0px 0px'}}/>
//                 <DetailsList 
//                     items={mirroredItems.current}
//                     compact={true}
//                     columns={columns}
//                     selectionMode={SelectionMode.none}
//                     getKey={_getKey}
//                     setKey="none"
//                     layoutMode={DetailsListLayoutMode.fixedColumns}
//                     isHeaderVisible={true} 
//                     style={{backgroundColor: 'transparent'}} 
//                 /> 
//             </div>
//         </div>
//     ); 
// }
 
// const msclassNames = mergeStyleSets({
//     fileIconHeaderIcon: {
//       padding: 0,
//       fontSize: '16px',
//     },
//     fileIconCell: {
//       textAlign: 'center',
//       selectors: {
//         '&:before': {
//           content: '.',
//           display: 'inline-block',
//           verticalAlign: 'middle',
//           height: '100%',
//           width: '0px',
//           visibility: 'hidden',
//         },
//       },
//     },
//     fileIconImg: {
//       verticalAlign: 'middle',
//       maxHeight: '16px',
//       maxWidth: '16px',
//     },
//     controlWrapper: {
//       display: 'flex',
//       flexWrap: 'wrap', 
//     },
//     exampleToggle: {
//       display: 'inline-block',
//       marginBottom: '10px',
//       marginRight: '30px',
//     },
//     selectionDetails: {
//       marginBottom: '20px',
//     },
// });
 
// const LOREM_IPSUM = (
//     'lorem ipsum dolor sit amet consectetur adipiscing elit sed do eiusmod tempor incididunt ut ' +
//     'labore et dolore magna aliqua ut enim ad minim veniam quis nostrud exercitation ullamco laboris nisi ut ' +
//     'aliquip ex ea commodo consequat duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore ' +
//     'eu fugiat nulla pariatur excepteur sint occaecat cupidatat non proident sunt in culpa qui officia deserunt '
// ).split(' ');
// let loremIndex = 0;
// function _lorem(wordCount) {
//     const startIndex = loremIndex + wordCount > LOREM_IPSUM.length ? 0 : loremIndex;
//     loremIndex = startIndex + wordCount;
//     return LOREM_IPSUM.slice(startIndex, loremIndex).join(' ');
// } 
  
// const FILE_ICONS = [
//     { name: 'accdb' },
//     { name: 'audio' },
//     { name: 'code' },
//     { name: 'csv' },
//     { name: 'docx' },
//     { name: 'dotx' },
//     { name: 'mpp' },
//     { name: 'mpt' },
//     { name: 'model' },
//     { name: 'one' },
//     { name: 'onetoc' },
//     { name: 'potx' },
//     { name: 'ppsx' },
//     { name: 'pdf' },
//     { name: 'photo' },
//     { name: 'pptx' },
//     { name: 'presentation' },
//     { name: 'potx' },
//     { name: 'pub' },
//     { name: 'rtf' },
//     { name: 'spreadsheet' },
//     { name: 'txt' },
//     { name: 'vector' },
//     { name: 'vsdx' },
//     { name: 'vssx' },
//     { name: 'vstx' },
//     { name: 'xlsx' },
//     { name: 'xltx' },
//     { name: 'xsn' },
// ];
  
// function _randomFileIcon() {
//     const docType = FILE_ICONS[Math.floor(Math.random() * FILE_ICONS.length)].name;
//     return {
//         docType: docType,
//         url: `https://static2.sharepointonline.com/files/fabric/assets/item-types/16/${docType}.svg`,
//     };
// } 
   
// function _getKey(item, index) {
//     return item.key;
// }
 
// function _onColumnClick (ev, column, columns, items) {
//     // const { columns, items } = this.state;
//     const newColumns = columns.slice();
//     const currColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
//     newColumns.forEach((newCol) => {
//     if (newCol === currColumn) {
//         currColumn.isSortedDescending = !currColumn.isSortedDescending;
//         currColumn.isSorted = true;
//         // this.setState({
//         //   announcedMessqty: `${currColumn.name} is sorted ${
//         //     currColumn.isSortedDescending ? 'descending' : 'ascending'
//         //   }`,
//         // });
//     } else {
//         newCol.isSorted = false;
//         newCol.isSortedDescending = true;
//     }
//     });
//     const newItems = _copyAndSort(items, currColumn.fieldName, currColumn.isSortedDescending);
//     // this.setState({
//     //   columns: newColumns,
//     //   items: newItems,
//     // });
// };
  
// function _copyAndSort(items, columnKey, isSortedDescending) {
//     const key = columnKey;
//     return items.slice(0).sort((a, b) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
// }
    
// // function _generateDocuments(source) {
// //     const items = [];
// //     if (source) {
// //         for (let i = 0; i < source.length; i++) {
// //             let item = source[i]; 
// //             item.iconName = _randomFileIcon();   
// //             items.push(item);
// //         }
// //         const key = 'dateCreatedValue';
// //         const isSortedDescending = true;
// //         return items.sort((a, b) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));;
// //     }
// //     return []; 
// // }

// function getSummary(item) {
//     if (!item || !item.eventData || !item.eventData.From || !item.eventData.To) {
//       // console.log(item);
//       return `Shipment (not specified)`;
//     } 
//     if (item.eventData.From.Company && !item.eventData.To.Company) return `Shipment from ${item.eventData.From.Company}`
//     if (!item.eventData.From.Company && item.eventData.To.Company) return `Shipment to ${item.eventData.To.Company}`
//     if (item.eventData.From.Company && item.eventData.To.Company) return `Shipment from ${item.eventData.From.Company} to ${item.eventData.To.Company}`
//     return `Shipment (not specified)`;
// }

// // export default ProductTable;



export default function ProductTable({items, isModal, setItems, overrideSave}) {
  const classes = useStyles();
  const itemsRef = useRef([...items]);
  const itemsBackupRef = useRef([...items]);
  const [isEditing, setIsEditing] = useState(true);
  const [selectedItems, setSelectedItems] = useState([]); 

  React.useEffect(() => {
    if (overrideSave === true) {
      if (isEditing === true) {
        saveTable();
      }
    }
  }, [overrideSave]);

  const columns = [ 
    {
      hide: !isEditing,
      field: 'checked',
      headerName: '✔',
      type: 'string',
      width: 60,
      renderCell: (params) => {
        return (
          <Checkbox 
            defaultChecked={params.value} 
            onChange={(ev, checked) => { 
              _setValue(params, checked, true);  
            }}/>
        )
      }
    },
  { 
    field: 'qty',
    headerName: 'Quantity', 
    type: 'string',
    width: 110,
    renderCell: (isEditing) ? ((params) => { 
      return (
        <div style={{width: '100%'}}>
          <CustomTextField
            placeholder="1"
            value={(params.value && params.value.length > 0) ? params.value : "1"}
            setValue={(value) => {
              // console.log(params, value)
              _setValue(params, value); 
            }}
          /> 
        </div>
      )
    }) : undefined
  },
  { 
    field: 'label',
    headerName: 'Product', 
    width: 130,
    renderCell: (isEditing) ? ((params) => {
      return (
        <div style={{width: '100%'}}>
          <CustomTextField
            placeholder="Enter a serial..."
            value={params.value}
            setValue={(value) => {
              // console.log(params, value)
              _setValue(params, value); 
            }}
          /> 
        </div>
      )
    }) : undefined
  },
  { 
    field: 'description', 
    headerName: 'Description', 
    width: 250,
    renderCell: (isEditing) ? ((params) => { 
      return (
        <div style={{width: '100%'}}>
          <CustomTextField
            placeholder="Enter a description..."
            value={params.value}
            setValue={(value) => {
              // console.log(params, value)
              _setValue(params, value); 
            }}
          /> 
        </div>
      )
    }) : undefined 
  }, 
  {
    field: 'summary',
    headerName: 'Notes',
    description: 'This column has a value getter and is not sortable.',
    sortable: false,
    width: '100%',
    renderCell: (isEditing) ? ((params) => { 
      return (
        <div style={{width: '100%'}}>
        <CustomTextField
          placeholder="Enter a summary..."
          value={params.value}
          setValue={(value) => {
            console.log(params, value)
            _setValue(params, value); 
          }}
        /> 
      </div>
      )
    }) : (params) => (<div style={{width: '100%'}}>{params.getValue('summary')}</div>)
    // valueGetter: (params) =>
    //   `${params.getValue('description') || ''} ${params.getValue('productId') || ''}`,
  },
]; 

  function _setValue(params, value, sel) {  
    var idx = itemsRef.current.findIndex(x => x.id === params.row.id);
    if (idx >= 0) {
      itemsRef.current[idx][params.field] = value; 
      setItems([...itemsRef.current]);
    } 
    if (sel===true) { 
      setSelectedItems(itemsRef.current.filter(x => x.checked));
    }
  } 

  function ButtonWrapper({children}) {
    return (
      <div style={{marginTop: '8px'}}>
      <Stack vertical wrap>
        <Stack horizontal wrap styles={{ root: { height: 44 } }}>
          <CommandBarButton 
            disabled={items.length >= 20}
            iconProps={{ iconName: 'Add' }}
            text="Add" 
            onClick={handleAdd}
            /> 
          {/* <CommandBarButton 
            disabled={!items || items.length === 0}
            iconProps={{ iconName: (isEditing) ? 'Save' : 'Edit' }}
            text={(isEditing) ? "Save" : 'Edit'}
            onClick={handleEdit}
            />  */}
          {/* <CommandBarButton 
            disabled={!isEditing}
            iconProps={{ iconName: 'Undo' }}
            text="Cancel" 
            onClick={handleCancel}
            />  */}
          <CommandBarButton 
            disabled={!(isEditing && selectedItems.length > 0)}
            iconProps={{ iconName: 'Delete' }}
            text="Remove" 
            onClick={handleDelete}
            /> 
          <CommandBarButton 
            disabled={!(isEditing && selectedItems.length < items.length)}
            iconProps={{ iconName: 'MultiSelect' }}
            text="Select All" 
            onClick={() => {
              itemsRef.current = itemsRef.current.map(x => ({...x, checked: true}));
              setSelectedItems(itemsRef.current.filter(x => x.checked));
              setItems([...itemsRef.current]);
              // setItems([...items.map(x => ({...x, checked: true}))])
              // setSelectedItems([...items])
            }}
            /> 
          <CommandBarButton 
            disabled={!(isEditing && selectedItems.length > 0)}
            iconProps={{ iconName: 'ClearSelection' }}
            text="Clear Selection" 
            onClick={() => {
              itemsRef.current = itemsRef.current.map(x => ({...x, checked: false}));
              setSelectedItems([]);
              setItems([...itemsRef.current]);
            }}
            /> 
        </Stack>
        {children}
      </Stack>
    </div>
    )
  }

  function handleCancel() {
    setItems([...itemsBackupRef.current]);
    setSelectedItems([]);
    setIsEditing(false);
  }

  function handleAdd() {
    if (items.length >= 20) {
      return;
    }
    setIsEditing(true);
    setSelectedItems([]);
    itemsBackupRef.current = [...items]; 
    itemsRef.current = [{
      id: uuidv4(), 
      qty: "1",
      // icon: (<Icon {...getFileTypeIconProps({ extension: 'docx', size: 28, imageFileType: 'png' }) } />),
      label: '', 
      description: '',
      summary: '',
      checked: false,
    },
    ...items]; 
    setItems([...itemsRef.current]); 
  } 

  function saveTable() {
    setItems([...itemsRef.current.map(x => ({...x, checked: false}))]);
    setSelectedItems([]);
    setIsEditing(false); 
  }

  function handleEdit() {
    if (!isEditing) {
      // console.log(items);
      itemsBackupRef.current = [...items];
      itemsRef.current = [...items]; 
      setIsEditing(true);
    } else {
      saveTable();
    }
  }

  function handleDelete() {
    console.log(itemsRef.current.filter(x=>x.checked));
    itemsRef.current = itemsRef.current.filter(x => !x.checked);
    setItems([...itemsRef.current]); 
  }  

  return (
    <ButtonWrapper>
      <div style={{ height: (isModal) ? '280px':'465px', width: '100%' }}>
        <DataGrid 
          className={classes.dg}
          components={{
            NoRowsOverlay: CustomNoRowsOverlay, 
          }}
          
          density="compact"
          rows={items} 
          columns={columns.map(x => ({...x, disableClickEventBubbling: true}))} 
          pqtySize={20} 
          pageSize={20}
          disableColumnMenu  
           />
      </div>
    </ButtonWrapper>
  );
}


function EmptyListPage({title, body}) {
  return (
    <div style={{marginTop:'50px', display: 'flex', flexDirection: 'column', justifyContent: 'center', textAlign: 'center'}}>
      <img height='128px' src='/queue-empty.svg'/>
      <Typography style={{fontWeight:600, marginTop: '16px', marginLeft: '16px', fontFamily: 'Segoe UI'}} variant='h6'>{title}</Typography>
      <Typography style={{fontFamily: 'Segoe UI', marginTop: '16px'}} variant='body2'>{body}</Typography>
    </div>
  )
}