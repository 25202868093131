import React, { useEffect, useState } from 'react'; 
import Typography from '@material-ui/core/Typography';  
import CreateShipment from './CreateShipment';
import CreateJob from './CreateJob';
import BaseDocumentView from '../components/BaseDocumentView';
import AppHeader from '../components/AppHeader'; 
import { AppConfig } from '../utils/config.utils';
import useLocalStorage from '../utils/auth/useLocalStorage.hook';
import { CustomAppBar } from '../components/UserProfilePopper'; 
import {
    Breadcrumb,Label
} from '@fluentui/react';

const API_CODE = AppConfig.PUBLIC_API_CODE;
const API_URL = AppConfig.PUBLIC_API_URL;
const axios = require('axios');
const axiosInstance = axios.create({
  baseURL: `${API_URL}/api/latest`, 
  headers: {'Content-Type': 'application/json'}
});

// async function createDocument(appId, userInfo) { 
//   return new Promise((resolve, reject) => {
//     axiosInstance.post(`/${appId.toLowerCase()}/document?code=${API_CODE}`, {
//       user: userInfo,
//       appId: appId,
//     })
//     .then(function (response) {
//       console.log(response); 
//       resolve(response.data.key); 
//     })
//     .catch(function (error) {
//       console.log(error);
//       reject(error);
//     });
//   }); 
// }

async function updateDocument(doc) {
  return new Promise((resolve, reject) => { 
    axiosInstance.put(`/document?code=${AppConfig.PUBLIC_API_CODE}`, doc)
    .then(function (response) {
      // console.log(response); 
      resolve();
    })
    .catch(function (error) {
      console.log(error);
      reject(error);
    });
  });
} 

async function getDocument(appId, data) {
  return new Promise((resolve, reject) => { 
    axiosInstance.get(`/document?key=${data.key}&code=${API_CODE}`, {})
    .then(function (response) {
      console.log(response);  
      resolve(response.data);
    })
    .catch(function (error) {
      console.log(error);
      reject(error);
    });
  });
}

async function deleteDocument(data) {
  return new Promise((resolve, reject) => { 
    axiosInstance.delete(`/document?key=${data.key}&code=${API_CODE}`, {})
    .then(function (response) {
      console.log(response);  
      resolve(response.data);
    })
    .catch(function (error) {
      console.log(error);
      reject(error);
    });
  });
}

const DocumentBrowser = (props) => {
    const { key } = props.match.params; 
    const [validKeys, setValidKeys] = useState(['JOB', 'SHIP'])
    const [document, setDocument] = useState({}); 
    const [loading, setLoading] = useState(true);
    const [valid, setValid] = useState('UNKNOWN'); 
    const [local, setLocal] = useLocalStorage('user', {});  

    useEffect(() => { 
        if (valid !== 'UNKNOWN') {
            return;
        }
        if (key && key.includes('-')) {
            const documentKey = key.split('-'); 
            if (documentKey && documentKey.length === 2) { // format should be '{type}-{index}' 
                documentKey[0] = documentKey[0].toUpperCase();
                if (validKeys.includes(documentKey[0])) {
                    // The doc key looks good, try to get the document!
                    setValid('DOC_ACCEPTED_APP_ID'); 
                    fetch(`${AppConfig.PUBLIC_API_URL}/api/latest/ship/document?key=${documentKey[0]}-${documentKey[1]}&code=${AppConfig.PUBLIC_API_CODE}`)
                    .then(response => {
                        if (response.status < 300) {
                            return response.json();
                        } else {
                            return null;
                        }
                    })
                    .then(json => { 
                        if (json) { 
                            if (json && json.id === `${documentKey[0]}-${documentKey[1]}`) { 
                                json.appId = documentKey[0];
                                setDocument(json);
                            } else {
                                setValid('DOC_REJECTED');
                                setDocument(null);
                            } 
                        } else {
                            setValid('DOC_REJECTED');
                        }
                        setLoading(false);
                    }); 
                } else {
                    // we don't recognize the key, so we shouldn't bother trying to get a document
                    setValid('DOC_REJECTED');
                    setLoading(false);
                }
            } else {
                // invalid key or key format
                    setValid('DOC_REJECTED');
                    setLoading(false);
            }
        } else {
            console.log(key)
            // invalid key or key format
            if (!key) {
                // route the user to a general browser
                    setValid('BROWSE_ACCEPTED');
                    setLoading(false);
            } else {
                // check to see if the key is one of:
                if (validKeys.includes(key.toUpperCase())) {
                    setValid('BROWSE_ACCEPTED_APP_ID'); 
                } else {
                    setValid('BROWSE_REJECTED_APP_ID');
                }
                setLoading(false);
            }
        }
        
    }, [key]);

    return ( 
        <CustomAppBar>
        {/* <AppHeader appSelected={(document) ? getAppSelectedName(document.appId) : null}> */}
            <> 
                {/* <BreadcrumbBasicExample document={document}/> */}
                <Label style={{fontSize: '28px', textAlign: 'left'}}>{document.id}</Label>
                {loading && <div>Loading...</div>}
                {!loading && (
                    <>
                    {(valid === 'BROWSE_ACCEPTED') && <Typography variant="h1">Display the general browser!</Typography>}
                    {(valid === 'BROWSE_ACCEPTED_APP_ID') && <Typography variant="h1">Display the app browser!</Typography>}
                    {(valid === 'BROWSE_REJECTED_APP_ID') && <PermissionErrorPage title={"You don't have access to this app"} description={"This app requires elevated permissions or the app doesn't exist. If you need access, contact the Portal Administrator for support."}/>}
                    {(valid === 'DOC_ACCEPTED_APP_ID') && (
                        <RenderDocument document={document} setDocument={setDocument}/>
                    )}
                    {(valid === 'DOC_REJECTED') && 
                    <>
                      {/* <PermissionErrorPage title={"You don't have access to this document"} description={'Make sure the document exists. If it does, ask the Portal Administrator to see the document.'}/> */}
                      <BaseDocumentView
                        headerContent={(<PermissionErrorPage title={"You don't have access to this document"} description={'Make sure the document exists. If it does, ask the Portal Administrator to see the document.'}/>)}
                        hideTabs
                        tabs={[
                          {
                            title: 'Notes',
                            icon: 'Notes',
                            itemCount: 4,
                            content: (
                              <div>
                                This is a test
                              </div>
                            )
                          }
                        ]}
                        />
                    </>
                    }
                    </>
                )}
            </>
        {/* </AppHeader> */} 
      </CustomAppBar>
    ); 
};


function _onBreadcrumbItemClicked(ev, item) {
    switch (item.key) {
      case 'document':   
        window.location.href = item.to;
        break;
      case 'browse': 
        window.location.href = item.to;
        break;
      case 'app':
        window.location.href = item.to;
        break;
      case 'home':
        window.location.href = item.to;
        break;
      default: 
        break;
    }
    console.log(item); 
} 
  
function convertAppIdToAppName(appId) {
    switch (appId) {
        case 'SHIP': return 'Logistics';
        case 'JOB': return 'Operations';
        default: return '...';
    }
}

// function getAppDocumentType(appId) {
//     switch (appId) {
//         case 'SHIP': return 'Shipments';
//         case 'JOB': return 'Jobs';
//         default: return '...';
//     }
// }

const BreadcrumbBasicExample = ({document}) => { 
    const [bcItems, setBCItems] = useState([]);  

    useEffect(() => { 
        if (document) {
            // console.log(document);
            setBCItems(b => { 
                return [ 
                    { to: '/', text: 'Home', key: 'home', onClick: _onBreadcrumbItemClicked }, 
                    { to: `/browse/${document.appId}`, text: convertAppIdToAppName(document.appId), key: 'browse', onClick: _onBreadcrumbItemClicked },
                    { to: '/browse/'+document.id, documentId: document.id, text: document.id, key: 'document', onClick: _onBreadcrumbItemClicked, isCurrentItem: true },
                ];
            });
        } else { 
            setBCItems(b => {  
                return [
                    { 
                        text: 'Home', 
                        key: 'home', 
                        onClick: _onBreadcrumbItemClicked,
                        isCurrentItem: true
                    }
                ];
            });
        } 
    }, [document])
    return (
      <div style={{marginBottom: '16px'}}> 
        <Breadcrumb
          style={{backgroundColor: '#F4F5F7'}}
          items={bcItems}
          maxDisplayedItems={4}
          ariaLabel="Breadcrumb"
          overflowAriaLabel="More links"
        /> 
      </div>
    );
};

function getAppSelectedName(appId) {
    switch (appId) {
        case 'JOB': return 'Operations';
        case 'SHIP': return 'Logistics';
        default: return;
    }
}

function RenderDocument({ document, setDocument }) {
    return (
        <>
            {
              document.appId === 'SHIP' && 
                <CreateShipment   
                  document={document} 
                  setDocument={setDocument} 
                  saveDocument={async () => {
                    // console.log('Saving...', document);
                   await updateDocument(document);
                  }}
                  />
            }
            {
              document.appId === 'JOB' && 
                <CreateJob  
                  document={document} 
                  setDocument={setDocument}
                  saveDocument={async () => {
                    // console.log('Saving...', document);
                    await updateDocument(document);
                  }}
                  />
            }
        </>
    )
}

function PermissionErrorPage({title, description}) {
    return (
      <div style={{marginTop:'50px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
        <img height='128px' src='/permission-error.svg'/>
        <Typography style={{fontWeight:600, fontSize: '24px', marginTop: '20px', fontFamily: 'Segoe UI'}} variant='h6'>{title}</Typography>
        <Typography style={{marginTop: '10px', fontSize: '16px', fontFamily: 'Segoe UI', maxWidth: '700px'}} variant='body2'>{description}</Typography>
      </div>
    )
  } 

export default DocumentBrowser;